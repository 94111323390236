import React from "react";
import {
    BooleanInput,
    Create,
    CreateProps,
    NumberInput,
    ReferenceArrayInput,
    required,
    SelectArrayInput,
    SimpleForm,
    TextInput
} from "react-admin";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import DocumentationLink from "../shared/DocumentationLink";

const MembershipGroupCreate = (props: CreateProps) => (
    <>
        <Create title="Create Membership Group" {...props}>
            <SimpleForm redirect="edit">
                <DisabledHiddenOrganisationIdInput />
                <TextInput
                    source="name"
                    fullWidth
                    validate={[required()]}
                    helperText="The name of the group, used as the title"
                />
                <TextInput
                    source="stub"
                    fullWidth
                    validate={[required()]}
                    helperText="The stub to appear after /memberships/groups/ e.g. golf-course"
                />
                <NumberInput
                    source="ordering"
                    helperText="The ordering of the Membership group, in listings"
                />
                <ReferenceArrayInput
                    label="Pick the memberships for this group"
                    source="membershipsIds"
                    reference="Membership"
                    sort={{ field: "name", order: "ASC" }}
                    perPage={200}
                    fullWidth
                >
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
                <BooleanInput
                    source="isAvailable"
                    defaultValue={true}
                    validate={[required()]}
                    helperText="If not set, this resource will not be displayed anywhere (website or mid-admin)"
                />
            </SimpleForm>
        </Create>
        <DocumentationLink link="https://docs.google.com/document/d/1BP4TaqmmvvuNX1ePg9Ic34uWPnzlgUawBPVVJVxV-y4/edit" />
    </>
);

export default MembershipGroupCreate;
