import { gql } from "@apollo/client";

const credentialFragment = gql`
    fragment credential on Credential {
        id
        customer {
            id
            name
        }
        bookings {
            id
            createdAt
            completedAt
            bookingStatus
            reference
        }
        isPrimaryCredential
        login
        email
        firstName
        lastName
        mobileNumber
        doesReceiveSms
        doesReceiveEmails
        isLockedOut
        isDisabled
        lastLoginAt
        passwordResetRequestedAt
        searchableUpper
    }
`;

export default credentialFragment;
