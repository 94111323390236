import {
    TextInput,
    Filter,
    ReferenceInput,
    SelectInput,
    FilterProps
} from "react-admin";
import React from "react";

const FacilityFilter = (props: Omit<FilterProps, "children">) => (
    <Filter {...props}>
        <TextInput
            label="Search by name"
            source="name_contains"
            alwaysOn
            resettable
        />
        <ReferenceInput
            label="Organisation"
            source="organisationId"
            reference="Organisation"
            alwaysOn
            resettable
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export default FacilityFilter;
