import React from "react";
import {
    ArrayField,
    Datagrid,
    DateInput,
    Edit,
    FormTab,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    TextInput,
    TextField,
    BooleanField,
    DateField,
    NumberField,
    FunctionField,
    ArrayInput,
    SimpleFormIterator,
    EditProps,
    Record
} from "react-admin";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import SingleLinkToReferenceField from "../shared/SingleLinkToReferenceField";
import BookingEditActions from "./BookingEditActions";
import DocumentationLink from "../shared/DocumentationLink";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";

const BookingEdit = (props: EditProps) => (
    <>
        <Edit
            title="Edit a Booking"
            actions={<BookingEditActions />}
            {...props}
        >
            <TabbedForm redirect="edit">
                <FormTab label="summary">
                    <DisabledHiddenOrganisationIdInput />
                    <NumberInput
                        label="Number of attendants"
                        disabled
                        source="activityQuantity"
                    />
                    <TextInput source="reference" />
                    <TextInput source="packageReference" />

                    <TextInput source="nominalCode" />
                    <TextInput
                        label="Confirmation email id"
                        source="customerConfirmationEmailId"
                    />
                    <DateInput source="checkedInAt" />
                    <DateInput source="completedAt" />

                    <NumberInput disabled source="subTotal" />
                    <NumberInput disabled source="discount" />
                    <NumberInput disabled source="total" />
                    <NumberInput disabled source="balance" />
                    <ReferenceInput
                        label="Checked in by User"
                        source="checkedInByUserId"
                        reference="User"
                        sort={{ field: "name", order: "ASC" }}
                        perPage={200}
                        fullWidth
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <TextInput source="bookingStatus" />
                    <ArrayInput source="bookingNames">
                        <SimpleFormIterator>
                            <TextInput source="" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Customer / Credential">
                    <SingleLinkToReferenceField
                        label="Link to customer"
                        source="customer.name"
                        sourceId="customer.id"
                        reference="Customer"
                    />
                    <SingleLinkToReferenceField
                        label="Link to credential"
                        source="madeByCredential.email"
                        sourceId="madeByCredential.id"
                        reference="Credential"
                    />
                    <TextInput
                        label="First name"
                        disabled
                        source="madeByCredential.firstName"
                    />
                    <TextInput
                        label="Last name"
                        disabled
                        source="madeByCredential.lastName"
                    />
                </FormTab>

                <FormTab label="Activity">
                    <SingleLinkToReferenceField
                        label="Link to resource"
                        source="activityInstance.activity.resource.name"
                        sourceId="activityInstance.activity.resource.id"
                        reference="Resource"
                    />
                    <SingleLinkToReferenceField
                        label="Link to activity"
                        source="activityInstance.activity.name"
                        sourceId="activityInstance.activity.id"
                        reference="Activity"
                    />
                    <NumberInput
                        label="Quantity unavailable"
                        disabled
                        source="activityInstance.quantityUnavailable"
                    />
                    <NumberInput
                        label="Max Capacity"
                        disabled
                        source="activityInstance.maxCapacity"
                    />
                    <NumberInput
                        label="Price"
                        disabled
                        source="activityPrice"
                    />
                    <NumberInput
                        label="Discount"
                        disabled
                        source="activityDiscount"
                    />
                    <DateInput
                        label="Starting at"
                        disabled
                        source="activityStartsAt"
                    />
                    <TextInput
                        label="Start time"
                        disabled
                        source="activityInstance.startTime"
                    />
                    <TextInput
                        label="Start time"
                        disabled
                        source="activityInstance.endTime"
                    />
                </FormTab>
                <FormTab label="Extras">
                    <ArrayField source="bookingExtras">
                        <Datagrid>
                            <LinkToReferenceField
                                source="name"
                                reference="Extra"
                                sourceId="extraId"
                            />
                            <TextField source="quantity" />
                            <NumberField
                                options={{ minimumFractionDigits: 2 }}
                                source="pricePerItem"
                            />
                            <NumberField
                                options={{ minimumFractionDigits: 2 }}
                                source="discountPerItem"
                            />
                        </Datagrid>
                    </ArrayField>
                </FormTab>
                <FormTab label="Transactions">
                    <ArrayField source="transactions">
                        <Datagrid>
                            <NumberField
                                source="amount"
                                options={{ minimumFractionDigits: 2 }}
                            />
                            <TextField source="providerIdentifier" />
                            <TextField source="authCode" />
                            <BooleanField source="isComplete" />
                            <DateField showTime source="completedAt" />
                        </Datagrid>
                    </ArrayField>
                </FormTab>
                <FormTab label="Coupons used">
                    <ArrayField source="couponIds">
                        <Datagrid>
                            <SingleLinkToReferenceField
                                label="Coupons used"
                                source="couponIds"
                                reference="Coupon"
                            />
                        </Datagrid>
                    </ArrayField>
                </FormTab>
                <FormTab label="Membership used">
                    <ArrayField source="customerMemberships">
                        <Datagrid>
                            <LinkToReferenceField
                                label="Name"
                                source="name"
                                reference="CustomerMembership"
                            />
                            <LinkToReferenceField
                                label="Membership type"
                                source="membership.name"
                                sourceId="membership.id"
                                reference="Membership"
                            />
                        </Datagrid>
                    </ArrayField>
                </FormTab>
                <FormTab label="Notes">
                    <ArrayField source="midAdminNotes">
                        <Datagrid>
                            <DateField showTime source="createdAt" />
                            <FunctionField
                                label="Type"
                                render={(record?: Record) => {
                                    return record && record.noteType
                                        ? `${record.noteType}`
                                        : "";
                                }}
                            />
                            <TextField source="content" />
                            <LinkToReferenceField
                                label="By User"
                                source="createdByUser.name"
                                sourceId="createdByUser.id"
                                reference="User"
                            />
                        </Datagrid>
                    </ArrayField>
                </FormTab>
            </TabbedForm>
        </Edit>
        <DocumentationLink link="https://docs.google.com/document/d/1reIw94fA4lXKYHak9zXodOhxtKcBz6t5y3QsvjTN3z0/edit" />
    </>
);

export default BookingEdit;
