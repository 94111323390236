import { gql } from "@apollo/client";

const bookingFragment = gql`
    fragment booking on Booking {
        id
        organisation {
            id
            name
        }
        bookingExtras {
            id
            name
            quantity
            pricePerItem
            discountPerItem
            extraId
        }
        transactions {
            id
            isComplete
            completedAt
            amount
            authCode
        }
        customer {
            id
            name
        }
        madeByCredential {
            id
            firstName
            lastName
            email
        }
        activityInstance {
            id
            activity {
                id
                name
                resource {
                    id
                    name
                }
            }
            startTime
            endTime
            quantityUnavailable
            maxCapacity
        }
        activityQuantity
        activityPrice
        activityDiscount
        activityStartsAt
        customerNotes {
            id
            content
        }
        adminNotes {
            id
            content
        }
        midAdminNotes {
            id
            content
            noteType
            createdAt
            createdByUser {
                id
                name
            }
        }
        completedAt
        reference
        packageReference
        bookingNames
        nominalCode
        subTotal
        discount
        total
        balance
        bookingStatus
        customerConfirmationEmailId
        checkedInAt
        checkedInByUserId
        searchableUpper
        couponIds
        transactions {
            id
            madeByCredentialId
            isComplete
            description
            completedAt
            amount
            providerIdentifier
            cardReference
            authCode
            createdAt
        }
        customerMemberships {
            id
            name
            membership {
                id
                name
            }
        }
    }
`;

export default bookingFragment;
