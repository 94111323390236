import React from "react";
import {
    Create,
    CreateProps,
    DateInput,
    ReferenceInput,
    required,
    SelectInput,
    TextInput
} from "react-admin";
import FormWithRedirect from "../shared/FormWithRedirect";

const CustomerBenefitCreate = (props: CreateProps) => (
    <Create title="Create a Benefit" {...props}>
        <FormWithRedirect>
            <TextInput source="name" validate={[required()]} />
            <TextInput source="description" validate={[required()]} />
            <ReferenceInput
                label="Discount"
                source="discount.id"
                sourceId="discount.id"
                reference="Discount"
                sort={{ field: "name", order: "ASC" }}
                perPage={200}
                validate={[required()]}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="Customer"
                source="customer.id"
                sourceId="customer.id"
                reference="Customer"
                sort={{ field: "name", order: "ASC" }}
                validate={[required()]}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <DateInput source="expiresAt" />
        </FormWithRedirect>
    </Create>
);

export default CustomerBenefitCreate;
