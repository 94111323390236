import { useContext } from "react";
import { IUseWeekScheduler } from "./interfaces";
import useLoadResource from "./useLoadResource";
import useLoadWeekSchedule from "./useLoadWeekSchedule";
import useSaveWeekSchedule from "./useSaveWeekSchedule";
import { WeekSchedulerContext } from "./WeekSchedulerContext";

const useWeekScheduler: IUseWeekScheduler = (resourceId, dateOfWeek) => {
    const weekSchedulerContext = useContext(WeekSchedulerContext);

    if (!weekSchedulerContext) {
        throw new Error(
            "WeekSchedulerContext does not have a value, it might not be inside a provider"
        );
    }

    const {
        weekSchedule,
        resource,
        timeLineTimes,
        shouldReloadWeekSchedule,
        assignResource,
        assignWeekSchedule,
        reloadWeekSchedule,
        createActivityInstance,
        updateActivityInstance,
        deleteActivityInstance,
        updatedActivityInstanceIds,
        deletedActivityInstanceIds,
        checkAreThereUnsavedChanges
    } = weekSchedulerContext;

    useLoadResource(
        resourceId as string,
        assignResource,
        !!(
            (resourceId && !resource) ||
            (resource && resourceId && resourceId !== resource.id)
        )
    );

    useLoadWeekSchedule(
        resourceId as string,
        dateOfWeek as Date,
        assignWeekSchedule,
        Boolean(
            (resourceId && (!weekSchedule || shouldReloadWeekSchedule)) ||
                (weekSchedule &&
                    resourceId &&
                    weekSchedule[0] &&
                    weekSchedule[0].resource &&
                    resource &&
                    weekSchedule[0].resource.id !== resource.id)
        )
    );

    const saveWeekSchedule = useSaveWeekSchedule(
        resource,
        weekSchedule,
        updatedActivityInstanceIds,
        deletedActivityInstanceIds,
        assignWeekSchedule
    );

    return {
        weekSchedule,
        resource,
        timeLineTimes,
        reloadWeekSchedule,
        createActivityInstance,
        updateActivityInstance,
        deleteActivityInstance,
        saveWeekSchedule,
        checkAreThereUnsavedChanges
    };
};

export default useWeekScheduler;
