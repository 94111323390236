import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { get } from "lodash";
import { LegacyDataProvider } from "react-admin";
import overridenQueries from "./queries";
import buildOpenCrudProvider from "./ra-data-opencrud";
import buildQuery from "./ra-data-opencrud/buildQuery";
import { IntrospectionResult } from "./ra-data-opencrud/constants/interfaces";

const enhanceBuildQuery =
    (buildQuery: any): any =>
    (introspectionResults: IntrospectionResult) =>
    (fetchType: string, resourceName: string, params: any): any => {
        const fragment = get(overridenQueries, `${resourceName}.${fetchType}`);

        try {
            const buildQueryFactory = buildQuery(introspectionResults)(
                fetchType,
                resourceName,
                params,
                fragment
            );

            return buildQueryFactory;
        } catch (error) {
            console.log(error);
        }
    };

const createDataProvider = async (
    client: ApolloClient<NormalizedCacheObject>
): Promise<LegacyDataProvider> =>
    buildOpenCrudProvider({
        client,
        buildQuery: enhanceBuildQuery(buildQuery)
    }) as Promise<LegacyDataProvider>;

export default createDataProvider;
