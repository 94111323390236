import Button from "@material-ui/core/Button";
import React from "react";
import useDashboard from "./useDashboard";
import { Grid, Typography } from "@material-ui/core";

const UpdateDataButton = () => {
    const { populateReport, setIsLoading } = useDashboard();

    return (
        <>
            <Grid item xs={12}>
                <Button
                    variant="text"
                    size="small"
                    color="primary"
                    onClick={() => {
                        setIsLoading();
                        populateReport();
                    }}
                >
                    Update with 7 days of new bookings
                </Button>
            </Grid>
            <Typography variant="caption">
                This should only take a few seconds, and will refresh the data
                with the next 7 days of new bookings since the last update date
            </Typography>
        </>
    );
};

export default UpdateDataButton;
