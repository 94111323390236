import { getResources } from "react-admin";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const RedirectToDashboard = ({ history }) => {
    history.push("/");

    return null;
};

const mapStateToProps = (state: any) => ({
    resources: getResources(state)
});

export default withRouter(connect(mapStateToProps)(RedirectToDashboard));
