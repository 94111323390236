import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import {
    IActivity,
    IActivityInstance
} from "../../../../generated/dataInterfaces";
import SelectInput from "../../shared/SelectInput";

const UpdateForm = ({
    dayScheduleId,
    activityInstance,
    times,
    activities,
    submitHandler,
    deleteHandler
}: IUpdateFormProps) => {
    const [maxCapacity, setMaxCapacity] = useState(
        activityInstance.maxCapacity
    );
    const [quantityUnavailable, setQuantityUnavailable] = useState(
        activityInstance.quantityUnavailable
    );
    const [pricePerBooking, setPricePerBooking] = useState(
        activityInstance.pricePerBooking
    );

    const updatedActivity = {
        dayScheduleId,
        activityInstanceId: activityInstance.id,
        maxCapacity,
        quantityUnavailable,
        pricePerBooking
    };

    const shouldDisableDelete = !!activityInstance.package;

    return (
        <>
            <DialogContent>
                <DialogContentText>Activity:</DialogContentText>
                <SelectInput
                    label=""
                    options={activities.map((a) => {
                        return {
                            value: a.id,
                            label: `${a.name} - ${a.defaultSlotLengthMinutes} minutes`
                        };
                    })}
                    isDisabled={true}
                    selectedValue={activityInstance.activity.id}
                />
            </DialogContent>
            {activityInstance.package ? (
                <DialogContent>
                    <DialogContentText>
                        This activity is part of a package
                    </DialogContentText>
                </DialogContent>
            ) : null}

            <DialogContent>
                <DialogContentText>Start time:</DialogContentText>
                <SelectInput
                    label=""
                    options={times.map((t) => {
                        return { value: t, label: t };
                    })}
                    isDisabled={true}
                    selectedValue={activityInstance.startTime}
                />
            </DialogContent>
            <DialogContent>
                <DialogContentText>End time:</DialogContentText>
                <SelectInput
                    label=""
                    options={times.map((t) => {
                        return { value: t, label: t };
                    })}
                    isDisabled={true}
                    selectedValue={activityInstance.endTime}
                />
            </DialogContent>
            <DialogContent>
                <DialogContentText>Max Capacity:</DialogContentText>
                <TextField
                    label=""
                    disabled={false}
                    onChange={(event) =>
                        setMaxCapacity(parseInt(event.target.value))
                    }
                    type="number"
                    value={maxCapacity}
                    InputLabelProps={{
                        shrink: true
                    }}
                    margin="normal"
                />
            </DialogContent>
            <DialogContent>
                <DialogContentText>Quantity Unavailable:</DialogContentText>
                <TextField
                    label=""
                    disabled={false}
                    onChange={(event) =>
                        setQuantityUnavailable(parseInt(event.target.value))
                    }
                    type="number"
                    value={quantityUnavailable}
                    InputLabelProps={{
                        shrink: true
                    }}
                    margin="normal"
                />
            </DialogContent>
            <DialogContent>
                <DialogContentText>Price per booking:</DialogContentText>
                <TextField
                    label=""
                    disabled={false}
                    onChange={(event) =>
                        setPricePerBooking(parseFloat(event.target.value))
                    }
                    type="float"
                    value={pricePerBooking}
                    InputLabelProps={{
                        shrink: true
                    }}
                    margin="normal"
                    data-testid="price-input"
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                        deleteHandler(dayScheduleId, activityInstance.id)
                    }
                    disabled={shouldDisableDelete}
                >
                    Delete
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => submitHandler(updatedActivity)}
                    data-testid="update-button"
                >
                    Update
                </Button>
            </DialogActions>
        </>
    );
};

interface IUpdateFormProps {
    dayScheduleId: string;
    activityInstance: IActivityInstance;
    times: string[];
    activities: IActivity[];

    submitHandler: (updatedData) => void;
    deleteHandler: (dayScheduleId: string, activityInstanceId: string) => void;
}

export default UpdateForm;
