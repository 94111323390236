import { get } from "lodash";
import React from "react";
import LinkField from "./LinkField";

const RecordLink = ({
    record,
    baseUrl,
    source,
    sourceId = "id",
    ...otherProps
}: IRecordLinkProps) => {
    const pathname = `${baseUrl}/${get(record, sourceId)}`;
    const linkText = get(record, source);

    return record ? (
        <LinkField pathname={pathname} linkText={linkText} {...otherProps} />
    ) : null;
};

interface IRecordLinkProps {
    record?: any;
    baseUrl: string;
    source: string;
    sourceId?: string;
    otherProps?: any;
}

export default RecordLink;
