import React from "react";
import { Datagrid, EditButton, List, ListProps, TextField } from "react-admin";
import LinkToReferenceField from "../shared/LinkToReferenceField";

const CredentialList = (props: ListProps) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="login" />
            <LinkToReferenceField
                label="Customer"
                source="customer.name"
                sourceId="customer.id"
                reference="Customer"
            />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="mobileNumber" />
            <EditButton />
        </Datagrid>
    </List>
);

export default CredentialList;
