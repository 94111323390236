import React, { useContext } from "react";
import { toTotalInMinutes } from "@les-ormes/lib";
import { IModalContext, ModalContext } from "./ModalContext";
import timelineUtil from "../utils/timelineUtil";

const ActivityInstanceTemplate = ({
    dayOfWeek,
    dayOfWeekName,
    dailyStartTime,
    startTime,
    endTime,
    timelinePrecision,
    pricePerBooking,
    activity
}: IActivityInstanceTemplateProps) => {
    const { toggleModalInEditingMode } = useContext(
        ModalContext
    ) as IModalContext;

    const css = timelineUtil.computeActivityTimelineStyles(
        dailyStartTime,
        startTime,
        endTime,
        activity.colorScheme,
        timelinePrecision
    );
    console.log("TCL: timelinePrecision", timelinePrecision);

    return (
        <li
            onClick={(event) => {
                event.stopPropagation();
                toggleModalInEditingMode(
                    dayOfWeek,
                    dayOfWeekName,
                    activity.id,
                    startTime,
                    endTime
                );
            }}
            style={css}
            className="single-event"
        >
            <a>
                <span className="event-date">
                    {startTime} - {endTime}&nbsp;&nbsp;&nbsp; &pound;
                    {pricePerBooking.toFixed(2)}
                </span>
                <em style={{ fontSize: "1.2em" }} className="event-name">
                    {activity.name}
                </em>
            </a>
        </li>
    );
};

interface IActivityInstanceTemplateProps {
    dayOfWeek: number;
    dayOfWeekName: string;
    dailyStartTime: string;
    startTime: string;
    endTime: string;
    timelinePrecision: number;
    pricePerBooking: number;
    activity: { id: string; name: string; colorScheme?: string };
}

export default ActivityInstanceTemplate;
