import { gql } from "@apollo/client";

const weekTemplateFragment = gql`
    fragment weekTemplate on WeekTemplate {
        id
        name
        createdAt
        updatedAt
        resource {
            id
            name
        }
        dayScheduleTemplates {
            id
            dayOfWeek
        }
    }
`;

export default weekTemplateFragment;
