import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect } from "react";
import { useRefresh, useDelete, useNotify } from "react-admin";

const DeleteOneActivityInstanceButton = ({
    record
}: {
    record?: { id: string };
}) => {
    const notify = useNotify();
    const refreshView = useRefresh();
    const [deleteOne, { data, loading, error }] = useDelete(
        "ActivityInstance",
        record?.id ?? ""
    );

    if (error) {
        notify(error.message);
    }

    useEffect(() => {
        if (data) {
            notify("Activity instance deleted");
            refreshView();
        }
    }, [data, notify, refreshView]);

    return (
        <IconButton
            aria-label="Delete"
            color="primary"
            disabled={loading}
            onClick={() => {
                notify("Deleting please wait...");

                if (record) {
                    deleteOne();
                }
            }}
        >
            <DeleteIcon />
        </IconButton>
    );
};

export default DeleteOneActivityInstanceButton;
