import { Button, Grid } from "@material-ui/core";
import OpenIcon from "@material-ui/icons/OpenInNew";
import React from "react";

interface IDocumentationLinkProps {
    link: string;
}

const DocumentationLink = ({ link }: IDocumentationLinkProps) => {
    return (
        <Grid>
            <Button
                style={{ marginTop: "20px" }}
                href={link}
                target="_blank"
                color="secondary"
            >
                <OpenIcon /> Documentation
            </Button>
        </Grid>
    );
};
export default DocumentationLink;
