import { gql } from "@apollo/client";

export const RESOURCE_QUERY = gql`
    query resource($resourceId: ID!) {
        resource(where: { id: $resourceId }) {
            id
            name
            dailyStartTime
            dailyEndTime
            defaultMaxCapacity
            greatestCommonDivisorOfActivitiesDuration
            activities {
                id
                name
                colorScheme
                defaultSlotLengthMinutes
                defaultPricePerBooking
                resource {
                    defaultMaxCapacity
                }
                isPackaged
                packages {
                    bookings {
                        id
                    }
                }
            }
        }
    }
`;

const dayScheduleFields = `
    id
    date
    resource {
        id
    }
    activityInstances {
        id
        package {
            id
        }
        activity {
            id
            name
            colorScheme
        }
        bookings {
            id
            bookingStatus
        }
        startTime
        endTime
        quantityUnavailable
        maxCapacity
        pricePerBooking
    }
`;

export const WEEK_SCHEDULE_QUERY = gql`
    query resourceWeekSchedule($resourceId: ID!, $dateOfWeek: DateTime) {
        resourceWeekSchedule(
            where: { resourceId: $resourceId, dateOfWeek: $dateOfWeek }
        ) {
            ${dayScheduleFields}
        }
    }
`;

export const SAVE_WEEK_SCHEDULE_MUTATION = gql`
    mutation saveWeekSchedule($saveWeekScheduleInput: SaveWeekScheduleInput!) {
        saveWeekSchedule(data: $saveWeekScheduleInput){
            ${dayScheduleFields}
        }
    }
`;


export const DELETE_DAY_SCHEDULE_MUTATION = gql`
    mutation deleteDayScheduleWithoutBookings($deleteDayScheduleInput: DeleteDayScheduleWithoutBookingsInput!) {
        deleteDayScheduleWithoutBookings(input: $deleteDayScheduleInput){
            ${dayScheduleFields}
        }
    }
`;