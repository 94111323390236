import React, { useContext, useEffect } from "react";
import { WeekTemplateContextProvider } from "../WeekTemplate/WeekTemplateContext";
import { ModalContextProvider } from "../WeekTemplate/ModalContext";
import WeekTemplate from "../WeekTemplate";

const UpdateWeekTemplate = ({ match }) => {
    const { weekTemplateId } = match.params;

    return (
        <WeekTemplateContextProvider>
            <ModalContextProvider>
                <WeekTemplate
                    weekTemplateId={weekTemplateId}
                    isUpdateMode={true}
                />
            </ModalContextProvider>
        </WeekTemplateContextProvider>
    );
};

export default UpdateWeekTemplate;
