import useDashboard from "./useDashboard";
import { Grid, Typography, Button } from "@material-ui/core";
import React from "react";

const ReportSummary = () => {
    const { reportSummary } = useDashboard();

    return reportSummary ? (
        <>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                    {reportSummary.name}{" "}
                    <Button
                        style={{ marginLeft: "100px" }}
                        variant="outlined"
                        color="primary"
                        href={`${process.env["ENDPOINT"]}${reportSummary.downloadLink}`}
                    >
                        Download report
                    </Button>
                </Typography>
            </Grid>

            <div
                style={{
                    width: "100%",
                    height: "1px",
                    borderBottom: "1px dotted #999",
                    marginBottom: "10px"
                }}
            />

            {reportSummary?.details?.map((item) => (
                <>
                    <Grid item xs={2}>
                        <Typography variant="body1" gutterBottom>
                            <strong>{item.key}</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h4" gutterBottom>
                            {item.value}
                        </Typography>
                    </Grid>
                </>
            )) ?? null}

            <div
                style={{
                    width: "100%",
                    height: "1px",
                    borderBottom: "1px dotted #999",
                    marginBottom: "10px"
                }}
            />

            <Grid item xs={2}>
                <Typography variant="body1" gutterBottom>
                    <strong>Requested at</strong>
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Typography variant="h4" gutterBottom>
                    {reportSummary.requestedAt}
                </Typography>
            </Grid>

            <Grid item xs={2}>
                <Typography variant="body1" gutterBottom>
                    <strong>Time taken</strong>
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Typography variant="h4" gutterBottom>
                    {reportSummary.numberOfSeconds} seconds
                </Typography>
            </Grid>
        </>
    ) : null;
};

export default ReportSummary;
