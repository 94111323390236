import React from "react";
import { CardActions } from "react-admin";
import CreateReferencedResourceButton from "../shared/CreateReferencedResourceButton";

const ActivityEditActions = ({
    data,
    currentUrl,
    ...otherProps
}: IEditActionsProps) => {
    return data && data.isPackaged ? (
        <CardActions>
            <CreateReferencedResourceButton
                reference="Package"
                currentUrl={currentUrl}
                mappings={[["id", "activity.id"]]}
                record={data}
                {...otherProps}
            />
        </CardActions>
    ) : null;
};

interface IEditActionsProps {
    data?: { id: string; isPackaged: boolean };
    currentUrl: string;
    otherProps?: any;
}

export default ActivityEditActions;
