import useDashboard from "../useDashboard";
import SelectInput from "../../shared/SelectInput";
import React from "react";

const ExtraFilter = () => {
    const { extraFilter, setFilter } = useDashboard();

    return extraFilter ? (
        <SelectInput
            label="Extra"
            options={extraFilter.options}
            isDisabled={false}
            onChangeHandler={(e) => {
                setFilter(e, extraFilter.filterName);
            }}
            selectedValue={extraFilter.selectedValue}
        />
    ) : null;
};

export default ExtraFilter;
