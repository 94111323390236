import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { confirmable } from "react-confirm";

const AlertDialog = ({ show, proceed, cancel, confirmation, options }) => {
    return (
        <Dialog open={show}>
            <DialogTitle>{confirmation}</DialogTitle>

            <DialogActions>
                <Button onClick={cancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={proceed} color="primary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default confirmable(AlertDialog);
