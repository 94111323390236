import useDashboard from "../useDashboard";
import SelectInput from "../../shared/SelectInput";
import React from "react";

const ActivityFilter = () => {
    const { activityFilter, setFilter } = useDashboard();

    return activityFilter ? (
        <SelectInput
            label="Activity"
            options={activityFilter.options}
            isDisabled={false}
            onChangeHandler={(e) => {
                setFilter(e, activityFilter.filterName);
            }}
            selectedValue={activityFilter.selectedValue}
        />
    ) : null;
};

export default ActivityFilter;
