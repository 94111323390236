import { Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import RichTextInput from "ra-input-rich-text";
import React from "react";
import {
    ArrayField,
    BooleanInput,
    Datagrid,
    Edit,
    EditProps,
    FormTab,
    NumberInput,
    ReferenceInput,
    regex,
    required,
    SelectInput,
    TabbedForm,
    TextInput
} from "react-admin";
//import { ColorInput } from "react-admin-color-input";
import { useLocation } from "react-router-dom";
import DocumentationLink from "../shared/DocumentationLink";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import ActivityEditActions from "./ActivityEditActions";

const ActivityEdit = (props: EditProps) => {
    const { pathname } = useLocation();
    console.log(
        "🚀 ~ file: ActivityEdit.tsx ~ line 28 ~ ActivityEdit ~ pathname",
        pathname
    );

    return (
        <>
            <Edit
                title="Edit a Activity"
                actions={<ActivityEditActions currentUrl={pathname} />}
                {...props}
            >
                <TabbedForm redirect="edit">
                    <FormTab label="summary">
                        <TextInput
                            source="name"
                            validate={[required()]}
                            fullWidth
                            helperText="The public name of this Activity"
                        />
                        <ReferenceInput
                            label="Resource"
                            source="resource.id"
                            reference="Resource"
                            sort={{ field: "name", order: "ASC" }}
                            perPage={200}
                        >
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <LinkToReferenceField
                            label="Link to resource"
                            sourceId="resource.id"
                            source="resource.name"
                            reference="Resource"
                        />
                        <TextInput
                            source="keyInformation"
                            fullWidth
                            helperText="The text to be displayed below the activity name e.g. "
                        />
                        <RichTextInput
                            source="description"
                            validate={[required()]}
                        />
                        <TextInput
                            source="imageUrl"
                            validate={[required()]}
                            fullWidth
                            helperText="For now, images should be uploaded on https://www.lesormesjersey.co.uk/umbraco and the full URL entered here"
                        />
                        <NumberInput
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        £
                                    </InputAdornment>
                                )
                            }}
                            source="defaultPricePerBooking"
                            validate={[required()]}
                            helperText="The default cost of a single booking, when adding to the schedule. Enter as ##.## e.g. 10.00"
                        />
                        <NumberInput
                            source="defaultSlotLengthMinutes"
                            label="Default slot length"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        minutes
                                    </InputAdornment>
                                )
                            }}
                            helperText="The default length, when adding to a schedule"
                        />
                        <NumberInput
                            source="quantityPerBooking"
                            helperText="The number of people per ticket"
                        />
                        <NumberInput
                            source="minimumQuantityPerBooking"
                            label="Min tickets per booking"
                            helperText="The minimum number tickets that can be purchased at one time"
                        />
                        <NumberInput
                            source="bookMinDaysInAdvance"
                            helperText="How many days in advance is the first option to book? 0 means 'today'"
                            defaultValue={0}
                        />
                        <NumberInput
                            source="bookMaxDaysInAdvance"
                            helperText="How many days in advance is the last option to book? e.g. 21 for 3 weeks, 28 for 4 weeks, etc"
                            defaultValue={14}
                        />
                        <TextInput
                            source="stub"
                            fullWidth
                            validate={[
                                regex(
                                    /^(\w+)(-?(\w+)?)+$/,
                                    "Letters, numbers and dashes (-) only"
                                ),
                                required()
                            ]}
                            helperText="The last part of the URL for this activity. Should be in the form resource-name-of-activity. Must be unique amongst activities"
                        />
                        <TextInput
                            source="needHelpUrl"
                            fullWidth
                            helperText="The URL for the FAQs, etc. Will open in a new tab"
                        />
                        <TextInput
                            source="ticketDescription"
                            fullWidth
                            helperText="The text to appear alongside the booking quantity e.g. 'Standard Ticket`"
                        />

                        <RichTextInput
                            source="bookingConfirmEmailText"
                            helperText="Text included on the booking confirmation email"
                        />
                        <RichTextInput
                            source="checkoutCompleteText"
                            helperText="Text included on the booking page"
                        />
                        <TextInput
                            source="colorScheme"
                            label="Schedule background colour"
                            helperText="Enter the #hex code. Use https://imagecolorpicker.com/color-code"
                        />

                        {/* <ColorInput
                                source="colorScheme"
                                label="Schedule background colour"
                            />*/}
                        <NumberInput
                            source="ordering"
                            helperText="The ordering of the Activity, in listings"
                        />

                        <BooleanInput
                            source="isAvailable"
                            helperText="If not set, this resource will not be displayed anywhere (website or mid-admin)"
                        />
                        <BooleanInput
                            source="isHiddenFromPublic"
                            helperText="If set, this will not be available on the website"
                        />
                        <BooleanInput
                            source="isBookingNameRequired"
                            helperText="If set, Booking names will be required when booking this activity"
                        />
                        <RichTextInput
                            source="bookingNameInfo"
                            label="Information about why and which names are required"
                        />
                    </FormTab>
                    <FormTab label="Package info">
                        <Typography
                            style={{ paddingTop: "30px", width: "100%" }}
                            variant="body1"
                            gutterBottom
                            align="left"
                        >
                            You must enable the isPackaged feature to then
                            create packages for this activity.
                        </Typography>
                        <BooleanInput source="isPackaged" />
                        <Typography variant="body1" style={{ width: "100%" }}>
                            It can be set to true only if there are no Bookings
                        </Typography>
                        <BooleanInput
                            source="mustPreventBookingAfterFirstInstanceIsPast"
                            label="If set, the package can't be purchased after the first instance is pasted"
                        />
                        <Typography
                            variant="body1"
                            style={{ width: "100%" }}
                            gutterBottom
                            align="left"
                        >
                            You must enable the isPackaged feature to then
                            create packages for this activity.
                        </Typography>
                    </FormTab>
                    <FormTab label="Packages">
                        <Typography
                            variant="body1"
                            style={{ paddingTop: "30px", width: "100%" }}
                            gutterBottom
                            align="left"
                        >
                            All the packages that are part of this activity. You
                            can have multiple packages running alongside as long
                            as the are on the same dates, and only different
                            start times.
                        </Typography>
                        <ArrayField source="packages">
                            <Datagrid
                                currentSort={{
                                    field: "Name",
                                    order: "ASC"
                                }}
                            >
                                <LinkToReferenceField
                                    label="Name"
                                    source="name"
                                    reference="Package"
                                />
                            </Datagrid>
                        </ArrayField>
                    </FormTab>
                    <FormTab label="Self check-in">
                        <BooleanInput
                            source="isSelfCheckInEnabled"
                            helperText="Enable/Disable self check-in"
                        />
                        <NumberInput
                            label="Minutes"
                            source="howManyMinutesCheckInOpensBeforeActivityStarts"
                            helperText="Set how many minutes before the activity starts the check-in opens"
                        />

                        <NumberInput
                            label="Minutes"
                            source="howManyMinutesCheckInClosesAfterActivityStarts"
                            helperText="Set how many minutes after the activity starts the check-in closes"
                        />
                    </FormTab>
                </TabbedForm>
            </Edit>
            <DocumentationLink link="https://docs.google.com/document/d/1mpbNO20jYds6fOIjkJA93pXq_ph9deSYW8iidF4AwEQ/edit" />
        </>
    );
};

export default ActivityEdit;
