import React from "react";
import {
    BooleanInput,
    Create,
    CreateProps,
    required,
    SimpleForm,
    TextInput
} from "react-admin";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import DocumentationLink from "../shared/DocumentationLink";

const CustomerCreate = (props: CreateProps) => (
    <>
        <Create title="Create a Customer" {...props}>
            <SimpleForm redirect="edit">
                <TextInput source="name" validate={[required()]} />
                <TextInput source="postcode" validate={[required()]} />
                <DisabledHiddenOrganisationIdInput />
                <BooleanInput source="isDisabled" defaultValue={false} />
            </SimpleForm>
        </Create>
        <DocumentationLink link="https://docs.google.com/document/d/1_dFChaJdvxCGWxuTJ316rWvMJsoaERsQ7JD-MTydg7Q/edit" />
    </>
);

export default CustomerCreate;
