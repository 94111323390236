import { useQuery } from "@apollo/client";
import { RESOURCE_QUERY } from "./queries";
import { toast } from "react-toastify";

const useLoadResource = (
    resourceId: string,
    assignResource: (resource: any) => void,
    shouldLoad: boolean
) => {
    const { data, error, loading } = useQuery(RESOURCE_QUERY, {
        variables: { resourceId },
        skip: !shouldLoad
    });

    if (data && data.resource) {
        assignResource(data.resource);
    }

    if (error) {
        toast(
            `An error occurred when loading the Resource ${resourceId}. Error message: ${error.message}`
        );
    }
};

export default useLoadResource;
