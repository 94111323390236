import { InputLabel } from "@material-ui/core";
import React from "react";
import RecordLink from "./RecordLink";

const SingleLinkToReferenceField = ({
    record,
    reference,
    source,
    label,
    sourceId = "id",
    prefilledRecord = {},
    shouldRedirect = false,
    redirectUrl = ""
}: ISingleLinkToReferenceFieldProps) => {
    return record ? (
        <InputLabel>
            {label ? (
                <label className="link-label">
                    <br />
                    {label}
                    <br /> <br />
                </label>
            ) : null}
            <RecordLink
                record={record}
                baseUrl={`/${reference}`}
                source={source}
                sourceId={sourceId}
                otherProps={{ prefilledRecord, shouldRedirect, redirectUrl }}
            />
        </InputLabel>
    ) : null;
};

interface ISingleLinkToReferenceFieldProps {
    record?: any;
    reference: string;
    source: string;
    label?: string;
    sourceId?: string;
    prefilledRecord?: any;
    shouldRedirect?: boolean;
    redirectUrl?: string;
}

export default SingleLinkToReferenceField;
