import React from "react";
import {
    ArrayField,
    BooleanField,
    ChipField,
    Datagrid,
    EditButton,
    List,
    ListProps,
    NumberField,
    SingleFieldList,
    TextField
} from "react-admin";
import DocumentationLink from "../shared/DocumentationLink";

const MembershipList = (props: ListProps) => (
    <>
        <List filter={{ isDeleted_not: true }} {...props}>
            <Datagrid>
                <TextField source="name" />
                <BooleanField source="isAvailable" label="Available?" />
                <BooleanField
                    source="canBeCrossAuthorised"
                    label="Cross auth?"
                />
                <ArrayField source="resources" label="Available Resources">
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
                <NumberField source="ordering" label="ordering" />
                <EditButton />
            </Datagrid>
        </List>
        <DocumentationLink link="https://docs.google.com/document/d/1A87z5To_sJhQYX4W-oW9hGEZeZPO_1qyX_gsn-fhhlY/edit" />
    </>
);

export default MembershipList;
