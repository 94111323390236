import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { confirmable, createConfirmation } from "react-confirm";
import AlertDialog from "./index";

const confirm = createConfirmation(AlertDialog);

export default function (confirmation, options = {}) {
    return confirm({ confirmation, ...options });
}
