import { gql } from "@apollo/client";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";

export const GET_DATA_FOR_FILTERS_QUERY = gql`
    query getDataForFiltersQuery {
        resources {
            id
            name
        }
        facilities {
            id
            name
        }
        activities {
            id
            name
        }
        extras {
            id
            name
        }
        coupons {
            id
            name
        }
        memberships {
            id
            name
        }
    }
`;

interface INameId {
    id: string;
    name: string;
}

export interface IUseGetFiltersData {
    resources: INameId[];
    facilities: INameId[];
    activities: INameId[];
    extras: INameId[];
    coupons: INameId[];
    memberships: INameId[];
}

export const useGetFiltersData = (
    populateFilters: (filtersData: any) => void
) => {
    const [filtersData] = useState<IUseGetFiltersData>(null);

    const { data, error, loading } = useQuery(GET_DATA_FOR_FILTERS_QUERY, {
        skip: !!filtersData
    });

    if (error) {
        toast(
            `An error occurred when loading the filters. Error message: ${error.message}`
        );
    }
    if (data && data.resources) {
        populateFilters(data);
    }
    return { filtersData };
};

export default useGetFiltersData;
