import React from "react";
import {
    ArrayField,
    BooleanInput,
    Datagrid,
    Edit,
    EditProps,
    FormTab,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput
} from "react-admin";
import LinkToReferenceField from "../shared/LinkToReferenceField";

const CredentialEdit = (props: EditProps) => {
    return (
        <Edit title="Edit Credential" {...props}>
            <TabbedForm redirect="edit">
                <FormTab label="summary">
                    <ReferenceInput
                        label="Customer"
                        source="customer.id"
                        reference="Customer"
                        sort={{ field: "name", order: "ASC" }}
                        validate={[required()]}
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <BooleanInput source="isPrimaryCredential" />
                    <TextInput source="login" validate={[required()]} />
                    <TextInput source="email" validate={[required()]} />
                    <TextInput source="firstName" validate={[required()]} />
                    <TextInput source="lastName" validate={[required()]} />
                    <TextInput
                        label="New password"
                        type="password"
                        source="newPassword"
                    />
                    <TextInput source="mobileNumber" />
                    <BooleanInput source="doesReceiveSms" />
                    <BooleanInput source="doesReceiveEmails" />
                    <BooleanInput source="isLockedOut" />
                    <BooleanInput source="isDisabled" />
                    <TextInput source="lastLoginAt" disabled />
                    <TextInput source="passwordResetRequestedAt" disabled />
                    <TextInput source="searchableUpper" disabled />
                </FormTab>
                <FormTab label="bookings">
                    <ArrayField source="bookings">
                        <Datagrid>
                            <TextField source="createdAt" />
                            <TextField source="completedAt" />
                            <TextField source="bookingStatus" />
                            <LinkToReferenceField
                                source="reference"
                                reference="Booking"
                            />
                        </Datagrid>
                    </ArrayField>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default CredentialEdit;
