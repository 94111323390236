import React from "react";
import {
    BooleanField,
    Datagrid,
    EditButton,
    List,
    ListProps,
    NumberField,
    TextField
} from "react-admin";
import DocumentationLink from "../shared/DocumentationLink";
import LinkToReferenceField from "../shared/LinkToReferenceField";

const DiscountList = (props: ListProps) => (
    <>
        <List {...props}>
            <Datagrid>
                <LinkToReferenceField
                    source="name"
                    sourceId="id"
                    reference="Discount"
                    label="Name"
                />
                <BooleanField source="isActive" />
                <NumberField source="discountAmount" />
                <TextField source="validDaysOfWeek" />
                <NumberField source="validForMaxQuantity" />
                <TextField source="discountType" />
                <EditButton />
            </Datagrid>
        </List>
        <DocumentationLink link="https://docs.google.com/document/d/18nlOQnhkGpCSSZGw0s3Qxx118flLIQznhkwc2sD-tbU/edit" />
    </>
);

export default DiscountList;
