import useDashboard from "../useDashboard";
import SelectInput from "../../shared/SelectInput";
import React from "react";

const IsAdminBookingFilter = () => {
    const { isAdminBookingFilter, setFilter } = useDashboard();

    return isAdminBookingFilter ? (
        <SelectInput
            label="Is it an admin booking?"
            options={isAdminBookingFilter.options}
            isDisabled={false}
            onChangeHandler={(e) => {
                setFilter(e, isAdminBookingFilter.filterName);
            }}
            selectedValue={isAdminBookingFilter.selectedValue}
        />
    ) : null;
};

export default IsAdminBookingFilter;
