import { gql } from "@apollo/client";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { IReportPopulationResponse } from "../../../../generated/dataInterfaces";

export const GET_LATEST_REPORT_POPULATION_HISTORY = gql`
    query latestReportPopulationHistory {
        latestReportPopulationHistory {
            lastUpdatedDate
        }
    }
`;

export const useGetReportUpdateInfo = (
    setReportUpdateInfo: (reportUpdateData: IReportPopulationResponse) => void,
    shouldReload: boolean
) => {
    const { data, error, loading } = useQuery(
        GET_LATEST_REPORT_POPULATION_HISTORY,
        {
            skip: !shouldReload
        }
    );

    if (error) {
        toast(
            `An error occurred when loading the report update date. Error message: ${error.message}`
        );
    }
    if (data && data.latestReportPopulationHistory) {
        setReportUpdateInfo(data.latestReportPopulationHistory);
    }
};

export default useGetReportUpdateInfo;
