import { IDaySchedule, IResource } from "../../../../generated/dataInterfaces";

export enum ActionType {
    AssignResource = "ASSIGN_RESOURCE",
    AssignWeekSchedule = "ASSIGN_WEEK_SCHEDULE",
    ReloadWeekSchedule = "RELOAD_WEEK_SCHEDULE",
    CreateActivityInstance = "CREATE_ACTIVITY_INSTANCE",
    UpdateActivityInstance = "UPDATE_ACTIVITY_INSTANCE",
    DeleteActivityInstance = "DELETE_ACTIVITY_INSTANCE"
}

export interface IUseWeekScheduler {
    (resourceId?: string, date?: Date): {
        weekSchedule: Array<IDaySchedule>;
        resource: IResource;
        timeLineTimes: Array<string>;
        reloadWeekSchedule: () => void;
        createActivityInstance: (
            newActivityInstance: INewActivityInstanceInput
        ) => void;
        updateActivityInstance: (
            updateActivityInstanceInput: IUpdateActivityInstanceInput
        ) => void;
        deleteActivityInstance: (
            dayScheduleId: string,
            activityInstanceId: string
        ) => void;
        saveWeekSchedule: () => void;
        checkAreThereUnsavedChanges: () => boolean;
    };
}

export interface IWeekSchedulerContext {
    weekSchedule: Array<IDaySchedule>;
    resource: IResource;
    timeLineTimes: Array<string>;
    shouldReloadWeekSchedule: boolean;
    assignResource: (resource: any) => void;
    assignWeekSchedule: (weekSchedule: any) => void;
    reloadWeekSchedule: () => void;
    createActivityInstance: (
        newActivityInstanceInput: INewActivityInstanceInput
    ) => void;
    updateActivityInstance: (
        updateActivityInstanceInput: IUpdateActivityInstanceInput
    ) => void;
    deleteActivityInstance: (
        dayScheduleId: string,
        activityInstanceId: string
    ) => void;
    updatedActivityInstanceIds: string[];
    deletedActivityInstanceIds: string[];
    checkAreThereUnsavedChanges: () => boolean;
}

export interface IState {
    weekSchedule: Array<IDaySchedule>;
    resource: IResource;
    timeLineTimes: string[];
    shouldReloadWeekSchedule: boolean;
    updatedActivityInstanceIds: string[];
    deletedActivityInstanceIds: string[];
}

export interface IAction {
    type: ActionType;
    payload?: any;
}

export interface INewActivityInstanceInput {
    dayScheduleId: string;
    startTime: string;
    endTime: string;
    activityId: string;
    maxCapacity: number;
    quantityUnavailable: number;
    pricePerBooking: number;
}

export interface IUpdateActivityInstanceInput {
    dayScheduleId: string;
    activityInstanceId: string;
    maxCapacity: number;
    quantityUnavailable: number;
    pricePerBooking: number;
}
