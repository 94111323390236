import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ActivityDateStartTimeEndTime from "./ActivityDateStartTimeEndTime";
import { usePackageContext } from "./PackageContext";

interface IAddActivityDateStartTimeEndTimeProps {
    onAdd: (date: moment.Moment, endTime: moment.Moment) => void;
}

const nextDayDateStartTime = (date: moment.Moment, startTime: moment.Moment) =>
    moment(date)
        .add(1, "day")
        .hours(startTime.hours())
        .minutes(startTime.minutes());

const AddActivityDateStartTimeEndTime = ({
    onAdd
}: IAddActivityDateStartTimeEndTimeProps) => {
    const [date, setDate] = useState<moment.Moment | null>(null);
    const [endTime, setEndTime] = useState<moment.Moment | null>(null);
    const { state } = usePackageContext();
    const {
        activityStartTime,
        activityEndTime,
        activityGeneratedDates
    } = state;

    useEffect(
        function updateAddActivityDateStartTimeEndTime() {
            if (
                activityStartTime &&
                activityGeneratedDates &&
                activityGeneratedDates.length > 0
            ) {
                const lastDate = moment(
                    activityGeneratedDates[activityGeneratedDates.length - 1]
                        .date
                );
                setDate(nextDayDateStartTime(lastDate, activityStartTime));
            }
            if (activityEndTime) {
                setEndTime(activityEndTime);
            }
        },
        [activityStartTime, activityEndTime, activityGeneratedDates]
    );

    if (!date || !endTime) {
        return null;
    }

    return (
        <div style={{ margin: "30px 0" }}>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom align="left">
                    Add a Date
                </Typography>
                <ActivityDateStartTimeEndTime
                    date={date}
                    endTime={endTime}
                    onDateEndTimeChange={(date, endTime) => {
                        setDate(date);
                        setEndTime(endTime);
                    }}
                    mustDisableDateInput={false}
                />
                <Button
                    style={{ marginTop: "10px" }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        onAdd(date, endTime);
                        setDate(null);
                        setEndTime(null);
                    }}
                    data-testid="add-another-date-button"
                >
                    Add date
                </Button>
            </Grid>
        </div>
    );
};
export default AddActivityDateStartTimeEndTime;
