import React from "react";
import {
    BooleanField,
    Datagrid,
    EditButton,
    List,
    ListProps,
    NumberField,
    TextField
} from "react-admin";
import DocumentationLink from "../shared/DocumentationLink";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import FacilityFilter from "./FacilityFilter";

const FacilityList = (props: ListProps) => (
    <>
        <List
            filters={<FacilityFilter />}
            filter={{ isDeleted_not: true }}
            {...props}
        >
            <Datagrid>
                <LinkToReferenceField
                    source="name"
                    sourceId="id"
                    reference="Facility"
                    label="Name"
                />
                <LinkToReferenceField
                    source="organisation.name"
                    sourceId="organisation.id"
                    reference="Organisation"
                    label="Organisation"
                />

                <TextField source="nominalCode" />
                <TextField source="stub" />
                <NumberField source="ordering" />
                <BooleanField source="isHiddenFromPublic" label="Hidden?" />
                <BooleanField source="isAvailable" label="Available?" />
                <BooleanField
                    source="isShownOnDefaultView"
                    label="Default view?"
                />
                <EditButton />
            </Datagrid>
        </List>
        <DocumentationLink link="https://docs.google.com/document/d/1uWmijvfjGIMZWYvqzk_EGsEJ-NzOjUMK8iQbwa5-H-E/edit" />
    </>
);

export default FacilityList;
