import React from "react";
import { Datagrid, EditButton, List, ListProps } from "react-admin";
import DocumentationLink from "../shared/DocumentationLink";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import PackageFilter from "./PackageFilter";

const PackageList = (props: ListProps) => (
    <>
        <List
            filters={<PackageFilter />}
            filter={{ isDeleted_not: true }}
            {...props}
        >
            <Datagrid>
                <LinkToReferenceField
                    source="name"
                    sourceId="id"
                    reference="Package"
                    label="Name"
                />
                <LinkToReferenceField
                    source="activity.name"
                    sourceId="activity.id"
                    reference="Activity"
                    label="Activity"
                />

                <EditButton />
            </Datagrid>
        </List>
        <DocumentationLink link="https://docs.google.com/document/d/1iHtMXfoOhlX-nJNio52-70p1R3orhOoPWnKgNX5RdK4/edit" />
    </>
);

export default PackageList;
