import { ApolloClient, ApolloProvider } from "@apollo/client";
import DateFnsUtils from "@date-io/date-fns";
import AddIcon from "@material-ui/icons/Add";
import FaceIcon from "@material-ui/icons/Face";
import GroupIcon from "@material-ui/icons/Group";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import MoodIcon from "@material-ui/icons/Mood";
import PoolIcon from "@material-ui/icons/Pool";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import enLocale from "date-fns/locale/en-GB";
import React from "react";
import {
    Admin,
    LegacyAuthProvider,
    LegacyDataProvider,
    Resource
} from "react-admin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import createApolloClientWithAuth from "../../createApolloClientWithAuth";
import createAuthProvider from "../../createAuthProvider";
import createDataProvider from "../../createDataProvider";
import ActivityCreate from "../Activity/ActivityCreate";
import ActivityEdit from "../Activity/ActivityEdit";
import ActivityList from "../Activity/ActivityList";
import BookingEdit from "../Booking/BookingEdit";
import BookingList from "../Booking/BookingList";
import CouponCreate from "../Coupon/CouponCreate";
import CouponEdit from "../Coupon/CouponEdit";
import CouponList from "../Coupon/CouponList";
import CredentialCreate from "../Credential/CredentialCreate";
import CredentialEdit from "../Credential/CredentialEdit";
import CredentialList from "../Credential/CredentialList";
import CustomerCreate from "../Customer/CustomerCreate";
import CustomerEdit from "../Customer/CustomerEdit";
import CustomerList from "../Customer/CustomerList";
import CustomerBenefitCreate from "../CustomerBenefits/CustomerBenefitCreate";
import CustomerBenefitEdit from "../CustomerBenefits/CustomerBenefitEdit";
import CustomerBenefitList from "../CustomerBenefits/CustomerBenefitList";
import CustomerMembershipCreate from "../CustomerMembership/CustomerMembershipCreate";
import CustomerMembershipEdit from "../CustomerMembership/CustomerMembershipEdit";
import CustomerMembershipList from "../CustomerMembership/CustomerMembershipList";
import CustomRoutes from "../CustomRoutes";
import DiscountCreate from "../Discount/DiscountCreate";
import DiscountEdit from "../Discount/DiscountEdit";
import DiscountList from "../Discount/DiscountList";
import ExtraCreate from "../Extra/ExtraCreate";
import ExtraEdit from "../Extra/ExtraEdit";
import ExtraList from "../Extra/ExtraList";
import FacilityCreate from "../Facility/FacilityCreate";
import FacilityEdit from "../Facility/FacilityEdit";
import FacilityList from "../Facility/FacilityList";
import LayoutWithMenu from "../LayoutWithMenu";
import MembershipCreate from "../Membership/MembershipCreate";
import MembershipEdit from "../Membership/MembershipEdit";
import MembershipList from "../Membership/MembershipList";
import MembershipGroupCreate from "../MembershipGroup/MembershipGroupCreate";
import MembershipGroupEdit from "../MembershipGroup/MembershipGroupEdit";
import MembershipGroupList from "../MembershipGroup/MembershipGroupList";
import OrganisationCreate from "../Organisation/OrganisationCreate";
import OrganisationEdit from "../Organisation/OrganisationEdit";
import OrganisationList from "../Organisation/OrganisationList";
import PackageCreate from "../Package/PackageCreate";
import PackageEdit from "../Package/PackageEdit";
import PackageList from "../Package/PackageList";
import ResourceCreate from "../Resource/ResourceCreate";
import ResourceEdit from "../Resource/ResourceEdit";
import ResourceList from "../Resource/ResourceList";
import ResourceGroupCreate from "../ResourceGroup/ResourceGroupCreate";
import ResourceGroupEdit from "../ResourceGroup/ResourceGroupEdit";
import ResourceGroupList from "../ResourceGroup/ResourceGroupList";
import RoleCreate from "../Role/RoleCreate";
import RoleEdit from "../Role/RoleEdit";
import RoleList from "../Role/RoleList";
import Loading from "../shared/Loading";
import Theme from "../Theme";
import UserCreate from "../User/UserCreate";
import UserEdit from "../User/UserEdit";
import UserList from "../User/UserList";
import RedirectAwayFromReactAdminLoginPage from "./RedirectAwayFromReactAdminLoginPage";

interface IAppProps {
    token: string;
}

interface IAppState {
    apolloClient: ApolloClient<NormalizedCacheObject> | null;
    dataProvider: LegacyDataProvider | null;
    authProvider: LegacyAuthProvider | null;
    token: string;
}

class ReactAdmin extends React.Component<IAppProps, IAppState> {
    constructor(props: IAppProps) {
        super(props);

        this.state = {
            apolloClient: null,
            dataProvider: null,
            authProvider: null,
            token: props.token
        };
    }

    componentDidMount() {
        const apolloClient = createApolloClientWithAuth(this.props.token);

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        createDataProvider(apolloClient).then((dataProvider) => {
            this.setState({
                apolloClient,
                dataProvider,
                authProvider: createAuthProvider(apolloClient)
            });
        });
    }

    render() {
        const { dataProvider, authProvider, apolloClient } = this.state;

        if (!dataProvider || !apolloClient || !authProvider) {
            return <Loading />;
        }

        return (
            <ApolloProvider client={apolloClient}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                    <Admin
                        layout={LayoutWithMenu}
                        theme={Theme}
                        customRoutes={CustomRoutes}
                        dataProvider={dataProvider}
                        authProvider={authProvider}
                        loginPage={RedirectAwayFromReactAdminLoginPage}
                        disableTelemetry
                    >
                        <Resource
                            name="Organisation"
                            list={OrganisationList}
                            edit={OrganisationEdit}
                            create={OrganisationCreate}
                            options={{
                                hidden: true
                            }}
                        />
                        <Resource
                            name="Facility"
                            list={FacilityList}
                            edit={FacilityEdit}
                            create={FacilityCreate}
                            icon={PoolIcon}
                            options={{
                                label: "Facilities"
                            }}
                        />
                        <Resource
                            name="Resource"
                            list={ResourceList}
                            edit={ResourceEdit}
                            create={ResourceCreate}
                            icon={PoolIcon}
                            options={{
                                label: "Resources"
                            }}
                        />
                        <Resource
                            name="ResourceGroup"
                            create={ResourceGroupCreate}
                            edit={ResourceGroupEdit}
                            list={ResourceGroupList}
                            options={{
                                label: "Resource groups"
                            }}
                        />
                        <Resource
                            name="Activity"
                            list={ActivityList}
                            edit={ActivityEdit}
                            create={ActivityCreate}
                            icon={PoolIcon}
                            options={{
                                label: "Activities"
                            }}
                        />
                        <Resource
                            name="Package"
                            list={PackageList}
                            edit={PackageEdit}
                            create={PackageCreate}
                            icon={PoolIcon}
                            options={{
                                label: "Packages"
                            }}
                        />
                        <Resource
                            name="Extra"
                            list={ExtraList}
                            edit={ExtraEdit}
                            create={ExtraCreate}
                            icon={AddIcon}
                            options={{
                                label: "Extras"
                            }}
                        />

                        <Resource
                            name="Customer"
                            create={CustomerCreate}
                            edit={CustomerEdit}
                            list={CustomerList}
                            icon={MoodIcon}
                            options={{
                                label: "Customers",
                                breakAbove: true
                            }}
                        />

                        <Resource
                            name="CustomerBenefit"
                            create={CustomerBenefitCreate}
                            edit={CustomerBenefitEdit}
                            list={CustomerBenefitList}
                            options={{
                                label: "Customer Benefits",
                                breakAbove: true
                            }}
                        />

                        <Resource
                            name="Booking"
                            list={BookingList}
                            edit={BookingEdit}
                            icon={ShoppingCartIcon}
                            options={{
                                label: "Bookings"
                            }}
                        />

                        <Resource
                            name="Credential"
                            create={CredentialCreate}
                            edit={CredentialEdit}
                            list={CredentialList}
                            options={{
                                label: "Credentials"
                            }}
                        />
                        <Resource
                            name="Coupon"
                            list={CouponList}
                            edit={CouponEdit}
                            create={CouponCreate}
                            icon={MonetizationOnIcon}
                            options={{
                                label: "Coupons",
                                breakAbove: true
                            }}
                        />
                        <Resource
                            name="Discount"
                            create={DiscountCreate}
                            edit={DiscountEdit}
                            list={DiscountList}
                            icon={MoneyOffIcon}
                            options={{
                                label: "Discounts"
                            }}
                        />
                        <Resource
                            name="Membership"
                            create={MembershipCreate}
                            edit={MembershipEdit}
                            list={MembershipList}
                            options={{
                                label: "Memberships"
                            }}
                        />
                        <Resource
                            name="MembershipGroup"
                            create={MembershipGroupCreate}
                            edit={MembershipGroupEdit}
                            list={MembershipGroupList}
                            options={{
                                label: "Membership groups"
                            }}
                        />

                        <Resource
                            name="User"
                            create={UserCreate}
                            edit={UserEdit}
                            list={UserList}
                            icon={FaceIcon}
                            options={{
                                label: "Users",
                                breakAbove: true
                            }}
                        />
                        <Resource
                            name="Role"
                            create={RoleCreate}
                            edit={RoleEdit}
                            list={RoleList}
                            icon={GroupIcon}
                            options={{
                                label: "Roles"
                            }}
                        />

                        <Resource
                            name="CustomerMembership"
                            create={CustomerMembershipCreate}
                            edit={CustomerMembershipEdit}
                            list={CustomerMembershipList}
                            options={{
                                label: "Customer memberships",
                                hidden: true
                            }}
                        />
                    </Admin>

                    <ToastContainer />
                </MuiPickersUtilsProvider>
            </ApolloProvider>
        );
    }
}

export default ReactAdmin;
