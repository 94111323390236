import produce from "immer";
import React from "react";
import { IAction } from "./interfaces/IAction";
import { IState } from "./interfaces/IState";
import { FilterNames } from "./interfaces/FilterNames";
import { ISelectFilter } from "./interfaces/ISelectFilter";
import timeUtil from "../../utils/timelineUtil";

export enum ActionType {
    PopulateFilters = "POPULATE_FILTERS",
    SetFilter = "SET_FILTER",
    RequestReport = "REQUEST_REPORT",
    SetIsLoading = "SET_IS_LOADING",
    ResetFilters = "RESET_FILTERS",
    SetUpdateReportInfo = "SET_UPDATE_REPORT_INFO",
    RequestToPopulateBookingReport = "REQUEST_TO_POPULATE_BOOKING_REPORT",
    RequestToRefreshAllBookingReportData = "REQUEST_TO_REFRESH_ALL_BOOKING_REPORT_DATA"
}

const mapIdNameToValueLabel = (input: { id: string; name: string }) => {
    return { value: input.id, label: input.name };
};

const createOptions = (items: { label: string; value: string }[]) => {
    return [{ label: "Any", value: "any" }].concat(
        items.map(mapIdNameToValueLabel).sort((a, b) => {
            const nameA = a.label.toUpperCase();
            const nameB = b.label.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        })
    );
};

function instanceOfISelectFilter(object: any): object is ISelectFilter {
    if (!object || typeof object === "string") {
        return false;
    }
    return "selectedValue" in object;
}

export const dashboardReducer: React.Reducer<IState, IAction> = (
    state,
    action
) => {
    switch (action.type) {
        case ActionType.PopulateFilters: {
            const nextState = produce(state, (draft) => {
                if (!state.activityFilter) {
                    const filtersData = action.payload;
                    const times = timeUtil.computeTimeline("7:00", "23:00", 60);

                    const timeOptions = [{ label: "Any", value: "any" }].concat(
                        times.map((t) => ({
                            label: t,
                            value: t
                        }))
                    );

                    draft.activityFilter = {
                        options: createOptions(filtersData.activities),
                        selectedValue: "any",
                        filterName: FilterNames.activityFilterName
                    };
                    draft.resourceFilter = {
                        options: createOptions(filtersData.resources),
                        selectedValue: "any",
                        filterName: FilterNames.resourceFilterName
                    };
                    draft.facilityFilter = {
                        options: createOptions(filtersData.facilities),
                        selectedValue: "any",
                        filterName: FilterNames.facilityFilterName
                    };
                    draft.membershipFilter = {
                        options: createOptions(filtersData.memberships),
                        selectedValue: "any",
                        filterName: FilterNames.membershipFilterName
                    };
                    draft.startTimeFilter = {
                        options: timeOptions,
                        selectedValue: "any",
                        filterName: FilterNames.startTimeFilterName
                    };
                    draft.endTimeFilter = {
                        options: timeOptions,
                        selectedValue: "any",
                        filterName: FilterNames.endTimeFilterName
                    };
                    draft.extraFilter = {
                        options: createOptions(filtersData.extras),
                        selectedValue: "any",
                        filterName: FilterNames.extraFilterName
                    };
                    draft.couponFilter = {
                        options: createOptions(filtersData.coupons),
                        selectedValue: "any",
                        filterName: FilterNames.couponFilterName
                    };
                    draft.membershipUsedFilter = {
                        options: createOptions(filtersData.memberships),
                        selectedValue: "any",
                        filterName: FilterNames.membershipUsedFilterName
                    };
                    draft.isAdminBookingFilter = {
                        options: [
                            { label: "Either", value: "either" },
                            { label: "Yes", value: "yes" },
                            { label: "No", value: "no" }
                        ],
                        selectedValue: "either",
                        filterName: FilterNames.isAdminBookingFilterName
                    };
                    draft.hasBalanceFilter = {
                        options: [
                            { label: "Either", value: "either" },
                            { label: "Yes", value: "yes" },
                            { label: "No", value: "no" }
                        ],
                        selectedValue: "either",
                        filterName: FilterNames.hasBalanceFilterName
                    };
                    draft.hasDiscountFilter = {
                        options: [
                            {
                                label: "Either",
                                value: "either"
                            },
                            { label: "Yes", value: "yes" },
                            { label: "No", value: "no" }
                        ],
                        selectedValue: "either",
                        filterName: FilterNames.hasDiscountFilterName
                    };
                    draft.isCheckedInFilter = {
                        options: [
                            { label: "Either", value: "either" },
                            { label: "Yes", value: "yes" },
                            { label: "No", value: "no" }
                        ],
                        selectedValue: "either",
                        filterName: FilterNames.isCheckedInFilterName
                    };
                    draft.reportTypeFilter = {
                        options: [
                            {
                                label: "Transaction report",
                                value: "transaction"
                            },
                            { label: "Basic report", value: "basic" },
                            { label: "Site report", value: "site" },
                            { label: "Income report", value: "income" }
                        ],
                        selectedValue: "transaction",
                        filterName: FilterNames.reportTypeFilterName
                    };
                }
            });
            return nextState;
        }
        case ActionType.SetFilter: {
            const nextState = produce(state, (draft) => {
                if (action.payload) {
                    const { value, filterName } = action.payload;
                    if (instanceOfISelectFilter(draft[filterName])) {
                        draft[filterName].selectedValue = value;
                    } else {
                        draft[filterName] = value;
                    }
                }
            });

            return nextState;
        }
        case ActionType.ResetFilters: {
            const nextState = produce(state, (draft) => {
                draft.activityFilter.selectedValue = "any";
                draft.resourceFilter.selectedValue = "any";
                draft.facilityFilter.selectedValue = "any";
                draft.startTimeFilter.selectedValue = "any";
                draft.endTimeFilter.selectedValue = "any";
                draft.extraFilter.selectedValue = "any";
                draft.couponFilter.selectedValue = "any";
                draft.membershipUsedFilter.selectedValue = "any";
                draft.membershipFilter.selectedValue = "any";
                draft.isAdminBookingFilter.selectedValue = "either";
                draft.hasBalanceFilter.selectedValue = "either";
                draft.hasDiscountFilter.selectedValue = "either";
                draft.isCheckedInFilter.selectedValue = "either";
                draft.completedDateFrom = null;
                draft.completedDateTo = null;
                draft.activityDateFrom = null;
                draft.activityDateTo = null;
            });

            return nextState;
        }
        case ActionType.SetIsLoading: {
            const nextState = produce(state, (draft) => {
                draft.isLoading = true;
            });

            return nextState;
        }
        case ActionType.RequestReport: {
            const nextState = produce(state, (draft) => {
                if (action.payload) {
                    const { requestReport } = action.payload;
                    draft.reportSummary = requestReport;
                    draft.isLoading = false;
                }
            });

            return nextState;
        }
        case ActionType.SetUpdateReportInfo: {
            const nextState = produce(state, (draft) => {
                if (action.payload) {
                    const { reportUpdateInfo } = action.payload;
                    draft.reportUpdateInfo = reportUpdateInfo;
                    draft.shouldReloadUpdateInfo = false;
                    draft.isLoading = false;
                }
            });

            return nextState;
        }
        default: {
            throw new Error(`Unhandled type: ${action.type}`);
        }
    }
};

export default dashboardReducer;
