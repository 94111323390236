import useDashboard from "../useDashboard";
import SelectInput from "../../shared/SelectInput";
import React from "react";

const FacilityFilter = () => {
    const { facilityFilter, setFilter } = useDashboard();

    return facilityFilter ? (
        <SelectInput
            label="Facility"
            options={facilityFilter.options}
            isDisabled={false}
            onChangeHandler={(e) => {
                setFilter(e, facilityFilter.filterName);
            }}
            selectedValue={facilityFilter.selectedValue}
        />
    ) : null;
};

export default FacilityFilter;
