import React from "react";
import Dashboard from "./Dashboard";
import { DashboardContextProvider } from "./useDashboard/DashboardContext";

const DashboardWithContext = () => {
    return (
        <DashboardContextProvider>
            <Dashboard />
        </DashboardContextProvider>
    );
};

export default DashboardWithContext;
