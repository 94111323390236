import {
    TextInput,
    Filter,
    NullableBooleanInput,
    FilterProps
} from "react-admin";
import React from "react";

const CustomerFilter = (props: Omit<FilterProps, "children">) => (
    <Filter {...props}>
        <TextInput
            style={{ width: "300px" }}
            label="Search (UPPER case)"
            source="searchableUpper_contains"
            alwaysOn
            resettable
            parse={(value: string) => value.toUpperCase()}
        />

        <NullableBooleanInput label="Disabled?" source="isDisabled" alwaysOn />
    </Filter>
);

export default CustomerFilter;
