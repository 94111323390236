import { OperationVariables } from "apollo-client";
import { useState, useEffect } from "react";
import { MutationOptions, useMutation } from "@apollo/client";

import useStateWithLocalStorage from "../../useStateWithLocalStorage";
import { IWeekTemplateWithTimeLine } from "../interfaces";
import useLoadTemplate from "./useLoadTemplate";
import {
    CREATE_WEEK_TEMPLATE_MUTATION,
    UPDATE_WEEK_TEMPLATE_MUTATION,
    DELETE_WEEK_TEMPLATE_MUTATION
} from "./queries";
import useLoadDefaultWeekTemplate from "./useLoadDefaultWeekTemplate";
import { isEqual } from "lodash";

export const useWeekTemplate: IUseWeekTemplate = () => {
    const [resourceId, setResourceId] = useState("");
    const [weekTemplateId, setWeekTemplateId] = useState("");
    const [weekTemplate, setWeekTemplate, getStateFromLocalStorageByKey] =
        useStateWithLocalStorage<IWeekTemplateWithTimeLine>();

    if (resourceId) {
        getStateFromLocalStorageByKey(`${resourceId}-WeekTemplate`);
    }

    const defaultWeekTemplate = useLoadDefaultWeekTemplate(resourceId);

    useEffect(() => {
        if (
            defaultWeekTemplate &&
            weekTemplate &&
            !isEqual(
                defaultWeekTemplate.resource.activities,
                weekTemplate.resource.activities
            )
        ) {
            setWeekTemplate({
                ...weekTemplate,
                resource: defaultWeekTemplate.resource
            });
        }
    }, [defaultWeekTemplate, weekTemplate]);

    const existingWeekTemplate = useLoadTemplate(weekTemplateId, weekTemplate);

    if (defaultWeekTemplate && !weekTemplate) {
        setWeekTemplate(defaultWeekTemplate);
    } else if (existingWeekTemplate) {
        setWeekTemplate(existingWeekTemplate);
    }

    const [createWeekTemplate] = useMutation(CREATE_WEEK_TEMPLATE_MUTATION);
    const [updateWeekTemplate] = useMutation(UPDATE_WEEK_TEMPLATE_MUTATION);
    const [deleteWeekTemplate] = useMutation(DELETE_WEEK_TEMPLATE_MUTATION);

    return {
        weekTemplate,
        setWeekTemplate,
        setWeekTemplateId,
        createWeekTemplate,
        updateWeekTemplate,
        deleteWeekTemplate,
        resourceId,
        setResourceId
    };
};

type IUseWeekTemplate = (initialResourceId?: string) => {
    weekTemplate: IWeekTemplateWithTimeLine;
    setWeekTemplate: (weekTemplate: IWeekTemplateWithTimeLine) => void;
    setWeekTemplateId: (weekTemplateId: string) => void;
    createWeekTemplate: any; // MutationFn<{}, OperationVariables>;
    updateWeekTemplate: any; // MutationFn<{}, OperationVariables>;
    deleteWeekTemplate: any; // MutationFn<{}, OperationVariables>;
    resourceId: string;
    setResourceId: (resourceId: string) => void;
};

export default useWeekTemplate;
