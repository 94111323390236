import React from "react";
import PhotoUpload from "./PhotoUpload";

interface ICustomerMembershipUploadPhotoProps {
    record?: {
        customer: {
            id: string;
        };
        id: string;
    };
}

const CustomerMembershipUploadPhoto = ({
    record
}: ICustomerMembershipUploadPhotoProps) => {
    if (!record) {
        return null;
    }
    return (
        <PhotoUpload
            customerId={record.customer.id}
            customerMembershipId={record.id}
        />
    );
};

export default CustomerMembershipUploadPhoto;
