import React from "react";
import BooleanFilter from "./BooleanFilter";
import { FilterNames } from "../useDashboard/interfaces/FilterNames";
import useDashboard from "../useDashboard";
import SelectInput from "../../shared/SelectInput";

const HasDiscountFilter = () => {
    const { hasDiscountFilter, setFilter } = useDashboard();

    return hasDiscountFilter ? (
        <SelectInput
            label="Does it have a discount?"
            options={hasDiscountFilter.options}
            isDisabled={false}
            onChangeHandler={(e) => {
                setFilter(e, hasDiscountFilter.filterName);
            }}
            selectedValue={hasDiscountFilter.selectedValue}
        />
    ) : null;
};

export default HasDiscountFilter;
