import { gql } from "@apollo/client";

const packageFragment = gql`
    fragment package on Package {
        id
        name
        description
        updatedAt
        activity {
            id
            name
            defaultSlotLengthMinutes
        }
        activityInstances(orderBy: pricePerBooking_DESC) {
            id
            bookings {
                id
            }
            daySchedule {
                id
                date
            }
            startTime
            endTime
            pricePerBooking
        }
        isDeleted
        deletedAt
        deletedByUser {
            id
            name
        }
        bookings {
            id
            reference
            packageReference
        }
    }
`;

export default packageFragment;
