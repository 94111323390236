import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import React, { useState } from "react";
import { useAuthContext } from "./AuthContext";

export const Login = () => {
    const { setToken } = useAuthContext();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const doLogin = async (email: string, password: string): Promise<void> => {
        const response = await fetch(`${process.env.ENDPOINT}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email,
                password
            })
        });

        const parsedResponse = await response.json();

        if (parsedResponse && parsedResponse.token) {
            setToken(parsedResponse.token);
            setErrorMessage("");
        }

        if (parsedResponse && parsedResponse.errorMessage) {
            setErrorMessage(parsedResponse.errorMessage);
        }
    };
    return (
        <div>
            <div
                style={{ margin: "auto", marginTop: "40px", maxWidth: "400px" }}
            >
                <Paper>
                    <div style={{ padding: "30px" }}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Email</InputLabel>
                            <Input
                                data-testid="email-input"
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}
                                onKeyDown={async (event) => {
                                    if (event.key === "Enter") {
                                        await doLogin(email, password);
                                    }
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <InputLabel>Password</InputLabel>
                            <Input
                                data-testid="password-input"
                                value={password}
                                type="password"
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                }}
                                onKeyDown={async (event) => {
                                    if (event.key === "Enter") {
                                        await doLogin(email, password);
                                    }
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <Button
                                data-testid="login-button"
                                onClick={async () => {
                                    await doLogin(email, password);
                                }}
                            >
                                Login
                            </Button>
                        </FormControl>
                        {errorMessage ? (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                        ) : null}
                    </div>
                </Paper>
            </div>
        </div>
    );
};

export default Login;
