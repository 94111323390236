import { Button, Grid } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useDropzone, DropzoneOptions, FileRejection } from "react-dropzone";
import { toast } from "react-toastify";
import useUploadCustomerMembershipPhoto from "./useUploadCustomerMembershipPhoto";

interface IPhotoUploadProps {
    customerId: string;
    customerMembershipId: string;
}

const PhotoUpload = ({
    customerId,
    customerMembershipId
}: IPhotoUploadProps) => {
    const [base64ImageData, setBase64ImageData] = useState<string | null>(null);
    const [isPhotoUploaded, setIsPhotoUploaded] = useState(false);
    const {
        uploadCustomerMembershipPhoto
    } = useUploadCustomerMembershipPhoto();

    const onDrop: DropzoneOptions["onDrop"] = useCallback(
        (acceptedFiles, fileRejections) => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = () => {
                const { result } = reader;
                setBase64ImageData(result as string);
            };
            acceptedFiles.forEach((file: File) => reader.readAsDataURL(file));
            fileRejections.forEach((fileRejection: FileRejection) => {
                console.log(
                    `Rejected file: ${
                        fileRejection.file.name
                    }. Errors: ${fileRejection.errors.map(
                        (error) => `${error.code} `
                    )}`
                );
            });
        },
        []
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/*",
        onDrop
    });

    return (
        <div style={{ padding: "30px" }}>
            <Grid container direction="row" alignItems="flex-end" spacing={8}>
                {!isPhotoUploaded ? (
                    <>
                        <Grid {...getRootProps()} item xs={12}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <p>Drop the image here ...</p>
                            ) : (
                                <p>
                                    Drag &apos;n&apos; drop an image, or click
                                    to select one
                                </p>
                            )}
                            {base64ImageData ? (
                                <img src={base64ImageData} />
                            ) : null}
                        </Grid>
                        <Grid item xs={12}>
                            {base64ImageData ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={async () => {
                                        const resultBase64Image = await uploadCustomerMembershipPhoto(
                                            customerId,
                                            customerMembershipId,
                                            base64ImageData
                                        );
                                        if (resultBase64Image) {
                                            toast("Photo uploaded!");
                                            setIsPhotoUploaded(true);
                                        }
                                    }}
                                >
                                    Upload
                                </Button>
                            ) : null}
                        </Grid>{" "}
                    </>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setIsPhotoUploaded(false);
                            setBase64ImageData(null);
                        }}
                    >
                        Upload another photo
                    </Button>
                )}
            </Grid>
        </div>
    );
};

export default PhotoUpload;
