import useDashboard from "../useDashboard";
import SelectInput from "../../shared/SelectInput";
import React from "react";

const EndTimeFilter = () => {
    const { endTimeFilter, setFilter } = useDashboard();

    return endTimeFilter ? (
        <SelectInput
            label="End time"
            options={endTimeFilter.options}
            isDisabled={false}
            onChangeHandler={(e) => {
                setFilter(e, endTimeFilter.filterName);
            }}
            selectedValue={endTimeFilter.selectedValue}
        />
    ) : null;
};

export default EndTimeFilter;
