import {
    ApolloClient,
    createHttpLink,
    DefaultOptions,
    InMemoryCache,
    NormalizedCacheObject
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const createApolloClientWithAuth = (
    token: string
): ApolloClient<NormalizedCacheObject> => {
    const httpLink = createHttpLink({ uri: process.env.GRAPHQL_ENDPOINT });

    const authLink = setContext((_request, { headers }) => {
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : ""
            }
        };
    });

    const defaultApolloOptions: DefaultOptions = {
        watchQuery: {
            fetchPolicy: "cache-and-network",
            errorPolicy: "ignore"
        },
        query: {
            fetchPolicy: "network-only",
            errorPolicy: "all"
        },
        mutate: {
            errorPolicy: "all"
        }
    };

    const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
        defaultOptions: defaultApolloOptions
    });

    return client;
};

export default createApolloClientWithAuth;
