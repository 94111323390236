import Button from "@material-ui/core/Button";
import React from "react";
import useDashboard from "./useDashboard";

const ResetFilters = () => {
    const { resetFilters } = useDashboard();

    return (
        <Button
            variant="contained"
            color="secondary"
            onClick={() => resetFilters()}
        >
            Reset filters
        </Button>
    );
};

export default ResetFilters;
