import { FormHelperText } from "@material-ui/core";
import RichTextInput from "ra-input-rich-text";
import React from "react";
import {
    ArrayField,
    BooleanField,
    BooleanInput,
    Datagrid,
    Edit,
    EditProps,
    FormTab,
    NumberField,
    NumberInput,
    ReferenceInput,
    regex,
    required,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput
} from "react-admin";
import DocumentationLink from "../shared/DocumentationLink";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import RecordLink from "../shared/RecordLink";
import ResourceEditActions from "./ResourceEditActions";

const ResourceEdit = (props: EditProps) => {
    return (
        <>
            <Edit
                title="Edit Resource"
                actions={<ResourceEditActions />}
                {...props}
            >
                <TabbedForm redirect="edit">
                    <FormTab label="summary">
                        <TextInput
                            source="name"
                            fullWidth
                            validate={[required()]}
                            helperText="The publicly visible name for this Resource"
                        />
                        <RichTextInput
                            source="description"
                            validate={[required()]}
                        />
                        <ReferenceInput
                            label="Facility"
                            source="facility.id"
                            reference="Facility"
                            sort={{ field: "name", order: "ASC" }}
                            perPage={200}
                            fullWidth
                            helperText="The facility for this resource"
                        >
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <LinkToReferenceField
                            label="Link to facility"
                            sourceId="facility.id"
                            source="facility.name"
                            reference="Facility"
                        />
                        <NumberInput
                            source="defaultMaxCapacity"
                            validate={[required()]}
                            helperText="The default maximum capacity when adding a new Activity"
                        />
                        <TextInput
                            source="imageUrl"
                            validate={[required()]}
                            fullWidth
                            helperText="For now, images should be uploaded on https://www.lesormesjersey.co.uk/umbraco and the URL entered here"
                        />
                        <TextInput
                            source="overrideNominalCode"
                            helperText="If not set, the nominal code for the Facility will be used"
                        />
                        <TextInput
                            source="stub"
                            fullWidth
                            validate={[
                                regex(
                                    /^(\w+)(-?(\w+)?)+$/,
                                    "Letters, numbers and dashes (-) only"
                                ),
                                required()
                            ]}
                            helperText="The last part of the URL for this resource. Should be in the form name-of-resource. Must be unique amongst resources"
                        />
                        <TextInput
                            source="dailyStartTime"
                            validate={[
                                required(),
                                regex(
                                    /([01]?[0-9]|2[0-3]):[0-5][0-9]/,
                                    "Must be HH:mm format"
                                )
                            ]}
                            helperText="Enter the earliest time an activity will start as HH:MM e.g. 07:00"
                        />
                        <TextInput
                            source="dailyEndTime"
                            validate={[
                                required(),
                                regex(
                                    /([01]?[0-9]|2[0-3]):[0-5][0-9]/,
                                    "Must be HH:mm format"
                                )
                            ]}
                            helperText="Enter the latest time an activity will end as HH:MM e.g. 18:00"
                        />

                        <TextInput
                            source="rulesOfUseHeading"
                            fullWidth
                            helperText="The heading for the 'Rules of Use' for this Resource"
                        />
                        <RichTextInput source="rulesOfUse" />
                        <FormHelperText className="extra-helper-text">
                            &apos;Rules of Use&apos; for this Resource
                        </FormHelperText>
                        <NumberInput
                            source="ordering"
                            helperText="Order in which the resource is displayed"
                        />
                        <BooleanInput
                            source="isAvailable"
                            validate={[required()]}
                            helperText="If not set, this resource will not be displayed anywhere (website or mid-admin)"
                        />
                        <BooleanInput
                            source="isHiddenFromPublic"
                            validate={[required()]}
                            helperText="If set, this will not be available on the website"
                        />
                        <BooleanInput
                            source="isShownOnDefaultView"
                            defaultValue={false}
                            helperText="If set, this will not be available on the default view"
                        />
                    </FormTab>
                    <FormTab label="activities">
                        <ArrayField source="activities">
                            <Datagrid>
                                <LinkToReferenceField
                                    label="Name"
                                    source="name"
                                    reference="Activity"
                                />
                                <TextField source="ordering" />
                                <BooleanField
                                    source="isHiddenFromPublic"
                                    label="Hidden?"
                                />
                                <BooleanField
                                    source="isAvailable"
                                    label="Available?"
                                />
                            </Datagrid>
                        </ArrayField>
                    </FormTab>
                    <FormTab label="extras">
                        <ArrayField source="extras">
                            <Datagrid>
                                <LinkToReferenceField
                                    source="name"
                                    reference="Extra"
                                />
                            </Datagrid>
                        </ArrayField>
                    </FormTab>
                    <FormTab label="week templates">
                        <ArrayField source="weekTemplates">
                            <Datagrid>
                                {/* <TextField source="id" /> */}
                                <RecordLink
                                    source="name"
                                    baseUrl="/c/week-template"
                                />
                            </Datagrid>
                        </ArrayField>
                    </FormTab>
                    <FormTab label="memberships">
                        <ArrayField source="memberships">
                            <Datagrid>
                                <LinkToReferenceField
                                    label="Name"
                                    source="name"
                                    reference="Membership"
                                />
                                <NumberField label="Price" source="price" />
                                <BooleanField
                                    source="isAvailable"
                                    label="Available?"
                                />
                            </Datagrid>
                        </ArrayField>
                    </FormTab>
                    <FormTab label="Resource Groups">
                        <ArrayField source="resourceGroups">
                            <Datagrid>
                                <LinkToReferenceField
                                    source="name"
                                    reference="ResourceGroup"
                                />
                            </Datagrid>
                        </ArrayField>
                    </FormTab>
                    <FormTab label="self check-in">
                        <RichTextInput source="preCheckInInstruction" />
                        <RichTextInput source="postCheckInInstruction" />
                    </FormTab>
                </TabbedForm>
            </Edit>
            <DocumentationLink link="https://docs.google.com/document/d/1UgGuuH5T-lAsnEpjFA_QOKoZNmQgYJzpnjBsT8dk9_k/edit" />
        </>
    );
};

export default ResourceEdit;
