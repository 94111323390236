import { difference, dropRight, flatten } from "lodash";
import {
    addDurationToMomentTimeOfDay,
    toMomentDurationFromMinutes,
    toMomentTimeOfDay,
    toTimeOfDayString,
    toTotalInMinutes
} from "@les-ormes/lib";

const computeManyTimelinesWithoutEndTime = (
    startEndTimes: Array<ITimeInterval<string>>,
    interval: number
) => {
    return startEndTimes.map((t) => {
        const { startTime, endTime } = t;
        return dropRight(
            timelineUtil.computeTimeline(startTime, endTime, interval)
        );
    });
};

const timelineUtil: ITimelineUtil = {
    computeTimeline(startTime, endTime, interval) {
        const startMoment = toMomentTimeOfDay(startTime);
        const endMoment = toMomentTimeOfDay(endTime);
        const intervalMoment = toMomentDurationFromMinutes(interval);
        const times = Array<string>();
        let currentMoment = startMoment;

        while (currentMoment <= endMoment) {
            times.push(toTimeOfDayString(currentMoment));
            currentMoment = addDurationToMomentTimeOfDay(
                currentMoment,
                intervalMoment
            );
        }

        return times;
    },
    computeAvailableTimes(fullTimeline, startEndTimeWithIntervals, interval) {
        return difference(
            dropRight(fullTimeline),
            flatten(
                computeManyTimelinesWithoutEndTime(
                    startEndTimeWithIntervals,
                    interval
                )
            )
        );
    },
    computeActivityTimelineStyles(
        dailyStartTime,
        startTime,
        endTime,
        colorScheme,
        timePrecision
    ) {
        const dailyStartTimeInMinutes = toTotalInMinutes(dailyStartTime);
        const startTimeInMinutes = toTotalInMinutes(startTime);
        const endTimeInMinutes = toTotalInMinutes(endTime);
        const durationInMinutes = endTimeInMinutes - startTimeInMinutes;

        const eventSlotHeight = 50;

        const eventTop =
            (eventSlotHeight * (startTimeInMinutes - dailyStartTimeInMinutes)) /
            timePrecision;
        const eventHeight =
            (eventSlotHeight * durationInMinutes) / timePrecision;

        const css = {
            top: eventTop,
            height: eventHeight,
            background: colorScheme
        };

        return css;
    },
    checkCanAllocateTimeIntervalInTimeline(
        busyStartEndTimes,
        startTime,
        endTime,
        closingTime
    ) {
        const busyStartEndTimesInNumbers: Array<ITimeInterval<number>> =
            busyStartEndTimes.map((b) => {
                return {
                    startTime: parseInt(b.startTime.replace(":", ""), 10),
                    endTime: parseInt(b.endTime.replace(":", ""), 10)
                };
            });

        const startTimeNumber = parseInt(startTime.replace(":", ""), 10);
        const endTimeNumber = parseInt(endTime.replace(":", ""), 10);

        const doesItOverlap =
            busyStartEndTimesInNumbers.filter(
                (b) =>
                    (startTimeNumber <= b.startTime &&
                        endTimeNumber > b.startTime) ||
                    (startTimeNumber >= b.startTime &&
                        endTimeNumber <= b.endTime) ||
                    (startTimeNumber < b.endTime && endTimeNumber >= b.endTime)
            ).length > 0;

        return !doesItOverlap && endTime <= closingTime;
    }
};

interface ITimelineUtil {
    computeTimeline(
        startTime: string,
        endTime: string,
        interval: number
    ): string[];
    computeAvailableTimes(
        fullTimeline: string[],
        startEndTimes: Array<ITimeInterval<string>>,
        interval: number
    ): string[];
    computeActivityTimelineStyles(
        dailyStartTime: string,
        startTime: string,
        endTime: string,
        colorScheme: string,
        timePrecision: number
    ): IActivityTimelineStyles;
    checkCanAllocateTimeIntervalInTimeline(
        busyStartEndTimes: Array<ITimeInterval<string>>,
        startTime: string,
        endTime: string,
        closingTime: string
    );
}

export interface ITimeInterval<T> {
    startTime: T;
    endTime: T;
}

export interface IActivityTimelineStyles {
    top: number;
    height: number;
    background: string;
}

export default timelineUtil;
