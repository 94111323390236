import { addMinutesToTimeOfDay } from "@les-ormes/lib";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { IActivity } from "../../../../generated/dataInterfaces";
import SelectInput from "../../shared/SelectInput";
import { INewActivityInstanceInput } from "../useWeekScheduler/interfaces";

const CreateForm = ({
    dayScheduleId,
    availableStartTimes,
    availableEndTimes,
    activities,
    submitHandler
}: ICreateFormProps) => {
    const [selectedStartTime, setSelectedStartTime] = useState("");
    const [selectedEndTime, setSelectedEndTime] = useState("");
    const [selectedActivityId, setSelectedActivityId] = useState("");
    const [maxCapacity, setMaxCapacity] = useState(20);
    const [quantityUnavailable, setQuantityUnavailable] = useState(0);
    const [pricePerBooking, setPricePerBooking] = useState(10.99);

    const selectEndTimeBasedOnSelectedActivity = () => {
        const selectedActivity = activities.find(
            (a) => a.id === selectedActivityId
        );
        if (selectedActivity && selectedStartTime) {
            const suggestedEndTime = addMinutesToTimeOfDay(
                selectedStartTime,
                selectedActivity.defaultSlotLengthMinutes
            );
            setSelectedEndTime(suggestedEndTime);
        }
    };

    useEffect(selectEndTimeBasedOnSelectedActivity, [
        selectedActivityId,
        selectedStartTime
    ]);

    const setMaxCapacityAndPricePerBooking = () => {
        const selectedActivity = activities.find(
            (a) => a.id === selectedActivityId
        );

        if (selectedActivity) {
            if (selectedActivity.resource) {
                setMaxCapacity(selectedActivity.resource.defaultMaxCapacity);
            }
            setPricePerBooking(selectedActivity.defaultPricePerBooking);
        }
    };

    useEffect(setMaxCapacityAndPricePerBooking, [selectedActivityId]);

    const newActivityInstance: INewActivityInstanceInput = {
        dayScheduleId,
        startTime: selectedStartTime,
        endTime: selectedEndTime,
        activityId: selectedActivityId,
        maxCapacity,
        quantityUnavailable,
        pricePerBooking
    };

    return (
        <>
            <DialogContent>
                <DialogContentText>Activity:</DialogContentText>
                <SelectInput
                    label=""
                    options={activities.map((a) => {
                        return {
                            value: a.id,
                            label: `${a.name} - ${a.defaultSlotLengthMinutes} minutes`,
                            isDisabled: a.isPackaged
                        };
                    })}
                    onChangeHandler={setSelectedActivityId}
                    selectedValue={selectedActivityId}
                    name="activity"
                />
            </DialogContent>
            <DialogContent>
                <DialogContentText>Start time:</DialogContentText>
                <SelectInput
                    label=""
                    options={availableStartTimes.map((t) => {
                        return { value: t, label: t };
                    })}
                    isDisabled={false}
                    onChangeHandler={setSelectedStartTime}
                    selectedValue={selectedStartTime}
                    name="start-time"
                />
            </DialogContent>
            <DialogContent>
                <DialogContentText>End time:</DialogContentText>
                <SelectInput
                    label=""
                    options={availableEndTimes.map((t) => {
                        return { value: t, label: t };
                    })}
                    isDisabled={false}
                    onChangeHandler={setSelectedEndTime}
                    selectedValue={selectedEndTime}
                    name="end-time"
                />
            </DialogContent>
            <DialogContent>
                <DialogContentText>Max Capacity:</DialogContentText>
                <TextField
                    label=""
                    disabled={false}
                    onChange={(event) =>
                        setMaxCapacity(parseInt(event.target.value))
                    }
                    type="number"
                    value={maxCapacity}
                    InputLabelProps={{
                        shrink: true
                    }}
                    margin="normal"
                />
            </DialogContent>
            <DialogContent>
                <DialogContentText>Quantity Unavailable:</DialogContentText>
                <TextField
                    label=""
                    disabled={false}
                    onChange={(event) =>
                        setQuantityUnavailable(parseInt(event.target.value, 10))
                    }
                    type="number"
                    value={quantityUnavailable}
                    InputLabelProps={{
                        shrink: true
                    }}
                    margin="normal"
                />
            </DialogContent>
            <DialogContent>
                <DialogContentText>Price per booking:</DialogContentText>
                <TextField
                    label=""
                    disabled={false}
                    onChange={(event) =>
                        setPricePerBooking(parseFloat(event.target.value))
                    }
                    type="float"
                    value={pricePerBooking}
                    InputLabelProps={{
                        shrink: true
                    }}
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => submitHandler(newActivityInstance)}
                    data-testid="add-activity-button"
                >
                    Add
                </Button>
            </DialogActions>
        </>
    );
};

interface ICreateFormProps {
    dayScheduleId: string;
    availableStartTimes: string[];
    availableEndTimes: string[];
    activities: IActivity[];
    submitHandler: (data: any) => void;
}

export default CreateForm;
