import React from "react";
import {
    BooleanInput,
    Edit,
    EditProps,
    FormTab,
    NumberInput,
    ReferenceArrayInput,
    required,
    SelectArrayInput,
    SelectInput,
    TextInput
} from "react-admin";
import daysOfTheWeek from "../../daysOfTheWeek";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import DocumentationLink from "../shared/DocumentationLink";
import FormWithRedirect from "../shared/FormWithRedirect";
import OptionTextRenderer from "../shared/OptionTextRendered";

const DiscountEdit = (props: EditProps) => {
    return (
        <>
            <Edit title="Edit a Discount" {...props}>
                <FormWithRedirect isTabbed={true}>
                    <FormTab label="summary">
                        <TextInput source="name" validate={[required()]} />
                        <TextInput
                            source="description"
                            validate={[required()]}
                        />
                        <BooleanInput source="isActive" defaultValue={true} />
                        <NumberInput source="discountAmount" />
                        <DisabledHiddenOrganisationIdInput />
                        <SelectInput
                            source="discountType"
                            choices={[
                                { id: "MONEY", name: "MONEY" },
                                { id: "PERCENTAGE", name: "PERCENTAGE" }
                            ]}
                        />
                        <SelectArrayInput
                            label="Valid days? Blank for all"
                            source="validDaysOfWeek"
                            choices={daysOfTheWeek}
                            optionText="label"
                            optionValue="id"
                            helperText="If empty, this will apply to all days"
                        />
                        <ReferenceArrayInput
                            label="Valid activities? Blank for all"
                            source="validActivityIds"
                            reference="Activity"
                            sort={{ field: "name", order: "ASC" }}
                            perPage={200}
                        >
                            <SelectArrayInput
                                optionText={
                                    <OptionTextRenderer
                                        paths={["name", "resource.name"]}
                                    />
                                }
                            />
                        </ReferenceArrayInput>

                        <NumberInput
                            source="validForMaxQuantity"
                            label="Maximum number of tickets"
                            helperText="Maximum number of tickets the discount will apply to. If empty it will apply to many tickets"
                        />

                        <BooleanInput
                            label="Applies to activities?"
                            source="appliesToActivities"
                            defaultValue={true}
                        />

                        <BooleanInput
                            label="Applies to extras?"
                            source="appliesToExtras"
                            defaultValue={false}
                        />
                    </FormTab>
                </FormWithRedirect>
            </Edit>
            <DocumentationLink link="https://docs.google.com/document/d/14Sa8rJ_eNt39x0g3KOTrt_VvmtVsGAzAjEN6fUBwjOE/edit" />
        </>
    );
};

export default DiscountEdit;
