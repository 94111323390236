import React from "react";
import {
    ArrayField,
    BooleanInput,
    Datagrid,
    Edit,
    EditProps,
    FormTab,
    required,
    TextField,
    TextInput
} from "react-admin";
import { useLocation } from "react-router-dom";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import DocumentationLink from "../shared/DocumentationLink";
import FormWithRedirect from "../shared/FormWithRedirect";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import SingleLinkToReferenceField from "../shared/SingleLinkToReferenceField";
import CustomerEditActions from "./CustomerEditActions";

const CustomerEdit = (props: EditProps) => {
    const { pathname } = useLocation();
    return (
        <>
            <Edit
                title="Edit a Customer"
                {...props}
                actions={<CustomerEditActions currentUrl={pathname} />}
            >
                <FormWithRedirect isTabbed={true}>
                    <FormTab label="summary">
                        <TextInput source="name" validate={[required()]} />
                        <TextInput source="postcode" />
                        <DisabledHiddenOrganisationIdInput />
                        <BooleanInput
                            source="isDisabled"
                            defaultValue={false}
                        />

                        <TextInput
                            source="createdAt"
                            defaultValue={new Date().toISOString()}
                            disabled
                        />
                        <TextInput
                            source="updatedAt"
                            defaultValue={new Date().toISOString()}
                            disabled
                        />
                    </FormTab>
                    <FormTab label="credentials">
                        <ArrayField source="credentials">
                            <Datagrid>
                                <TextField source="id" />
                                <LinkToReferenceField
                                    label="Credential"
                                    source="login"
                                    reference="Credential"
                                />
                            </Datagrid>
                        </ArrayField>
                    </FormTab>
                    <FormTab label="memberships">
                        <ArrayField source="memberships">
                            <Datagrid>
                                <LinkToReferenceField
                                    label="memberships"
                                    source="name"
                                    reference="CustomerMembership"
                                />
                            </Datagrid>
                        </ArrayField>
                    </FormTab>
                    <FormTab label="benefits">
                        <ArrayField source="benefits">
                            <Datagrid>
                                <TextField source="id" />
                                <LinkToReferenceField
                                    label="benefits"
                                    source="name"
                                    reference="CustomerBenefit"
                                />
                            </Datagrid>
                        </ArrayField>
                    </FormTab>
                    <FormTab label="bookings">
                        <ArrayField source="bookings">
                            <ArrayField source="bookings">
                                <Datagrid>
                                    <TextField source="createdAt" />
                                    <TextField source="completedAt" />
                                    <TextField source="bookingStatus" />
                                    <LinkToReferenceField
                                        source="reference"
                                        reference="Booking"
                                    />
                                </Datagrid>
                            </ArrayField>
                        </ArrayField>
                    </FormTab>
                    <FormTab label="Coupons used">
                        <ArrayField source="usedCouponIds">
                            <Datagrid>
                                <SingleLinkToReferenceField
                                    label="Coupons used"
                                    source="couponIds"
                                    reference="Coupon"
                                />
                            </Datagrid>
                        </ArrayField>
                    </FormTab>
                    <FormTab label="customer notes">
                        <ArrayField source="customerNotes">
                            <Datagrid>
                                <TextField source="id" />
                                <LinkToReferenceField
                                    label="customer notes"
                                    source="content"
                                    reference="Notes"
                                />
                            </Datagrid>
                        </ArrayField>
                    </FormTab>
                    <FormTab label="admin notes">
                        <ArrayField source="midAdminNotes">
                            <Datagrid>
                                <TextField source="id" />
                                <LinkToReferenceField
                                    label="admin notes"
                                    source="content"
                                    reference="Notes"
                                />
                            </Datagrid>
                        </ArrayField>
                    </FormTab>
                </FormWithRedirect>
            </Edit>
            <DocumentationLink link="https://docs.google.com/document/d/1_dFChaJdvxCGWxuTJ316rWvMJsoaERsQ7JD-MTydg7Q/edit" />
        </>
    );
};

export default CustomerEdit;
