import React from "react";
import {
    ArrayField,
    ChipField,
    Datagrid,
    EditButton,
    List,
    ListProps,
    NumberField,
    SingleFieldList,
    TextField
} from "react-admin";
import DocumentationLink from "../shared/DocumentationLink";

const ResourceGroupList = (props: ListProps) => (
    <>
        <List filter={{ isDeleted_not: true }} {...props}>
            <Datagrid>
                <TextField source="name" />
                <TextField source="stub" label="/group/stub" />
                <NumberField source="ordering" label="Ordering" />
                <ArrayField source="resources" label="Resources">
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
                <EditButton />
            </Datagrid>
        </List>
        <DocumentationLink link="https://docs.google.com/document/d/1iuR_r565tcaKRWn6L7-JNBrCuq7JC1X02j8ABsAYBeo/edit" />
    </>
);

export default ResourceGroupList;
