import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import timelineUtil from "../../../utils/timelineUtil";
import { IWeekTemplateWithTimeLine } from "../interfaces";
import { WEEK_TEMPLATE_QUERY } from "./queries";

type IUseLoadTemplate = (
    weekTemplateId: string,
    weekTemplate: IWeekTemplateWithTimeLine
) => IWeekTemplateWithTimeLine;

const useLoadTemplate: IUseLoadTemplate = (weekTemplateId, weekTemplate) => {
    const { data, error, loading } = useQuery(WEEK_TEMPLATE_QUERY, {
        variables: { weekTemplateId },
        skip: !!(
            !weekTemplateId ||
            (weekTemplate &&
                weekTemplate.dayScheduleTemplates &&
                weekTemplate.resource)
        )
    });

    if (data && data.weekTemplate) {
        const weekTemplate: IWeekTemplateWithTimeLine = data.weekTemplate;
        const { resource } = weekTemplate;

        const times = timelineUtil.computeTimeline(
            resource.dailyStartTime,
            resource.dailyEndTime,
            resource.greatestCommonDivisorOfActivitiesDuration
        );

        return { ...weekTemplate, timeLineTimes: times };
    }

    if (error) {
        toast(`An error has occurred ${error.message}`);
    }

    return null;
};

export default useLoadTemplate;
