import React from "react";
import {
    Datagrid,
    DateField,
    EditButton,
    List,
    ListProps,
    TextField
} from "react-admin";
import LinkToReferenceField from "../shared/LinkToReferenceField";

const CustomerBenefitList = (props: ListProps) => (
    <List {...props}>
        <Datagrid>
            <TextField source="name" />
            <DateField source="expiresAt" />
            <LinkToReferenceField
                label="Discount"
                source="discount.name"
                sourceId="discount.id"
                reference="Discount"
            />
            <LinkToReferenceField
                label="Customer"
                source="customer.name"
                sourceId="customer.id"
                reference="Customer"
            />
            <EditButton />
        </Datagrid>
    </List>
);

export default CustomerBenefitList;
