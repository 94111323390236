import { Typography } from "@material-ui/core";
import React from "react";
import {
    BooleanInput,
    DateInput,
    Edit,
    EditProps,
    ReferenceInput,
    required,
    SelectInput,
    TextInput
} from "react-admin";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import FormWithRedirect from "../shared/FormWithRedirect";

const CouponEdit = (props: EditProps) => {
    return (
        <>
            <Edit title="Edit a Coupon" {...props}>
                <FormWithRedirect isTabbed={false}>
                    <TextInput source="name" />
                    <DisabledHiddenOrganisationIdInput />
                    <DateInput source="validFrom" />
                    <DateInput source="validTo" />
                    <ReferenceInput
                        label="Discount"
                        source="discount.id"
                        reference="Discount"
                        sort={{ field: "name", order: "ASC" }}
                        perPage={200}
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <TextInput source="couponCode" />
                    <BooleanInput source="isSingleUse" />
                    <BooleanInput
                        source="isSingleUsePerCustomer"
                        defaultValue={false}
                        validate={[required()]}
                    />
                    <BooleanInput source="isActive" />
                </FormWithRedirect>
            </Edit>
            <Typography
                style={{ paddingTop: "30px" }}
                variant="body1"
                gutterBottom
                align="left"
            >
                Docs{" "}
                <a
                    target="blank"
                    href="https://docs.google.com/document/d/1CncNaeeD4xHz443CGeZcJCYHdVs0cEfaPjBiLKGogyk/edit"
                >
                    Link
                </a>
            </Typography>
        </>
    );
};

export default CouponEdit;
