import { gql } from "@apollo/client";

export const customerMembershipCommonFields = `id
    name
    membership {
        id
        name
    }
    customer {
        id
        name
    }
    hasBeenActivated
    amountPaid
    activatedAt
    isDisabled
    disabledAt
    disabledByUser{
        id
        name
    }
    completedAt
    expiresAt
    photoUploadedAt
    photoUpdatedByUser {
        id
        name
    }
    photoUpdatedByUserAt
    isActivationEmailSent
    activationEmailSentAt
    renewalAvailableAt
    renewalReminder1SendOn
    hasRenewalReminder1BeenSent
    renewalReminder2SendOn
    hasRenewalReminder2BeenSent
    canPhotoBeChanged
    photoLastChangedAt
    customerNotes {
        id
        content
    }
    adminNotes {
        id
        content
    }
    midAdminNotes {
        id
        content
        createdByUser {
            id
            name
        }
    }
    renewals {
        id
        completedAt
    }
    bookings {
        id
        reference
    }
    createdByUser {
        id
        name
    }
    transactions {
        id
        authCode
        providerIdentifier
    }
    createdAt
    updatedAt
`;

const customerMembershipFragment = gql`
    fragment customerMembershipFragment on CustomerMembership {
        ${customerMembershipCommonFields}
    }
`;

export default customerMembershipFragment;
