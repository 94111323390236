import React from "react";
import {
    BooleanInput,
    Create,
    CreateProps,
    NumberInput,
    ReferenceArrayInput,
    required,
    SelectArrayInput,
    SelectInput,
    TextInput
} from "react-admin";
import daysOfTheWeek from "../../daysOfTheWeek";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import DocumentationLink from "../shared/DocumentationLink";
import FormWithRedirect from "../shared/FormWithRedirect";
import OptionTextRenderer from "../shared/OptionTextRendered";

const DiscountCreate = (props: CreateProps) => {
    return (
        <>
            <Create title="Create Discount" {...props}>
                <FormWithRedirect>
                    <TextInput
                        source="name"
                        fullWidth
                        validate={[required()]}
                        helperText="The name of this discount. It may be displayed publically"
                    />
                    <TextInput
                        source="description"
                        multiline
                        fullWidth
                        validate={[required()]}
                        helperText="A description, which will not be displayed publically"
                    />
                    <BooleanInput
                        source="isActive"
                        defaultValue={true}
                        helperText="Whether this will be applied"
                    />
                    <NumberInput
                        source="discountAmount"
                        validate={[required()]}
                        helperText="The numerical amount of the discount"
                    />
                    <SelectInput
                        source="discountType"
                        choices={[
                            { id: "MONEY", name: "MONEY" },
                            { id: "PERCENTAGE", name: "PERCENTAGE" }
                        ]}
                        validate={[required()]}
                        helperText="Money (£) or percentage (%)"
                    />
                    <DisabledHiddenOrganisationIdInput />
                    <SelectArrayInput
                        label="Valid days? Blank for all"
                        source="validDaysOfWeek"
                        choices={daysOfTheWeek}
                        optionText="label"
                        optionValue="id"
                    />

                    <ReferenceArrayInput
                        label="Valid activities? Blank for all"
                        source="validActivityIds"
                        reference="Activity"
                        sort={{ field: "name", order: "ASC" }}
                        perPage={200}
                    >
                        <SelectArrayInput
                            optionText={
                                <OptionTextRenderer
                                    paths={["name", "resource.name"]}
                                />
                            }
                        />
                    </ReferenceArrayInput>

                    <NumberInput
                        source="validForMaxQuantity"
                        label="Maximum number of tickets"
                        helperText="Maximum number of tickets the discount will apply to. If empty it will apply to many tickets"
                    />

                    <BooleanInput
                        label="Applies to activities?"
                        source="appliesToActivities"
                        defaultValue={true}
                    />

                    <BooleanInput
                        label="Applies to extras?"
                        source="appliesToExtras"
                        defaultValue={false}
                    />
                </FormWithRedirect>
            </Create>
            <DocumentationLink link="https://docs.google.com/document/d/14Sa8rJ_eNt39x0g3KOTrt_VvmtVsGAzAjEN6fUBwjOE/edit" />
        </>
    );
};

export default DiscountCreate;
