import InputAdornment from "@material-ui/core/InputAdornment";
import React from "react";
import {
    Create,
    CreateProps,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    TextInput
} from "react-admin";
import FormWithRedirect from "../shared/FormWithRedirect";

const CustomerMembershipCreate = (props: CreateProps) => (
    <Create title="Create Customer Membership" {...props}>
        <FormWithRedirect>
            <TextInput
                source="name"
                fullWidth
                validate={[required()]}
                helperText="The name of the Customer Membership"
            />
            <ReferenceInput
                label="Membership"
                source="membership.id"
                reference="Membership"
                validate={[required()]}
                fullWidth
                sort={{ field: "name", order: "ASC" }}
                perPage={200}
                helperText="Please select a Membership"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="Customer"
                source="customer.id"
                reference="Customer"
                validate={[required()]}
                sort={{ field: "name", order: "ASC" }}
                fullWidth
                helperText="Please select a Customer"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                    )
                }}
                source="amountPaid"
                validate={[required()]}
                defaultValue={0}
            />
        </FormWithRedirect>
    </Create>
);

export default CustomerMembershipCreate;
