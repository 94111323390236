import { useQuery } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import timelineUtil from "../../../utils/timelineUtil";
import { IWeekTemplateWithTimeLine } from "../interfaces";
import { EMPTY_WEEK_TEMPLATE_QUERY } from "./queries";

type IUseLoadDefaultWeekTemplate = (
    resourceId: string
) => IWeekTemplateWithTimeLine | null;

const useLoadDefaultWeekTemplate: IUseLoadDefaultWeekTemplate = (
    resourceId
) => {
    const [defaultWeekTemplate, setDefaultWeekTemplate] =
        useState<IWeekTemplateWithTimeLine | null>(null);

    const { data, error, loading } = useQuery(EMPTY_WEEK_TEMPLATE_QUERY, {
        variables: { resourceId },
        skip: !!(!resourceId || defaultWeekTemplate)
    });

    if (!defaultWeekTemplate && data && data.resourceEmptyWeekTemplateQuery) {
        const { resourceEmptyWeekTemplateQuery } = data;
        const { resource } = resourceEmptyWeekTemplateQuery;

        const times = timelineUtil.computeTimeline(
            resource.dailyStartTime,
            resource.dailyEndTime,
            resource.greatestCommonDivisorOfActivitiesDuration
        );

        setDefaultWeekTemplate({
            ...resourceEmptyWeekTemplateQuery,
            timeLineTimes: times
        });
    }

    if (error) {
        toast(`An error has occurred ${error.message}`);
    }

    return defaultWeekTemplate;
};

export default useLoadDefaultWeekTemplate;
