import { ExecutionResult } from "graphql";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import {
    IDateStartTimeEndTimeInput,
    IPackage
} from "../../../../generated/dataInterfaces";
import createPackageReducer from "./createPackageReducer";
import Action from "./types/Action";
import State from "./types/State";
import useCreatePackageActivityInstances from "./useCreatePackageActivityInstances";
import useGetActivity from "./useGetActivity";

const initialState: State = {
    activityStartTime: null,
    activityEndTime: null,
    activityGeneratedDates: []
};

interface IPackageContextProps {
    activityId: string;
    packageId: string;
    children: React.ReactNode;
}

export interface IPackageContext {
    state: State;
    dispatch: React.Dispatch<Action>;
    createPackageActivityInstances: () => Promise<
        ExecutionResult<{
            createPackageActivityInstances: IPackage;
        }>
    >;
}

export const PackageContext = createContext<IPackageContext | undefined>(
    undefined
);

export function PackageContextProvider({
    activityId,
    packageId,
    children
}: IPackageContextProps) {
    const activity = useGetActivity(activityId);
    const {
        createPackageActivityInstances
    } = useCreatePackageActivityInstances();

    const [state, dispatch] = useReducer<React.Reducer<State, Action>>(
        createPackageReducer,
        initialState
    );

    useEffect(
        function updateActivityEndTimeWithDefaultActivityLength() {
            if (
                activity &&
                activity.defaultSlotLengthMinutes &&
                state.activityStartTime
            ) {
                const activityEndTime = state.activityStartTime
                    .clone()
                    .add(activity.defaultSlotLengthMinutes, "minutes");

                dispatch({
                    type: "SET_ACTIVITY_END_TIME",
                    payload: activityEndTime
                });
            }
        },
        [activity, state.activityStartTime]
    );

    const contextValue: IPackageContext = {
        state,
        dispatch,
        createPackageActivityInstances: async () => {
            const response = await createPackageActivityInstances({
                variables: {
                    createPackageActivityInstancesInput: {
                        dateStartTimesEndTimes: state.activityGeneratedDates.map<IDateStartTimeEndTimeInput>(
                            (d) => {
                                return {
                                    dateStartTime: d.date,
                                    endTime: d.endTime.format("HH:mm")
                                };
                            }
                        ),
                        activityId
                    },
                    packageWhereUniqueInput: { id: packageId }
                }
            });
            const { data, errors } = response;
            if (
                data &&
                data.createPackageActivityInstances &&
                (!errors || errors.length === 0)
            ) {
                dispatch({
                    type: "RESET_ACTIVITY_GENERATED_DATES",
                    payload: null
                });
            }
            return response;
        }
    };

    return (
        <PackageContext.Provider value={contextValue}>
            {children}
        </PackageContext.Provider>
    );
}

export function usePackageContext() {
    const context = useContext(PackageContext);

    if (!context) {
        throw new Error(
            "usePackageContext must be used within a PackageContextProvider"
        );
    }
    return context;
}

export default PackageContext;
