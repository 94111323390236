import DateFilter from "./DateFilter";
import React from "react";
import useDashboard from "../useDashboard";
import { FilterNames } from "../useDashboard/interfaces/FilterNames";

const CompletedDateToFilter = () => {
    const { completedDateTo, setFilter } = useDashboard();

    return (
        <DateFilter
            label="Completed Date to"
            value={completedDateTo}
            onChangeHandler={(date) => {
                setFilter(date, FilterNames.completedDateToFilterName);
            }}
        />
    );
};

export default CompletedDateToFilter;
