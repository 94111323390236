import { useContext } from "react";
import DashboardContext from "./DashboardContext";
import useGetFiltersData from "./useGetFiltersData";
import useRequestReport from "./useRequestReport";
import useGetReportUpdateInfo from "./useGetReportUpdateInfo";
import useUpdateReport from "./useUpdateReport";

const useDashboard = () => {
    const dashboardContext = useContext(DashboardContext);

    useGetFiltersData(dashboardContext.populateFilters);
    useGetReportUpdateInfo(
        dashboardContext.setReportUpdateInfo,
        dashboardContext.shouldReloadUpdateInfo
    );

    const { requestReport } = useRequestReport(
        dashboardContext.setReportResult
    );

    const { populateReport } = useUpdateReport(
        dashboardContext.setReportUpdateInfo
    );

    if (!dashboardContext) {
        throw new Error(
            "DashboardContext does not have a value, it might not be inside a provider"
        );
    }

    return { ...dashboardContext, requestReport, populateReport };
};

export default useDashboard;
