import { compact, flatten } from "lodash";
import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import {
    IActivityInstanceCreateInput,
    IDaySchedule,
    IResource,
    ISaveWeekScheduleInput,
    IUpdateActivityInstanceCapacityAndPrice
} from "../../../../generated/dataInterfaces";
import computeNewActivityInstances from "./computeNewActivityInstances";
import { SAVE_WEEK_SCHEDULE_MUTATION } from "./queries";

const useSaveWeekSchedule = (
    resource: IResource,
    weekSchedule: IDaySchedule[],
    updatedActivityInstanceIds: string[],
    deletedActivityInstanceIds: string[],
    assignWeekSchedule: (weekSchedule: any) => void
) => {
    const [saveWeekSchedule] = useMutation(SAVE_WEEK_SCHEDULE_MUTATION);

    const saveWeekScheduleExtended = useCallback(async () => {
        if (!resource || !weekSchedule) {
            return;
        }

        const activityInstancesToCreate =
            computeCreatedActivityInstancesAsInput(weekSchedule);
        const updatedActivityInstanceInputs =
            computeUpdatedActivityInstanceInputs(
                updatedActivityInstanceIds,
                weekSchedule
            );

        const mondayDate = weekSchedule[0].date;

        const saveWeekScheduleInput: ISaveWeekScheduleInput = {
            dateOfWeek: mondayDate,
            resourceId: resource.id,
            activityInstancesToCreate,
            activityInstanceIdsToDelete: deletedActivityInstanceIds,
            updatedActivityInstanceInputs
        };

        const { data, errors } = await saveWeekSchedule({
            variables: { saveWeekScheduleInput }
        });

        if (errors && errors.length > 0) {
            toast(errors[0].message);
        }

        if (data && data["saveWeekSchedule"]) {
            assignWeekSchedule(data["saveWeekSchedule"]);
            toast("Week schedule saved");
        } else {
            toast(`An error occurred, the schedule wasn't saved.`);
        }
    }, [
        resource,
        weekSchedule,
        updatedActivityInstanceIds,
        deletedActivityInstanceIds
    ]);

    return saveWeekScheduleExtended;
};

const computeActivityInstancesToUpdate = (
    updatedActivityInstanceIds: string[],
    weekSchedule: IDaySchedule[]
) => {
    if (!weekSchedule || !updatedActivityInstanceIds) {
        return [];
    }
    const activityInstancesToUpdate = flatten(
        compact(
            weekSchedule.map((d) =>
                d.activityInstances.filter((a) =>
                    updatedActivityInstanceIds.includes(a.id)
                )
            )
        )
    );
    return activityInstancesToUpdate;
};

const computeUpdatedActivityInstanceInputs = (
    updatedActivityInstanceIds: string[],
    weekSchedule: IDaySchedule[]
) => {
    const activityInstancesToUpdate = computeActivityInstancesToUpdate(
        updatedActivityInstanceIds,
        weekSchedule
    );

    const updatedActivityInstanceInputs =
        activityInstancesToUpdate.map<IUpdateActivityInstanceCapacityAndPrice>(
            (a) => {
                return {
                    id: a.id,
                    maxCapacity: a.maxCapacity,
                    pricePerBooking: a.pricePerBooking,
                    quantityUnavailable: a.quantityUnavailable
                };
            }
        );

    return updatedActivityInstanceInputs;
};

const computeCreatedActivityInstancesAsInput = (
    weekSchedule: IDaySchedule[]
) => {
    const newActivityInstances = computeNewActivityInstances(weekSchedule);

    const newActivityInstanceInputs =
        newActivityInstances.map<IActivityInstanceCreateInput>((a) => {
            return {
                activity: { connect: { id: a.activity.id } },
                daySchedule: { connect: { id: a.daySchedule.id } },
                startTime: a.startTime,
                endTime: a.endTime,
                quantityUnavailable: a.quantityUnavailable,
                maxCapacity: a.maxCapacity,
                pricePerBooking: a.pricePerBooking,
                isFirstOfPackage: false
            };
        });

    return newActivityInstanceInputs;
};

export default useSaveWeekSchedule;
