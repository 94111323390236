import RichTextInput from "ra-input-rich-text";
import React from "react";
import {
    ArrayField,
    BooleanField,
    BooleanInput,
    Datagrid,
    Edit,
    EditProps,
    FormTab,
    NumberInput,
    regex,
    required,
    TabbedForm,
    TextField,
    TextInput
} from "react-admin";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import DocumentationLink from "../shared/DocumentationLink";
import LinkToReferenceField from "../shared/LinkToReferenceField";

const FacilityEdit = (props: EditProps) => (
    <>
        <Edit title="Edit Facility" {...props}>
            <TabbedForm redirect="edit">
                <FormTab label="summary">
                    <TextInput
                        source="name"
                        validate={[required()]}
                        fullWidth
                        helperText="The name for this Facility. It will not currently be displayed anywhere public"
                    />
                    <RichTextInput
                        source="description"
                        validate={[required()]}
                        helperText="Administrative description for this facility"
                    />
                    <DisabledHiddenOrganisationIdInput />
                    <TextInput
                        source="nominalCode"
                        fullWidth
                        validate={[required()]}
                        helperText="The nominal code that will be used for all resources/activities in this facility (unless overridden)"
                    />
                    <TextInput
                        source="stub"
                        fullWidth
                        validate={[
                            regex(
                                /^(\w+)(-?(\w+)?)+$/,
                                "Letters, numbers and dashes (-) only"
                            ),
                            required()
                        ]}
                        helperText="The last part of the URL for this facility. Should be in the form name-of-facility. Must be unique amongst facilities"
                    />
                    <NumberInput
                        source="ordering"
                        fullWidth
                        helperText="Order in which the facility will be displayed"
                    />
                    <BooleanInput
                        source="isAvailable"
                        validate={[required()]}
                        helperText="If not set, this resource will not be displayed anywhere (website or mid-admin)"
                    />
                    <BooleanInput
                        source="isHiddenFromPublic"
                        validate={[required()]}
                        helperText="If set, none of the resources or activities will be available on the website"
                    />
                    <BooleanInput
                        source="isShownOnDefaultView"
                        defaultValue={false}
                        helperText="If set, this will not be available on the default view"
                    />
                </FormTab>
                <FormTab label="resources">
                    <ArrayField source="resources">
                        <Datagrid>
                            <LinkToReferenceField
                                label="Name"
                                source="name"
                                reference="Resource"
                            />
                            <TextField source="ordering" />
                            <BooleanField
                                source="isHiddenFromPublic"
                                label="Hidden?"
                            />
                            <BooleanField
                                source="isAvailable"
                                label="Available?"
                            />
                            <BooleanField
                                source="isShownOnDefaultView"
                                label="Default view?"
                            />
                        </Datagrid>
                    </ArrayField>
                </FormTab>
            </TabbedForm>
        </Edit>
        <DocumentationLink link="https://docs.google.com/document/d/1LJovGOCPl_oUGPzH17hJiYpFbZgy01Z-g8mL5tSrKLU/edit" />
    </>
);

export default FacilityEdit;
