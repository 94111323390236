import { gql } from "@apollo/client";

const resourceGroupFragment = gql`
    fragment resourceGroupFragment on ResourceGroup {
        id
        name
        stub
        # organisation {
        #     id
        #     name
        # }
        resources(orderBy: name_ASC, where: { isDeleted_not: true }) {
            id
            name
        }
        createdAt
        updatedAt
        membershipGroup {
            id
            name
            stub
            ordering
            isAvailable
            isHiddenFromPublic
            isDeleted
        }
        ordering
        isDeleted
        deletedAt
        deletedByUser {
            id
            name
        }
    }
`;

export default resourceGroupFragment;
