import {
    FetchResult,
    MutationFunctionOptions,
    useMutation
} from "@apollo/client";
import { gql } from "@apollo/client";
import {
    IActivityInstance,
    IActivityInstanceWhereInput
} from "../../../../generated/dataInterfaces";

const DELETE_ACTIVITY_INSTANCES_MUTATION = gql`
    mutation deleteActivityInstances(
        $activityInstanceWhereInput: ActivityInstanceWhereInput!
    ) {
        deleteActivityInstances(where: $activityInstanceWhereInput) {
            id
        }
    }
`;

interface IUseDeleteActivityInstances {
    deleteActivityInstances: (
        options?:
            | MutationFunctionOptions<
                  { deleteActivityInstances: IActivityInstance[] },
                  { activityInstanceWhereInput: IActivityInstanceWhereInput }
              >
            | undefined
    ) => Promise<
        FetchResult<
            { deleteActivityInstances: IActivityInstance[] },
            Record<string, any>,
            Record<string, any>
        >
    >;
    loading: boolean;
}

const useDeleteActivityInstances = (): IUseDeleteActivityInstances => {
    const [deleteActivityInstances, { loading }] = useMutation<
        { deleteActivityInstances: IActivityInstance[] },
        {
            activityInstanceWhereInput: IActivityInstanceWhereInput;
        }
    >(DELETE_ACTIVITY_INSTANCES_MUTATION, {
        onCompleted: (data) => {
            console.log(data);
        },
        onError: (error) => {
            console.log(error);
        }
    });
    return { deleteActivityInstances, loading };
};

export default useDeleteActivityInstances;
