import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { Record, showNotification, useRefresh, useNotify } from "react-admin";
import useDeleteActivityInstances from "./CreateActivityInstancesWithRRule/PackageContext/useDeleteActivityInstances";

const DeleteAllActivityInstancesButton = ({ record }: { record?: Record }) => {
    const refreshView = useRefresh();
    const notify = useNotify();
    const { deleteActivityInstances } = useDeleteActivityInstances();

    return record &&
        record.activityInstances &&
        record.activityInstances.length > 0 ? (
        <Button
            aria-label="Delete"
            color="secondary"
            onClick={async () => {
                notify("Deleting all activities please wait...");

                try {
                    const { data, errors } = await deleteActivityInstances({
                        variables: {
                            activityInstanceWhereInput: {
                                id_in: record.activityInstances.map(
                                    (ai: { id: string }): string => ai.id
                                )
                            }
                        }
                    });

                    if (errors && errors.length > 0) {
                        notify(errors[0].message);
                    } else if (data) {
                        notify("Activity deleted");
                        refreshView();
                    }
                } catch (error) {
                    notify(error.message);
                }
            }}
        >
            Delete all
            <DeleteIcon />
        </Button>
    ) : null;
};

export default DeleteAllActivityInstancesButton;
