import React from "react";
import { Filter, FilterProps, TextInput } from "react-admin";

const BookingFilter = (props: Omit<FilterProps, "children">) => (
    <Filter {...props}>
        <TextInput
            style={{ width: "300px" }}
            label="Search (also Package ref)"
            source="searchableUpper_contains"
            alwaysOn
            resettable
            parse={(value: string) => value.toUpperCase()}
        />
    </Filter>
);

export default BookingFilter;
