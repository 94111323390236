import ListIcon from "@material-ui/icons/List";
import React from "react";
import {
    IActivity,
    IActivityInstance
} from "../../../../generated/dataInterfaces";
import timelineUtil from "../../utils/timelineUtil";
import UpdateForm from "../Form/UpdateForm";
import useModal from "../Modal/useModal";
import useWeekScheduler from "../useWeekScheduler";

const ActivityInstance = ({
    dayScheduleId,
    activityInstance,
    dailyStartTime,
    activity,
    timelinePrecision,
    dayScheduleDate
}: IActivityInstanceProps) => {
    const { toggleModal, setModalTitle, setModalBodyComponent } = useModal();
    const {
        resource,
        timeLineTimes,
        updateActivityInstance,
        deleteActivityInstance
    } = useWeekScheduler();

    const onActivityInstanceClickHandler = () => {
        setModalTitle(`Update Activity ${activity.name}`);
        setModalBodyComponent(() => (
            <UpdateForm
                activities={resource.activities}
                dayScheduleId={dayScheduleId}
                activityInstance={activityInstance}
                key={activityInstance.id}
                times={timeLineTimes}
                submitHandler={(updatedActivityInstanceInput) => {
                    updateActivityInstance(updatedActivityInstanceInput);
                    toggleModal();
                }}
                deleteHandler={(dayScheduleId, activityInstanceId) => {
                    deleteActivityInstance(dayScheduleId, activityInstanceId);
                    toggleModal();
                }}
            />
        ));
        toggleModal();
    };

    const css = timelineUtil.computeActivityTimelineStyles(
        dailyStartTime,
        activityInstance.startTime,
        activityInstance.endTime,
        activity.colorScheme,
        timelinePrecision
    );

    return (
        <li
            onClick={(event) => {
                event.stopPropagation();
                onActivityInstanceClickHandler();
            }}
            style={css}
            className="single-event"
            data-testid={`${dayScheduleDate.getDate()}-${
                dayScheduleDate.getMonth() + 1
            }-${dayScheduleDate.getFullYear()}-at-${activityInstance.startTime.replace(
                ":",
                ""
            )}`}
        >
            <a>
                <span className="event-date">
                    {activityInstance.package ? <ListIcon /> : null}{" "}
                    {activityInstance.startTime} - {activityInstance.endTime}{" "}
                </span>
                <em style={{ fontSize: "1em" }} className="event-name">
                    {activity.name}
                </em>
            </a>
        </li>
    );
};

interface IActivityInstanceProps {
    dayScheduleId: string;
    activityInstance: IActivityInstance;
    activity: IActivity;
    dailyStartTime: string;
    timelinePrecision: number;
    dayScheduleDate: Date;
}

export default ActivityInstance;
