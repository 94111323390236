import React from "react";

interface ICustomerMembershipViewPhotoProps {
    record?: {
        photo: {
            base64: string;
        };
    };
}

const CustomerMembershipViewPhoto = ({
    record
}: ICustomerMembershipViewPhotoProps) => {
    record?.photo;

    return (
        <div style={{ padding: "30px" }}>
            <p>
                Current photo, if you just uploaded a new one refresh the page.
            </p>
            {record?.photo && record?.photo?.base64 ? (
                <img src={record.photo.base64} />
            ) : null}
        </div>
    );
};

export default CustomerMembershipViewPhoto;
