import { get, set } from "lodash";
import { Record } from "react-admin";

const map = (
    sourceObject: Record,
    outputObject: Record,
    sourcePath: string,
    destinationPath: string
): Record => set(outputObject, destinationPath, get(sourceObject, sourcePath));

export const mapRecordToReferencedField = (
    record: Record,
    mappings: string[][]
): Record => {
    const mappedRecord = mappings.reduce(
        (accumulator: Record, currentValue: string[]) => {
            const [sourcePath, destinationPath] = currentValue;
            return map(record, accumulator, sourcePath, destinationPath);
        },
        {} as Record
    );
    return mappedRecord;
};

export default mapRecordToReferencedField;
