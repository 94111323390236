import { useQuery } from "@apollo/client";
import { WEEK_SCHEDULE_QUERY } from "./queries";
import { toast } from "react-toastify";

const useLoadWeekSchedule = (
    resourceId: string,
    dateOfWeek: Date,
    assignWeekSchedule: (weekSchedule: any) => void,
    shouldLoad: boolean
) => {
    const { data, error, loading } = useQuery(WEEK_SCHEDULE_QUERY, {
        variables: { resourceId, dateOfWeek },
        skip: !shouldLoad
    });

    if (
        data &&
        data.resourceWeekSchedule &&
        data.resourceWeekSchedule.length > 0
    ) {
        assignWeekSchedule(data.resourceWeekSchedule);
    } else if (
        data &&
        data.resourceWeekSchedule &&
        data.resourceWeekSchedule.length === 0
    ) {
        toast("No day schedule instances for these week. Contact support.");
    }

    if (error) {
        toast(
            `An error occurred when loading week schedule resourceId: ${resourceId} for date: ${dateOfWeek}.
            Error message: ${error.message}`
        );
    }
};

export default useLoadWeekSchedule;
