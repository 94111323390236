import {
    TextInput,
    NullableBooleanInput,
    Filter,
    ReferenceInput,
    SelectInput,
    FilterProps
} from "react-admin";
import React from "react";

type ActivityFilterProps = Omit<FilterProps, "children">;

const ActivityFilter = (props: ActivityFilterProps) => (
    <Filter {...props}>
        <TextInput
            label="Search by name"
            source="name_contains"
            alwaysOn
            resettable
        />
        <ReferenceInput
            label="Resource"
            source="resource.id"
            reference="Resource"
            sort={{ field: "name", order: "ASC" }}
            perPage={200}
            alwaysOn
            resettable
        >
            <SelectInput optionText="name" />
        </ReferenceInput>

        <NullableBooleanInput label="Packaged?" source="isPackaged" alwaysOn />

        <NullableBooleanInput
            label="Hidden?"
            source="isHiddenFromPublic"
            alwaysOn
        />

        <NullableBooleanInput
            label="Available?"
            source="isAvailable"
            alwaysOn
        />
    </Filter>
);

export default ActivityFilter;
