import {
    DialogContent,
    DialogContentText,
    MenuItem,
    Select
} from "@material-ui/core";
import React from "react";
import { IActivity } from "../../../../generated/dataInterfaces";

const SelectActivity = ({
    activities,
    isDisabled,
    value,
    onChangeHandler
}: ISelectActivityProps) => {
    return (
        <DialogContent>
            <DialogContentText>Activity:</DialogContentText>
            <Select
                disabled={isDisabled}
                onChange={(event) => onChangeHandler(event.target.value)}
                value={value}
                name="name"
                data-testid="select-activity-input"
            >
                {activities.map((activity) => {
                    return (
                        <MenuItem key={activity.id} value={activity.id}>
                            {`${activity.name} - ${activity.defaultSlotLengthMinutes} minutes`}
                        </MenuItem>
                    );
                })}
            </Select>
        </DialogContent>
    );
};

export interface ISelectActivityProps {
    activities: IActivity[];
    isDisabled: boolean;
    value: string;
    onChangeHandler: (event: any) => void;
}

export default SelectActivity;
