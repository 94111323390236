import React from "react";

import { Record } from "react-admin";
import { Link } from "react-router-dom";

interface ILinkFieldProps {
    pathname: string;
    linkText: string;
    prefilledRecord?: Record;
    shouldRedirect?: boolean;
    redirectUrl?: string;
}

const LinkField = ({
    pathname,
    linkText,
    prefilledRecord = {} as Record,
    shouldRedirect = false,
    redirectUrl = ""
}: ILinkFieldProps) => {
    return (
        <Link
            to={{
                pathname,
                state: {
                    record: prefilledRecord,
                    shouldRedirect,
                    redirectUrl
                }
            }}
        >
            {linkText}
        </Link>
    );
};
export default LinkField;
