import produce from "immer";
import React, { createContext, useReducer } from "react";
import { toast } from "react-toastify";
import { IResource } from "../../../../generated/dataInterfaces";
import timelineUtil, { ITimeInterval } from "../../utils/timelineUtil";
import checkCanDeleteActivityInstance from "./checkCanDeleteActivityInstance";
import {
    ActionType,
    IAction,
    INewActivityInstanceInput,
    IState,
    IWeekSchedulerContext
} from "./interfaces";
import mapToActivityInstance from "./mapToActivityInstance";
import computeNewActivityInstances from "./computeNewActivityInstances";

export const WeekSchedulerContext = createContext<
    IWeekSchedulerContext | undefined
>(undefined);

const weekSchedulerReducer: React.Reducer<IState, IAction> = (
    state,
    action
) => {
    switch (action.type) {
        case ActionType.AssignResource: {
            const nextState = produce(state, (draft) => {
                const resource: IResource = action.payload;
                const times = timelineUtil.computeTimeline(
                    resource.dailyStartTime,
                    resource.dailyEndTime,
                    resource.greatestCommonDivisorOfActivitiesDuration
                );
                draft.resource = resource;
                draft.timeLineTimes = times;
            });

            return nextState;
        }
        case ActionType.ReloadWeekSchedule: {
            const nextState = produce(state, (draft) => {
                draft.shouldReloadWeekSchedule = true;
            });
            return nextState;
        }
        case ActionType.AssignWeekSchedule: {
            const nextState = produce(state, (draft) => {
                draft.shouldReloadWeekSchedule = false;
                draft.weekSchedule = action.payload;
                draft.deletedActivityInstanceIds = [];
                draft.updatedActivityInstanceIds = [];
            });
            return nextState;
        }
        case ActionType.CreateActivityInstance: {
            const nextState = produce(state, (draft) => {
                const newActivityInstanceInput: INewActivityInstanceInput =
                    action.payload;
                const { weekSchedule } = draft;
                const { resource } = state;
                const daySchedule = weekSchedule.find(
                    (d) => d.id === newActivityInstanceInput.dayScheduleId
                );

                const currentBusyStartEndTimes = daySchedule.activityInstances.map<
                    ITimeInterval<string>
                >((a) => {
                    return {
                        startTime: a.startTime,
                        endTime: a.endTime
                    };
                });

                const canAddNewActivityInstance = timelineUtil.checkCanAllocateTimeIntervalInTimeline(
                    currentBusyStartEndTimes,
                    newActivityInstanceInput.startTime,
                    newActivityInstanceInput.endTime,
                    resource.dailyEndTime
                );

                if (!canAddNewActivityInstance) {
                    toast(
                        `You cannot add an activity on that day and time, it is overlapping`
                    );
                    return;
                }
                const newActivityInstance = mapToActivityInstance(
                    draft.resource.activities,
                    newActivityInstanceInput
                );
                daySchedule.activityInstances.push(newActivityInstance);
            });
            return nextState;
        }
        case ActionType.UpdateActivityInstance: {
            const nextState = produce(state, (draft) => {
                const {
                    activityInstanceId,
                    dayScheduleId,
                    maxCapacity,
                    quantityUnavailable,
                    pricePerBooking
                } = action.payload;

                const daySchedule = draft.weekSchedule.find(
                    (d) => d.id === dayScheduleId
                );

                const activityInstanceToUpdate = daySchedule.activityInstances.find(
                    (a) => a.id === activityInstanceId
                );

                activityInstanceToUpdate.maxCapacity = maxCapacity;
                activityInstanceToUpdate.pricePerBooking = pricePerBooking;
                activityInstanceToUpdate.quantityUnavailable = quantityUnavailable;
                if (activityInstanceToUpdate.id) {
                    draft.updatedActivityInstanceIds.push(
                        activityInstanceToUpdate.id
                    );
                }
            });
            return nextState;
        }
        case ActionType.DeleteActivityInstance: {
            const nextState = produce(state, (draft) => {
                const { dayScheduleId, activityInstanceId } = action.payload;
                const daySchedule = draft.weekSchedule.find(
                    (d) => d.id === dayScheduleId
                );
                const indexActivityInstanceToDelete = daySchedule.activityInstances.findIndex(
                    (a) => a.id === activityInstanceId
                );

                const activityInstanceToDelete =
                    daySchedule.activityInstances[
                        indexActivityInstanceToDelete
                    ];

                const canDeleteActivityInstance = checkCanDeleteActivityInstance(
                    activityInstanceToDelete
                );

                if (canDeleteActivityInstance) {
                    daySchedule.activityInstances.splice(
                        indexActivityInstanceToDelete,
                        1
                    );
                    if (activityInstanceToDelete.id) {
                        draft.deletedActivityInstanceIds.push(
                            activityInstanceToDelete.id
                        );
                    }
                } else {
                    toast(
                        `You cannot delete this ${activityInstanceToDelete.activity.name} ${activityInstanceToDelete.startTime} - ${activityInstanceToDelete.endTime}, it contains bookings`
                    );
                }
            });

            return nextState;
        }
        default: {
            throw new Error(`Unhandled type: ${action.type}`);
        }
    }
};

export const WeekSchedulerContextProvider = ({ children }) => {
    const initialState: IState = {
        weekSchedule: null,
        resource: null,
        timeLineTimes: null,
        shouldReloadWeekSchedule: false,
        updatedActivityInstanceIds: [],
        deletedActivityInstanceIds: []
    };

    const [state, dispatch] = useReducer<React.Reducer<IState, IAction>>(
        weekSchedulerReducer,
        initialState
    );

    const {
        weekSchedule,
        resource,
        timeLineTimes,
        shouldReloadWeekSchedule,
        updatedActivityInstanceIds,
        deletedActivityInstanceIds
    } = state;

    const assignWeekSchedule = (weekSchedule) => {
        dispatch({
            type: ActionType.AssignWeekSchedule,
            payload: weekSchedule
        });
    };

    const assignResource = (resource) => {
        dispatch({
            type: ActionType.AssignResource,
            payload: resource
        });
    };

    const reloadWeekSchedule = () => {
        dispatch({ type: ActionType.ReloadWeekSchedule });
    };

    const createActivityInstance = (newActivityInstance) => {
        dispatch({
            type: ActionType.CreateActivityInstance,
            payload: newActivityInstance
        });
    };

    const updateActivityInstance = (updateActivityInstanceInput) => {
        dispatch({
            type: ActionType.UpdateActivityInstance,
            payload: updateActivityInstanceInput
        });
    };

    const deleteActivityInstance = (dayScheduleId, activityInstanceId) => {
        dispatch({
            type: ActionType.DeleteActivityInstance,
            payload: { dayScheduleId, activityInstanceId }
        });
    };

    const checkAreThereUnsavedChanges = () => {
        const activityInstancesToCreate = computeNewActivityInstances(
            weekSchedule
        );

        return !!(
            activityInstancesToCreate.length > 0 ||
            deletedActivityInstanceIds.length > 0 ||
            updatedActivityInstanceIds.length > 0
        );
    };

    const weekSchedulerContext: IWeekSchedulerContext = {
        weekSchedule,
        resource,
        timeLineTimes,
        shouldReloadWeekSchedule,
        assignWeekSchedule,
        assignResource,
        reloadWeekSchedule,
        createActivityInstance,
        updateActivityInstance,
        deleteActivityInstance,
        updatedActivityInstanceIds,
        deletedActivityInstanceIds,
        checkAreThereUnsavedChanges
    };

    return (
        <WeekSchedulerContext.Provider value={weekSchedulerContext}>
            {children}
        </WeekSchedulerContext.Provider>
    );
};
