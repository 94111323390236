import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import moment from "moment";
import React, { useState } from "react";
import { useNotify, useRefresh } from "react-admin";
import RRRuleGenerator, { translations } from "rrrule-generator";
import rrule from "rrule";
import GeneratedDates from "./GeneratedDates";
import GlobalActivityStartEndTimeInput from "./GlobalActivityStartEndTimeInput";
import { usePackageContext } from "./PackageContext";

const DatesGenerator = () => {
    const {
        state,
        dispatch,
        createPackageActivityInstances
    } = usePackageContext();
    const notify = useNotify();
    const refreshView = useRefresh();
    const [rruleRule, setRruleRule] = useState<string | null>(null);

    const english = translations.english;
    english.start.label = "Start date";
    english.end.label = "End date";
    english.repeat.label = "Repeats";
    english.repeat.monthly.every = english.repeat.weekly.every = english.repeat.daily.every =
        "Every";
    english.repeat.monthly.on_day = "On which day of the month?";

    // needs implementing
    english.start.description = "The date of the first event in the series";
    english.repeat.description = "How often does this event repeat?";
    english.end.description = "The date of the last event in the series";

    return (
        <Grid container style={{ marginBottom: "20px" }}>
            <Grid item xs={12}>
                <GlobalActivityStartEndTimeInput label="Start and end time" />
            </Grid>
            <Grid item xs={6}>
                <RRRuleGenerator
                    onChange={(rrule: string): void => {
                        setRruleRule(rrule);
                    }}
                    config={{
                        repeat: ["Monthly", "Weekly", "Daily"],
                        yearly: "on",
                        monthly: "on",
                        hideStart: false,
                        end: ["On date"],
                        hideError: false
                    }}
                    tranlations={english}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const rule = rrule.fromString(rruleRule ?? "");
                                dispatch({
                                    type: "ADD_ACTIVITY_GENERATED_DATES",
                                    payload: rule
                                        .all()
                                        .map((date) => moment(date))
                                });
                            }}
                        >
                            Generate and add dates
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                dispatch({
                                    type: "RESET_ACTIVITY_GENERATED_DATES",
                                    payload: null
                                });
                            }}
                        >
                            Clear dates
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <GeneratedDates />

            {state.activityGeneratedDates &&
            state.activityGeneratedDates.length > 0 ? (
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            notify(
                                "Creating activity instances, please wait until the page reloads."
                            );

                            try {
                                const {
                                    data,
                                    errors
                                } = await createPackageActivityInstances();

                                if (errors && errors.length > 0) {
                                    notify(errors[0].message);
                                } else {
                                    notify("Activity Created");
                                    refreshView();
                                }
                            } catch (error) {
                                notify(error.message);
                            }
                        }}
                    >
                        Create Activity Instances
                    </Button>
                </Grid>
            ) : null}
        </Grid>
    );
};
export default DatesGenerator;
