import { gql } from "@apollo/client";

const customerFragment = gql`
    fragment customer on Customer {
        id
        organisation {
            id
            name
        }
        name
        isDisabled
        postcode
        createdAt
        updatedAt
        credentials {
            id
            login
            email
            firstName
            lastName
        }
        benefits {
            id
            name
            expiresAt
            discount {
                id
                name
                isActive
            }
        }
        bookings {
            id
            reference
            total
            createdAt
            completedAt
            bookingStatus
        }
        postcode
        addressInfo
        isSelfCateringCustomer
        selfCateringIdentifer
        relatedSelfCateringBookingIds
        searchableUpper
        usedCouponIds
        memberships {
            id
            name
        }
    }
`;

export default customerFragment;
