import React, { useContext } from "react";
import ActivityInstanceTemplate from "./ActivityInstanceTemplate";
import { IModalContext, ModalContext } from "./ModalContext";
import { IActivityInstanceTemplate } from "../../../generated/dataInterfaces";

const DayScheduleTemplate = ({
    dayOfWeek,
    dayOfWeekName,
    dailyStartTime,
    activityInstanceTemplates,
    timelinePrecision,
    timelineCount
}: IDayScheduleTemplateProps) => {
    const { toggleModalInAddingMode } = useContext(
        ModalContext
    ) as IModalContext;

    const height = 50 * timelineCount;

    return (
        <li
            onClick={() => {
                toggleModalInAddingMode(dayOfWeek, dayOfWeekName);
            }}
            key={dayOfWeek}
            className="events-group"
        >
            <div className="top-info" style={{ height: "49px" }}>
                <span>{dayOfWeekName}</span>
            </div>
            <ul style={{ height: `${height}px` }}>
                {activityInstanceTemplates
                    ? activityInstanceTemplates.map(
                          (activityInstanceTemplate, index) => (
                              <ActivityInstanceTemplate
                                  key={`${index}-${dailyStartTime}`}
                                  dayOfWeek={dayOfWeek}
                                  dayOfWeekName={dayOfWeekName}
                                  dailyStartTime={dailyStartTime}
                                  startTime={activityInstanceTemplate.startTime}
                                  endTime={activityInstanceTemplate.endTime}
                                  pricePerBooking={
                                      activityInstanceTemplate.pricePerBooking
                                  }
                                  activity={activityInstanceTemplate.activity}
                                  timelinePrecision={timelinePrecision}
                              />
                          )
                      )
                    : null}
            </ul>
        </li>
    );
};

interface IDayScheduleTemplateProps {
    dayOfWeek: number;
    dayOfWeekName: string;
    dailyStartTime: string;
    activityInstanceTemplates: IActivityInstanceTemplate[];
    timelinePrecision: number;
    timelineCount: number;
}

export default DayScheduleTemplate;
