import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import { IReportPopulationResponse } from "../../../../generated/dataInterfaces";

export const POPULATE_REPORT_MUTATION = gql`
    mutation populateReport($populateReportInput: PopulateReportInput!) {
        populateReport(input: $populateReportInput) {
            lastUpdatedDate
            numberOfCompletedBookings
            numberOfMemberships
            totalCompletedSales
            numberOfSeconds
        }
    }
`;

interface IPopulateReportMutationResult {
    populateReport: IReportPopulationResponse;
}

export const useUpdateReport = (
    setReportUpdateInfo: (reportUpdateData: IReportPopulationResponse) => void
) => {
    const [populateReportMutation] = useMutation<IPopulateReportMutationResult>(
        POPULATE_REPORT_MUTATION
    );

    const populateReport = async (shouldForce = false) => {
        const { data, errors } = await populateReportMutation({
            variables: {
                populateReportInput: { forceUpdateAll: shouldForce }
            }
        });

        if (data && data.populateReport) {
            setReportUpdateInfo(data.populateReport);
        }
    };

    return { populateReport };
};

export default useUpdateReport;
