import React from "react";
import { useParams } from "react-router-dom";
import WeekTemplate from "../WeekTemplate";
import { ModalContextProvider } from "../WeekTemplate/ModalContext";
import { WeekTemplateContextProvider } from "../WeekTemplate/WeekTemplateContext";

const CreateWeekTemplate = () => {
    const { resourceId } = useParams<{ resourceId: string }>();

    return (
        <WeekTemplateContextProvider>
            <ModalContextProvider>
                <WeekTemplate resourceId={resourceId} isUpdateMode={false} />
            </ModalContextProvider>
        </WeekTemplateContextProvider>
    );
};

export default CreateWeekTemplate;
