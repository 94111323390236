import React from "react";
import { TextInput } from "react-admin";

const DisabledHiddenOrganisationIdInput = () => {
    const organisationId = process.env.ORGANISATION_ID
        ? process.env.ORGANISATION_ID
        : "5cf593e43782f10007c47314";

    return (
        <TextInput
            style={{ display: "none" }}
            source="organisation.id"
            value={organisationId}
            defaultValue={organisationId}
            disabled
        />
    );
};

export default DisabledHiddenOrganisationIdInput;
