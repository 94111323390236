import React from "react";
import {
    BooleanInput,
    Create,
    CreateProps,
    NumberInput,
    ReferenceInput,
    regex,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import DocumentationLink from "../shared/DocumentationLink";

const ResourceCreate = (props: CreateProps) => (
    <>
        <Create title="Create Resource" {...props}>
            <SimpleForm redirect="edit">
                <TextInput
                    source="name"
                    fullWidth
                    validate={[required()]}
                    helperText="The publicly visible name for this Resource"
                />
                <TextInput source="description" validate={[required()]} />
                <ReferenceInput
                    label="Facility"
                    source="facility.id"
                    reference="Facility"
                    sort={{ field: "name", order: "ASC" }}
                    perPage={200}
                    fullWidth
                    helperText="The facility for this resource"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <NumberInput
                    source="defaultMaxCapacity"
                    validate={[required()]}
                    helperText="The default maximum capacity when adding a new Activity"
                />
                <TextInput
                    source="imageUrl"
                    validate={[required()]}
                    fullWidth
                    helperText="For now, images should be uploaded on https://www.lesormesjersey.co.uk/umbraco and the URL entered here"
                />
                <TextInput
                    source="stub"
                    fullWidth
                    validate={[
                        regex(
                            /^(\w+)(-?(\w+)?)+$/,
                            "Letters, numbers and dashes (-) only"
                        ),
                        required()
                    ]}
                    helperText="The last part of the URL for this resource. Should be in the form name-of-resource. Must be unique amongst resources"
                />
                <TextInput
                    source="overrideNominalCode"
                    helperText="If not set, the nominal code for the Facility will be used"
                />

                <TextInput
                    source="dailyStartTime"
                    validate={[
                        required(),
                        regex(
                            /([01]?[0-9]|2[0-3]):[0-5][0-9]/,
                            "Must be HH:mm format"
                        )
                    ]}
                    defaultValue="08:00"
                    helperText="Enter the earliest time an activity will start as HH:MM e.g. 07:00"
                />
                <TextInput
                    source="dailyEndTime"
                    validate={[
                        required(),
                        regex(
                            /([01]?[0-9]|2[0-3]):[0-5][0-9]/,
                            "Must be HH:mm format"
                        )
                    ]}
                    defaultValue="22:00"
                    helperText="Enter the latest time an activity will end as HH:MM e.g. 18:00"
                />
                <NumberInput
                    source="ordering"
                    defaultValue={0}
                    helperText="Order in which the resource is displayed"
                />

                <BooleanInput
                    source="isAvailable"
                    defaultValue={true}
                    validate={[required()]}
                    helperText="If not set, this resource will not be displayed anywhere (website or mid-admin)"
                />
                <BooleanInput
                    source="isHiddenFromPublic"
                    defaultValue={true}
                    validate={[required()]}
                    helperText="If set, this will not be available on the website"
                />
                <BooleanInput
                    source="isShownOnDefaultView"
                    defaultValue={false}
                    helperText="If set, this will not be available on the default view"
                />
            </SimpleForm>
        </Create>
        <DocumentationLink link="https://docs.google.com/document/d/1UgGuuH5T-lAsnEpjFA_QOKoZNmQgYJzpnjBsT8dk9_k/edit" />
    </>
);

export default ResourceCreate;
