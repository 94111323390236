import { gql } from "@apollo/client";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { IWeekTemplate } from "../../../generated/dataInterfaces";
import useWeekScheduler from "./useWeekScheduler";

const useWeekTemplates = (resourceId: string) => {
    const [weekTemplates, setWeekTemplates] = useState<IWeekTemplate[]>(null);
    const { data, error, loading } = useQuery(
        WEEK_TEMPLATES_BY_RESOURCE_QUERY,
        {
            variables: { resourceId },
            skip: !(
                (resourceId && !weekTemplates) ||
                (resourceId &&
                    weekTemplates &&
                    weekTemplates.length > 0 &&
                    weekTemplates[0].resource.id !== resourceId)
            )
        }
    );

    const { weekSchedule, createActivityInstance } = useWeekScheduler();

    if (data && data.weekTemplates) {
        setWeekTemplates(data.weekTemplates);
    }

    const applyWeekTemplate = (weekTemplateId: string) => {
        const weekTemplateToApply = weekTemplates.find(
            (w) => w.id === weekTemplateId
        );
        weekTemplateToApply.dayScheduleTemplates.forEach(
            (dayScheduleTemplate) => {
                const dayScheduleInstance =
                    weekSchedule[dayScheduleTemplate.dayOfWeek - 1];
                dayScheduleTemplate.activityInstanceTemplates.forEach(
                    (activityInstanceTemplate) => {
                        createActivityInstance({
                            activityId: activityInstanceTemplate.activity.id,
                            dayScheduleId: dayScheduleInstance.id,
                            startTime: activityInstanceTemplate.startTime,
                            endTime: activityInstanceTemplate.endTime,
                            maxCapacity: activityInstanceTemplate.maxCapacity,
                            pricePerBooking:
                                activityInstanceTemplate.pricePerBooking,
                            quantityUnavailable: 0
                        });
                    }
                );
            }
        );
    };

    return { weekTemplates, applyWeekTemplate };
};

export default useWeekTemplates;

const WEEK_TEMPLATES_BY_RESOURCE_QUERY = gql`
    query weekTemplatesByResource($resourceId: ID!) {
        weekTemplates(where: { resource: { id: $resourceId } }) {
            id
            name
            resource {
                id
            }
            dayScheduleTemplates {
                dayOfWeek
                activityInstanceTemplates {
                    activity {
                        id
                        name
                    }
                    startTime
                    endTime
                    maxCapacity
                    pricePerBooking
                }
            }
        }
    }
`;
