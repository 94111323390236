import { gql } from "@apollo/client";

const organisationFragment = gql`
    fragment organisation on Organisation {
        id
        name
        isEnabled
        logoUrl
        midAdminScheme
        adminScheme
        hosts
        createdAt
        updatedAt
        facilities(orderBy: name_ASC, where: { isDeleted_not: true }) {
            id
            name
        }
        users {
            id
            name
        }
        memberships(orderBy: name_ASC, where: { isDeleted_not: true }) {
            id
            name
        }
    }
`;

export default organisationFragment;
