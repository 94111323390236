import useDashboard from "../useDashboard";
import SelectInput from "../../shared/SelectInput";
import React from "react";

const ResourceFilter = () => {
    const { resourceFilter, setFilter } = useDashboard();

    return resourceFilter ? (
        <SelectInput
            label="Resource"
            options={resourceFilter.options}
            isDisabled={false}
            onChangeHandler={(e) => {
                setFilter(e, resourceFilter.filterName);
            }}
            selectedValue={resourceFilter.selectedValue}
        />
    ) : null;
};

export default ResourceFilter;
