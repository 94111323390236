import Button from "@material-ui/core/Button";
import { Omit } from "@material-ui/types";
import React from "react";
import { CardActions } from "react-admin";
import { Link, LinkProps } from "react-router-dom";

const createLinkRef = (to: string) => {
    // eslint-disable-next-line react/display-name
    return React.forwardRef<any, Omit<LinkProps, "to">>((props, ref) => {
        return <Link ref={ref} to={to} {...props} />;
    });
};

const ResourceEditActions = ({ data }: IEditActionsProps) => {
    const CreateNewWeekTemplateLink = createLinkRef(
        `/c/resource/${data?.id}/create-week-template`
    );
    const WeekSchedulerLink = createLinkRef(
        `/c/resource/${data?.id}/week-scheduler`
    );
    CreateNewWeekTemplateLink.displayName = "";
    return data ? (
        <CardActions>
            <Button
                variant="contained"
                color="default"
                component={CreateNewWeekTemplateLink}
                data-testid="create-new-template-button"
            >
                Create new Week Template
            </Button>
            <Button
                variant="contained"
                color="default"
                component={WeekSchedulerLink}
                data-testid="week-scheduler-button"
            >
                Week Scheduler
            </Button>
        </CardActions>
    ) : null;
};

interface IEditActionsProps {
    data?: { id: string };
    otherProps?: any;
}

export default ResourceEditActions;
