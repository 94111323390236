import React from "react";
import {
    BooleanField,
    Datagrid,
    EditButton,
    List,
    NumberField,
    TextField,
    Pagination,
    ListProps,
    PaginationProps
} from "react-admin";
import DocumentationLink from "../shared/DocumentationLink";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import ActivityFilter from "./ActivityFilter";

const ActivityList = (props: ListProps) => {
    const PostPagination = (props: PaginationProps) => (
        <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
    );
    return (
        <>
            <List
                filters={<ActivityFilter />}
                filter={{ isDeleted_not: true }}
                perPage={25}
                pagination={<PostPagination />}
                {...props}
            >
                <Datagrid>
                    <LinkToReferenceField
                        source="name"
                        sourceId="id"
                        reference="Activity"
                        label="Name"
                    />
                    <LinkToReferenceField
                        source="resource.name"
                        sourceId="resource.id"
                        reference="Resource"
                        label="Resource"
                    />
                    <TextField source="stub" />
                    <NumberField
                        source="defaultSlotLengthMinutes"
                        label="Default slot length"
                    />
                    <NumberField
                        source="defaultPricePerBooking"
                        label="Default price"
                    />
                    <NumberField source="quantityPerBooking" />

                    <NumberField source="ordering" />
                    <BooleanField source="isPackaged" label="Packaged?" />
                    <BooleanField source="isHiddenFromPublic" label="Hidden?" />
                    <BooleanField source="isAvailable" label="Available?" />
                    <EditButton />
                </Datagrid>
            </List>
            <DocumentationLink link="https://docs.google.com/document/d/1Kb-d-IIp9N57QOmYp9v1KzYQ05Gbmd88_7BZtE3KCzs/edit" />
        </>
    );
};

export default ActivityList;
