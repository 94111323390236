import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

const Modal = ({ isOpen, closeModal, title, BodyComponent }: IModalProps) => {
    return (
        <Dialog open={isOpen} onClose={closeModal}>
            <DialogTitle>{title}</DialogTitle>
            <BodyComponent />
        </Dialog>
    );
};

interface IModalProps {
    isOpen: boolean;
    closeModal: () => void;
    title: string;
    BodyComponent: React.FC;
}

export default Modal;
