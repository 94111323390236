import React from "react";
import { SimpleForm, TabbedForm, TabbedFormProps } from "react-admin";
import { useLocation } from "react-router-dom";

interface IFormWithRedirectProps extends TabbedFormProps {
    children: React.ReactNode;
    isTabbed?: boolean;
}

interface ILocationRedirectState {
    state: {
        shouldRedirect: boolean;
        redirectUrl: string;
    };
}

const FormWithRedirect = ({
    children,
    isTabbed = false,
    ...otherProps
}: IFormWithRedirectProps) => {
    const location = useLocation();
    let shouldRedirect = false;
    let redirectUrl = "";

    if (location && location.state) {
        const { state } = location.state as ILocationRedirectState;
        shouldRedirect = state?.shouldRedirect;
        redirectUrl = state?.redirectUrl;
    }
    return isTabbed ? (
        <TabbedForm
            redirect={shouldRedirect ? redirectUrl : ""}
            {...otherProps}
            location={location}
        >
            {children}
        </TabbedForm>
    ) : (
        <SimpleForm
            redirect={shouldRedirect ? redirectUrl : ""}
            {...otherProps}
            location={location}
        >
            {children}
        </SimpleForm>
    );
};

export default FormWithRedirect;
