import React from "react";

const Loading = () => {
    const style: React.CSSProperties = {
        position: "fixed",
        zIndex: 1000,
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        background:
            "rgba( 255, 255, 255, .8 ) url('/assets/img/load.gif') 50% 50% no-repeat"
    };

    return <div style={style} />;
};
export default Loading;
