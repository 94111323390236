import { Typography } from "@material-ui/core";
import React from "react";
import DatesGenerator from "./DatesGenerator";
import { PackageContextProvider } from "./PackageContext";

interface ICreateActivityInstancesWithRRuleProps {
    activity: { id: string };
    packageId: string;
}

const CreateActivityInstancesWithRRule = ({
    activity,
    packageId
}: ICreateActivityInstancesWithRRuleProps) => {
    if (!activity || !packageId) {
        return null;
    }
    return (
        <PackageContextProvider activityId={activity.id} packageId={packageId}>
            <Typography variant="h4" gutterBottom align="left">
                Repeating instance generator
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
                You can use this to generate the activity instances.
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
                Once you&asop;ve generated a list, you will be able to alter the
                dates and times, before committing and creating the individual
                Activity Instances for this package.
            </Typography>
            <DatesGenerator />
        </PackageContextProvider>
    );
};

export default CreateActivityInstancesWithRRule;
