import React from "react";
import { Filter, FilterProps, TextInput } from "react-admin";

const CustomerMembershipFilter = (props: Omit<FilterProps, "children">) => (
    <Filter {...props}>
        <TextInput
            style={{ width: "300px" }}
            label="Search customer name"
            source="customer.name"
            alwaysOn
            resettable
        />
    </Filter>
);

export default CustomerMembershipFilter;
