import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { IPhotoData } from "../../../generated/dataInterfaces";
import { useState } from "react";

export const UPLOAD_CUSTOMER_MEMBERSHIP_PHOTO = gql`
    mutation uploadCustomerMembershipPhoto(
        $uploadPhotoInput: UploadPhotoInput!
    ) {
        uploadCustomerMembershipPhoto(data: $uploadPhotoInput) {
            base64
        }
    }
`;

interface IUploadCustomerMembershipMutationResult {
    uploadCustomerMembershipPhoto: IPhotoData;
}

interface IUploadCustomerMembershipPhoto {
    (
        customerId: string,
        customerMembershipId: string,
        base64DataImage: string
    ): Promise<string | null>;
}

export const useUploadCustomerMembershipPhoto = (): {
    uploadCustomerMembershipPhoto: IUploadCustomerMembershipPhoto;
} => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, setResult] = useState<string | null>(null);
    const [uploadCustomerMembershipPhotoMutation] =
        useMutation<IUploadCustomerMembershipMutationResult>(
            UPLOAD_CUSTOMER_MEMBERSHIP_PHOTO
        );

    const uploadCustomerMembershipPhoto: IUploadCustomerMembershipPhoto =
        async (
            customerId: string,
            customerMembershipId: string,
            base64DataImage: string
        ): Promise<string | null> => {
            const { data } = await uploadCustomerMembershipPhotoMutation({
                variables: {
                    uploadPhotoInput: {
                        customerId,
                        customerMembershipId,
                        base64DataImage
                    }
                }
            });

            if (
                data &&
                data.uploadCustomerMembershipPhoto &&
                data.uploadCustomerMembershipPhoto.base64
            ) {
                setResult(data.uploadCustomerMembershipPhoto.base64);
                return data.uploadCustomerMembershipPhoto.base64;
            }
            return null;
        };

    return { uploadCustomerMembershipPhoto };
};

export default useUploadCustomerMembershipPhoto;
