import { gql } from "@apollo/client";

const couponFragment = gql`
    fragment coupon on Coupon {
        id
        name
        organisation {
            id
            name
        }
        validFrom
        validTo
        discount {
            id
            name
            validActivityIds
            validDaysOfWeek
            isActive
            discountType
            discountAmount
        }
        couponCode
        isSingleUse
        isSingleUsePerCustomer
        isActive
        createdAt
        updatedAt
    }
`;

export default couponFragment;
