import React from "react";
import {
    BooleanInput,
    Create,
    CreateProps,
    ReferenceInput,
    required,
    SelectInput,
    TextInput
} from "react-admin";
import FormWithRedirect from "../shared/FormWithRedirect";

const CredentialCreate = (props: CreateProps) => {
    return (
        <Create title="Create Credential" {...props}>
            <FormWithRedirect>
                <ReferenceInput
                    label="Customer"
                    source="customer.id"
                    reference="Customer"
                    sort={{ field: "name", order: "ASC" }}
                    validate={[required()]}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <BooleanInput source="isPrimaryCredential" />
                <TextInput source="login" validate={[required()]} />
                <TextInput source="email" validate={[required()]} />
                <TextInput source="firstName" validate={[required()]} />
                <TextInput source="lastName" validate={[required()]} />
                <TextInput source="mobileNumber" validate={[required()]} />
                <TextInput source="password" type="password" />

                <BooleanInput source="doesReceiveSms" />
                <BooleanInput source="doesReceiveEmails" />
                <BooleanInput source="isLockedOut" />
                <BooleanInput source="isDisabled" />
                <TextInput source="lastLoginAt" disabled />
                <TextInput source="passwordResetRequestedAt" disabled />
                <TextInput source="searchableUpper" disabled />
            </FormWithRedirect>
        </Create>
    );
};

export default CredentialCreate;
