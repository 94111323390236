const daysOfTheWeek = [
    { id: 1, name: "1", label: "Monday" },
    { id: 2, name: "2", label: "Tuesday" },
    { id: 3, name: "3", label: "Wednesday" },
    { id: 4, name: "4", label: "Thursday" },
    { id: 5, name: "5", label: "Friday" },
    { id: 6, name: "6", label: "Saturday" },
    { id: 7, name: "7", label: "Sunday" }
];

export default daysOfTheWeek;
