import { TimePicker } from "@material-ui/pickers";
import moment from "moment";
import React from "react";
import { usePackageContext } from "./PackageContext";

moment.locale("en");

interface IGlobalActivityStartEndTimeInputProps {
    label?: string;
}

const GlobalActivityStartEndTimeInput = ({
    label
}: IGlobalActivityStartEndTimeInputProps) => {
    const { state, dispatch } = usePackageContext();
    const { activityStartTime, activityEndTime } = state;

    return (
        <div className="form-group row" style={{ marginTop: "20px" }}>
            {label ? (
                <div>
                    <label className="col-form-label">{label}</label>
                </div>
            ) : null}
            <TimePicker
                data-testid="start-time-input"
                label="Activity start time"
                onChange={(date: moment.Moment | null) => {
                    if (date) {
                        dispatch({
                            type: "SET_ACTIVITY_START_TIME",
                            payload: date
                        });
                    }
                }}
                ampm={false}
                format="HH:mm"
                value={activityStartTime}
            />
            <TimePicker
                label="Activity end time"
                onChange={(date: moment.Moment | null) => {
                    if (date) {
                        dispatch({
                            type: "SET_ACTIVITY_END_TIME",
                            payload: date
                        });
                    }
                }}
                ampm={false}
                format="HH:mm"
                value={activityEndTime}
            />
        </div>
    );
};
export default GlobalActivityStartEndTimeInput;
