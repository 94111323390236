import { gql } from "@apollo/client";

const resourceFragment = gql`
    fragment resource on Resource {
        id
        name
        description
        defaultMaxCapacity
        imageUrl
        overrideNominalCode
        isAvailable
        isHiddenFromPublic
        isShownOnDefaultView
        ordering
        dailyStartTime
        dailyEndTime
        stub
        facility {
            id
            name
        }
        activities(orderBy: ordering_ASC, where: { isDeleted_not: true }) {
            id
            name
            ordering
            isAvailable
            isHiddenFromPublic
        }
        extras {
            id
            name
        }
        weekTemplates {
            id
            name
        }
        memberships(orderBy: name_ASC, where: { isDeleted_not: true }) {
            id
            name
            isAvailable
            price
        }
        resourceGroups(orderBy: name_ASC, where: { isDeleted_not: true }) {
            id
            name
        }
        isDeleted
        deletedAt
        deletedByUser {
            id
            name
        }
        preCheckInInstruction
        postCheckInInstruction
    }
`;

export default resourceFragment;
