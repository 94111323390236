import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import SelectInput from "../shared/SelectInput";
import useWeekTemplates from "./useWeekTemplates";

const WeekTemplatePicker = ({ resourceId }) => {
    const { weekTemplates, applyWeekTemplate } = useWeekTemplates(resourceId);
    const [selectedWeekTemplateId, setSelectedWeekTemplateId] = useState("");

    if (!weekTemplates) return null;

    return (
        <div style={{ padding: "30px" }}>
            <Grid container direction="row" alignItems="flex-end">
                <Grid item xs={6}>
                    <SelectInput
                        label="Select week template"
                        options={weekTemplates.map((w) => {
                            return { label: w.name, value: w.id };
                        })}
                        isDisabled={false}
                        selectedValue={selectedWeekTemplateId}
                        onChangeHandler={setSelectedWeekTemplateId}
                        data-testid="week-template-select-input"
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            applyWeekTemplate(selectedWeekTemplateId);
                        }}
                        data-testid="apply-template-button"
                    >
                        Apply template
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default WeekTemplatePicker;
