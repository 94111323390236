import React from "react";
import {
    BooleanInput,
    Edit,
    EditProps,
    email,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import DocumentationLink from "../shared/DocumentationLink";

const UserEdit = (props: EditProps) => (
    <>
        <Edit title="Edit User" {...props}>
            <SimpleForm redirect="edit">
                <TextInput source="name" validate={[required()]} />
                <TextInput
                    source="emailAddress"
                    validate={[required(), email()]}
                />
                <TextInput
                    label="New password"
                    source="newPassword"
                    type="password"
                />
                <BooleanInput source="isDisabled" />
                <BooleanInput source="isLockedOut" />
                <DisabledHiddenOrganisationIdInput />
                <ReferenceInput
                    label="Role"
                    source="role.id"
                    reference="Role"
                    sort={{ field: "name", order: "ASC" }}
                    perPage={200}
                    validate={[required()]}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
        <DocumentationLink link="https://docs.google.com/document/d/19EytUDi9vjY7nTtQRMlBGFgfc3RaQPFYIvuiUh05Zf0/edit" />
    </>
);

export default UserEdit;
