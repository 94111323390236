import {
    Edit,
    Datagrid,
    TextField,
    TextInput,
    BooleanInput,
    ArrayField,
    TabbedForm,
    FormTab,
    required,
    EditProps
} from "react-admin";
import React from "react";

import LinkToReferenceField from "../shared/LinkToReferenceField";

const OrganisationEdit = (props: EditProps) => (
    <Edit title="Edit an Organisation" {...props}>
        <TabbedForm redirect="edit">
            <FormTab label="summary">
                <TextInput source="name" validate={[required()]} />
                <BooleanInput source="isEnabled" defaultValue={true} />
                <TextInput source="logoUrl" />
                <TextInput source="midAdminScheme" />
                <TextInput source="adminScheme" />
                <TextInput source="hosts" validate={[required()]} />
            </FormTab>
            <FormTab label="facilities">
                <ArrayField source="facilities">
                    <Datagrid>
                        <TextField source="id" />
                        <LinkToReferenceField
                            label="Link"
                            source="name"
                            sourceId="id"
                            reference="Facility"
                        />
                    </Datagrid>
                </ArrayField>
            </FormTab>
            <FormTab label="users">
                <ArrayField source="users">
                    <Datagrid>
                        <TextField source="id" />
                        <LinkToReferenceField
                            label="User"
                            source="name"
                            sourceId="id"
                            reference="User"
                        />
                    </Datagrid>
                </ArrayField>
            </FormTab>
            <FormTab label="memberships">
                <ArrayField source="memberships">
                    <Datagrid>
                        <TextField source="id" />
                        <LinkToReferenceField
                            label="Link"
                            source="name"
                            sourceId="id"
                            reference="Membership"
                        />
                    </Datagrid>
                </ArrayField>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default OrganisationEdit;
