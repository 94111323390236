import React from "react";
import { CardActions, useNotify, useRefresh } from "react-admin";
import Button from "@material-ui/core/Button";
import confirm from "../Custom/shared/confirmAlert/confirm";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const CANCEL_BOOKING_MUTATION = gql`
    mutation cancelBooking($bookingId: ID!) {
        cancelBooking(input: { bookingId: $bookingId }) {
            id
        }
    }
`;

const BookingEditActions = ({ data }: IEditActionsProps) => {
    const [cancelBooking] = useMutation(CANCEL_BOOKING_MUTATION);
    const refreshView = useRefresh();
    const notify = useNotify();

    return data && data.bookingStatus === "COMPLETE" && !data.checkedInAt ? (
        <CardActions>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    confirm("Do you want to cancel this booking?").then(
                        async () => {
                            const response = await cancelBooking({
                                variables: {
                                    bookingId: data.id
                                }
                            });
                            if (
                                response.data["cancelBooking"] &&
                                response.data["cancelBooking"].id
                            ) {
                                notify("The booking was cancelled");
                                refreshView();
                            } else {
                                notify(
                                    "The booking wasn't cancelled an error occurred."
                                );
                            }
                        }
                    );
                }}
            >
                Cancel booking
            </Button>
        </CardActions>
    ) : null;
};

interface IEditActionsProps {
    data?: { id: string; bookingStatus: string; checkedInAt: Date };
    otherProps?: any;
}

export default BookingEditActions;
