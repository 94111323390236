import useDashboard from "../useDashboard";
import SelectInput from "../../shared/SelectInput";
import React from "react";

const ReportTypeFilter = () => {
    const { reportTypeFilter, setFilter } = useDashboard();

    return reportTypeFilter ? (
        <SelectInput
            label="Report Type"
            options={reportTypeFilter.options}
            isDisabled={false}
            onChangeHandler={(e) => {
                setFilter(e, reportTypeFilter.filterName);
            }}
            selectedValue={reportTypeFilter.selectedValue}
        />
    ) : null;
};

export default ReportTypeFilter;
