import {
    TextInput,
    Filter,
    ReferenceInput,
    SelectInput,
    NullableBooleanInput,
    FilterProps
} from "react-admin";
import React from "react";

const ResourceFilter = (props: Omit<FilterProps, "children">) => (
    <Filter {...props}>
        <TextInput
            label="Search by name"
            source="name_contains"
            alwaysOn
            resettable
        />
        {/* Facility filter not working, query comes back with results, but the count is 0 */}
        <ReferenceInput
            label="Facility"
            source="facility.id"
            reference="Facility"
            sort={{ field: "name", order: "ASC" }}
            perPage={200}
            alwaysOn
            resettable
        >
            <SelectInput optionText="name" />
        </ReferenceInput>

        <NullableBooleanInput
            label="Hidden?"
            source="isHiddenFromPublic"
            alwaysOn
        />

        <NullableBooleanInput
            label="Available?"
            source="isAvailable"
            alwaysOn
        />
    </Filter>
);

export default ResourceFilter;
