import React from "react";
import WeekScheduler from "./WeekScheduler";
import { WeekSchedulerContextProvider } from "./useWeekScheduler/WeekSchedulerContext";
import SubMenuWeekSchedule from "./SubMenuWeekSchedule";

const WeekSchedulerWithContext = ({ match }) => {
    const { resourceId } = match.params;

    return (
        <>
            <WeekSchedulerContextProvider>
                <WeekScheduler resourceId={resourceId} />
            </WeekSchedulerContextProvider>
            <SubMenuWeekSchedule />
        </>
    );
};

export default WeekSchedulerWithContext;
