import React from "react";
import {
    BooleanField,
    Datagrid,
    EditButton,
    List,
    ListProps,
    NumberField
} from "react-admin";
import DocumentationLink from "../shared/DocumentationLink";
import LinkToReferenceField from "../shared/LinkToReferenceField";

const ExtraList = (props: ListProps) => (
    <>
        <List {...props}>
            <Datagrid>
                <LinkToReferenceField
                    source="name"
                    sourceId="id"
                    reference="Extra"
                    label="Name"
                />
                <LinkToReferenceField
                    source="resource.name"
                    sourceId="resource.id"
                    reference="Resource"
                    label="Resource"
                />
                <NumberField source="price" />
                <BooleanField source="isAvailable" />
                <EditButton />
            </Datagrid>
        </List>
        <DocumentationLink link="https://docs.google.com/document/d/1Z_h_CEgwqugWh9tv31jXFaGsXgNpkn6Wbi-pXt5Ca94/edit" />
    </>
);

export default ExtraList;
