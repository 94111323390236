import { get } from "lodash";
import React from "react";
import { Record } from "react-admin";

interface IOptionTextRendererProps {
    record?: Record;
    paths: string[];
}

const OptionTextRenderer = ({ record, paths }: IOptionTextRendererProps) => (
    <span>
        {paths.reduce((accumulator, currentPath, index) => {
            const value = get(record, currentPath);
            return accumulator.concat(
                `${index > 0 && value ? " - " : ""}${value ? value : ""}`
            );
        }, "")}
    </span>
);

export default OptionTextRenderer;
