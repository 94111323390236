import {
    DialogContent,
    DialogContentText,
    MenuItem,
    Select
} from "@material-ui/core";
import React from "react";

const SelectTime = ({
    timeLineTimes,
    isDisabled,
    value,
    onChangeHandler
}: ISelectTimeProps) => {
    return (
        <DialogContent>
            <DialogContentText>Time:</DialogContentText>
            <Select
                disabled={isDisabled}
                value={value}
                onChange={(event) => {
                    onChangeHandler(event.target.value);
                }}
                data-testid="select-time-input"
            >
                {timeLineTimes.map((time) => {
                    return (
                        <MenuItem key={time} value={time}>
                            {time}
                        </MenuItem>
                    );
                })}
            </Select>
        </DialogContent>
    );
};

export interface ISelectTimeProps {
    timeLineTimes: string[];
    isDisabled: boolean;
    value: string;
    onChangeHandler: (event: any) => void;
}

export default SelectTime;
