import { MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { Login } from "react-admin";
import Theme from "../Theme";
import RedirectToDashboard from "./RedirectToDashboard";

const RedirectAwayFromReactAdminLoginPage = () => {
    return (
        <MuiThemeProvider theme={Theme}>
            <Login />
            <RedirectToDashboard />
        </MuiThemeProvider>
    );
};

export default RedirectAwayFromReactAdminLoginPage;
