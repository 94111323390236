import { gql } from "@apollo/client";

const facilityFragment = gql`
    fragment facility on Facility {
        id
        name
        description
        organisation {
            id
            name
        }
        nominalCode
        stub
        isAvailable
        isHiddenFromPublic
        isShownOnDefaultView
        ordering
        resources(orderBy: ordering_ASC, where: { isDeleted_not: true }) {
            id
            name
            ordering
            isAvailable
            isHiddenFromPublic
            isShownOnDefaultView
        }
        isDeleted
        deletedAt
        deletedByUser {
            id
            name
        }
    }
`;

export default facilityFragment;
