import { gql } from "@apollo/client";

const activityInstanceTemplateFragment = gql`
    fragment activityInstanceTemplate on ActivityInstanceTemplate {
        id
        startTime
        endTime
        maxCapacity
        pricePerBooking
        activity {
            id
            name
            resource {
                id
                name
            }
        }
        dayScheduleTemplate {
            id
            dayOfWeek
        }
        createdAt
        updatedAt
    }
`;

export default activityInstanceTemplateFragment;
