import React from "react";
import {
    Edit,
    EditProps,
    FormTab,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    TabbedForm,
    TextInput
} from "react-admin";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import DocumentationLink from "../shared/DocumentationLink";

const ResourceGroupEdit = (props: EditProps) => (
    <>
        <Edit title="Edit Resource Group" {...props}>
            <TabbedForm redirect="edit">
                <FormTab label="summary">
                    <DisabledHiddenOrganisationIdInput />
                    <TextInput
                        source="name"
                        fullWidth
                        validate={[required()]}
                        helperText=""
                    />
                    <TextInput
                        source="stub"
                        fullWidth
                        validate={[required()]}
                        helperText="The stub to appear after /group/ e.g. jump-jersey"
                    />
                    <NumberInput
                        source="ordering"
                        helperText="The ordering of the Resource group, in listings"
                    />
                    <ReferenceArrayInput
                        label="Resources to display"
                        source="resourcesIds"
                        reference="Resource"
                        fullWidth
                        sort={{ field: "name", order: "ASC" }}
                        perPage={200}
                    >
                        <SelectArrayInput fullWidth optionText="name" />
                    </ReferenceArrayInput>
                    <ReferenceInput
                        label="Link to membership group?"
                        source="membershipGroup.id"
                        reference="MembershipGroup"
                        fullWidth
                        helperText="An optional membership group to link to, when the activities are accessed via this resource group"
                    >
                        <SelectInput fullWidth optionText="name" />
                    </ReferenceInput>
                </FormTab>
            </TabbedForm>
        </Edit>
        <DocumentationLink link="https://docs.google.com/document/d/1iuR_r565tcaKRWn6L7-JNBrCuq7JC1X02j8ABsAYBeo/edit" />
    </>
);

export default ResourceGroupEdit;
