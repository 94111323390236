import DateFilter from "./DateFilter";
import React from "react";
import useDashboard from "../useDashboard";
import { FilterNames } from "../useDashboard/interfaces/FilterNames";

const CompletedDateFromFiler = () => {
    const { completedDateFrom, setFilter } = useDashboard();

    return (
        <DateFilter
            label="Completed Date from"
            value={completedDateFrom}
            onChangeHandler={(date) => {
                setFilter(date, FilterNames.completedDateFromFilterName);
            }}
        />
    );
};

export default CompletedDateFromFiler;
