export enum FilterNames {
    facilityFilterName = "facilityFilter",
    resourceFilterName = "resourceFilter",
    activityFilterName = "activityFilter",
    membershipFilterName = "membershipFilter",
    completedDateFromFilterName = "completedDateFrom",
    completedDateToFilterName = "completedDateTo",
    activityDateFromFilterName = "activityDateFrom",
    activityDateToFilterName = "activityDateTo",
    startTimeFilterName = "startTimeFilter",
    endTimeFilterName = "endTimeFilter",
    hasBalanceFilterName = "hasBalanceFilter",
    hasDiscountFilterName = "hasDiscountFilter",
    isCheckedInFilterName = "isCheckedInFilter",
    extraFilterName = "extraFilter",
    couponFilterName = "couponFilter",
    membershipUsedFilterName = "membershipUsedFilter",
    isAdminBookingFilterName = "isAdminBookingFilter",
    reportTypeFilterName = "reportTypeFilter"
}
