import { Typography } from "@material-ui/core";
import React from "react";
import {
    BooleanInput,
    Create,
    CreateProps,
    DateInput,
    ReferenceInput,
    required,
    SelectInput,
    TextInput
} from "react-admin";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import FormWithRedirect from "../shared/FormWithRedirect";

const CouponCreate = (props: CreateProps) => {
    return (
        <>
            <Create title="Create Coupon" {...props}>
                <FormWithRedirect isTabbed={false}>
                    <TextInput source="name" validate={[required()]} />
                    <DisabledHiddenOrganisationIdInput />
                    <DateInput source="validFrom" />
                    <DateInput source="validTo" />
                    <ReferenceInput
                        label="Discount"
                        source="discount.id"
                        reference="Discount"
                        sort={{ field: "name", order: "ASC" }}
                        perPage={200}
                        validate={[required()]}
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <TextInput source="couponCode" validate={[required()]} />
                    <BooleanInput
                        source="isSingleUse"
                        defaultValue={false}
                        validate={[required()]}
                    />
                    <BooleanInput
                        source="isSingleUsePerCustomer"
                        defaultValue={false}
                        validate={[required()]}
                    />
                    <BooleanInput
                        source="isActive"
                        defaultValue={true}
                        validate={[required()]}
                    />
                </FormWithRedirect>
            </Create>
            <Typography
                style={{ paddingTop: "30px" }}
                variant="body1"
                gutterBottom
                align="left"
            >
                Docs{" "}
                <a
                    target="blank"
                    href="https://docs.google.com/document/d/1CncNaeeD4xHz443CGeZcJCYHdVs0cEfaPjBiLKGogyk/edit"
                >
                    Link
                </a>
            </Typography>
        </>
    );
};

export default CouponCreate;
