import { Grid, Typography, Paper } from "@material-ui/core";
import React from "react";
import useDashboard from "./useDashboard";

const ReportUpdateInfo = () => {
    const { reportUpdateInfo } = useDashboard();

    return reportUpdateInfo ? (
        <>
            <Typography variant="body1" gutterBottom>
                {reportUpdateInfo.lastUpdatedDate}
                <br />
            </Typography>

            <Typography variant="caption">
                The date/time that the data was last updated.
            </Typography>
        </>
    ) : null;
};

export default ReportUpdateInfo;
