import { INewActivityInstanceInput } from "./interfaces";
import { IActivityInstance } from "../../../../generated/dataInterfaces";

const checkCanDeleteActivityInstance = (
    activityInstance: IActivityInstance
) => {
    return (
        !activityInstance.bookings || // empty
        activityInstance.bookings.length === 0 || // none
        activityInstance.bookings?.filter(
            (booking) => booking.bookingStatus != "CANCELLED"
        ).length === 0 // all cancelled
    );
};

export default checkCanDeleteActivityInstance;
