import useDashboard from "../useDashboard";
import SelectInput from "../../shared/SelectInput";
import React from "react";

const CouponFiler = () => {
    const { couponFilter, setFilter } = useDashboard();

    return couponFilter ? (
        <SelectInput
            label="Coupon"
            options={couponFilter.options}
            isDisabled={false}
            onChangeHandler={(e) => {
                setFilter(e, couponFilter.filterName);
            }}
            selectedValue={couponFilter.selectedValue}
        />
    ) : null;
};

export default CouponFiler;
