import { gql } from "@apollo/client";

const customerBenefitFragment = gql`
    fragment customerBenefit on CustomerBenefit {
        id
        name
        description
        expiresAt
        customer {
            id
            name
        }
        discount {
            id
            name
        }
        createdAt
        updatedAt
    }
`;

export default customerBenefitFragment;
