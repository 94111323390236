import { gql } from "@apollo/client";

const userFragment = gql`
    fragment user on User {
        id
        name
        emailAddress
        createdAt
        updatedAt
        isDisabled
        isLockedOut
        lastLoginAt
        organisation {
            id
            name
        }
        role {
            id
            name
        }
    }
`;

export default userFragment;
