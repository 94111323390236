import { InputAdornment } from "@material-ui/core";
import React from "react";
import {
    ArrayInput,
    BooleanInput,
    Create,
    CreateProps,
    NumberInput,
    ReferenceArrayInput,
    regex,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput
} from "react-admin";
import daysOfTheWeek from "../../daysOfTheWeek";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import DocumentationLink from "../shared/DocumentationLink";

const MembershipCreate = (props: CreateProps) => (
    <>
        <Create title="Create Membership" {...props}>
            <SimpleForm redirect="edit">
                <TextInput
                    source="name"
                    fullWidth
                    validate={[required()]}
                    helperText="The publicly visible name for this Membership"
                />
                <TextInput source="description" />
                <DisabledHiddenOrganisationIdInput />
                <NumberInput
                    source="allowedBookingsPerDay"
                    validate={[required()]}
                    helperText="The number of booking allowed in the same day"
                />
                <BooleanInput
                    source="isLimitlessInBookingsPerDay"
                    defaultValue={false}
                    validate={[required()]}
                    helperText="If set, it allows for an unlimited number of bookings per day"
                />
                <TextInput
                    source="imageUrl"
                    fullWidth
                    helperText="For now, images should be uploaded on https://www.lesormesjersey.co.uk/umbraco and the URL entered here"
                />
                <NumberInput
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                        )
                    }}
                    source="price"
                    validate={[required()]}
                    helperText="The Membership price"
                />

                <ReferenceArrayInput
                    label="Valid resources? Blank for all"
                    source="resourcesIds"
                    reference="Resource"
                    sort={{ field: "name", order: "ASC" }}
                    perPage={200}
                >
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>

                <BooleanInput
                    source="doesRequirePhoto"
                    defaultValue={true}
                    validate={[required()]}
                    helperText="If not set, the membership will require a photo"
                />
                <BooleanInput
                    source="isAvailable"
                    defaultValue={true}
                    validate={[required()]}
                    helperText="If not set, this resource will not be displayed anywhere (website or mid-admin)"
                />
                <SelectInput
                    source="discountType"
                    choices={[
                        { id: "MONEY", name: "MONEY" },
                        { id: "PERCENTAGE", name: "PERCENTAGE" }
                    ]}
                    validate={[required()]}
                    helperText="Money (£) or percentage (%)"
                />
                <NumberInput
                    source="discountAmount"
                    validate={[required()]}
                    helperText="The amount of discount the membership provides"
                />

                <NumberInput
                    source="lengthInDays"
                    validate={[required()]}
                    helperText="The number of days the Membership is valid for"
                />
                <SelectArrayInput
                    label="Valid days? Blank for all"
                    source="validDaysOfWeek"
                    choices={daysOfTheWeek}
                    optionText="label"
                    optionValue="id"
                />
                <ArrayInput source="allowedTimesInDay">
                    <SimpleFormIterator>
                        <TextInput
                            source="startTime"
                            defaultValue="08:00"
                            validate={regex(
                                /([01]?[0-9]|2[0-3]):[0-5][0-9]/,
                                "Must be HH:mm format"
                            )}
                        />
                        <TextInput
                            source="endTime"
                            defaultValue="22:00"
                            validate={regex(
                                /([01]?[0-9]|2[0-3]):[0-5][0-9]/,
                                "Must be HH:mm format"
                            )}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
                <BooleanInput
                    source="isRenewable"
                    defaultValue={true}
                    validate={[required()]}
                    helperText="Tick to enable Membership renewal"
                />
                <NumberInput
                    label="Renewal days before exp"
                    source="renewalAvailableDaysBeforeExpiry"
                    helperText="How many days before the expiration date the membership can be renewed? Also a reminder will be sent"
                />
                <NumberInput
                    label="Renewal last reminder "
                    source="renewalReminderDaysBeforeExpiry"
                    helperText="How many days before the expiration date a last reminder will be sent?"
                />
                <BooleanInput
                    source="canBeCrossAuthorised"
                    defaultValue={false}
                    validate={[required()]}
                    helperText="Tick to enable cross authorisation (sharing)"
                />
            </SimpleForm>
        </Create>
        <DocumentationLink link="https://docs.google.com/document/d/1ROf-YO7t5G81iUA4hWXDjV4DQ9pOmp2OED9tKh7_xN8/edit" />
    </>
);

export default MembershipCreate;
