import { gql } from "@apollo/client";

const extraFragment = gql`
    fragment extra on Extra {
        id
        name
        resource {
            id
            name
        }
        description
        price
        isAvailable
    }
`;

export default extraFragment;
