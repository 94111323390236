import { gql } from "@apollo/client";

const activityFragment = gql`
    fragment activity on Activity {
        id
        name
        description
        keyInformation
        imageUrl
        isAvailable
        isHiddenFromPublic
        defaultPricePerBooking
        defaultSlotLengthMinutes
        quantityPerBooking
        minimumQuantityPerBooking
        bookMinDaysInAdvance
        bookMaxDaysInAdvance
        ticketDescription
        bookingConfirmEmailText
        checkoutCompleteText
        stub
        needHelpUrl
        colorScheme
        isPackaged
        mustPreventBookingAfterFirstInstanceIsPast
        ordering
        updatedAt
        resource {
            id
            name
            ordering
            resourceGroups(first: 1) {
                id
                name
            }
        }
        packages {
            id
            name
        }
        isDeleted
        deletedAt
        deletedByUser {
            id
            name
        }
        isBookingNameRequired
        bookingNameInfo
        isSelfCheckInEnabled
        howManyMinutesCheckInOpensBeforeActivityStarts
        howManyMinutesCheckInClosesAfterActivityStarts
    }
`;

export default activityFragment;
