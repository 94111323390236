import produce from "immer";
import State from "./types/State";
import Action from "./types/Action";
import moment from "moment";
import IDateAndEndTime from "./types/IDateAndEndTime";

const sortByDate = (date1: moment.Moment, date2: moment.Moment) => {
    if (moment(date1).isBefore(date2)) {
        return -1;
    }
    if (moment(date1).isAfter(date2)) {
        return 1;
    }
    return 0;
};

export default function createPackageReducer(
    state: State,
    action: Action
): State {
    switch (action.type) {
        case "SET_ACTIVITY_START_TIME": {
            const nextState = produce<State, State>(state, (draftState) => {
                const newActivityStartTime = moment(action.payload, "HH:mm");
                draftState.activityStartTime = newActivityStartTime;
            });
            return nextState;
        }
        case "SET_ACTIVITY_END_TIME": {
            const nextState = produce<State, State>(state, (draftState) => {
                draftState.activityEndTime = moment(action.payload, "HH:mm");
            });
            return nextState;
        }
        case "ADD_ACTIVITY_GENERATED_DATES": {
            const nextState = produce<State, State>(state, (draftState) => {
                const defaultHourStartTime = 9;
                const defaultMinutesStartTime = 0;
                const generatedDatesToAdd = action.payload.map<IDateAndEndTime>(
                    (date: moment.Moment) => {
                        return {
                            date: moment(date)
                                .hours(
                                    state.activityStartTime
                                        ? state.activityStartTime.hours()
                                        : defaultHourStartTime
                                )
                                .minutes(
                                    state.activityStartTime
                                        ? state.activityStartTime.minutes()
                                        : defaultMinutesStartTime
                                ),
                            endTime: state.activityEndTime
                                ? moment(date)
                                      .hours(state.activityEndTime.hours())
                                      .minutes(state.activityEndTime.minutes())
                                : moment(date)
                                      .hours(defaultHourStartTime)
                                      .minutes(defaultMinutesStartTime)
                                      .add(1, "hours")
                        };
                    }
                );
                draftState.activityGeneratedDates =
                    draftState.activityGeneratedDates
                        ? draftState.activityGeneratedDates
                              .concat(generatedDatesToAdd)
                              .sort((date1, date2) =>
                                  sortByDate(date1.date, date2.date)
                              )
                        : generatedDatesToAdd;
            });
            return nextState;
        }
        case "RESET_ACTIVITY_GENERATED_DATES": {
            const nextState = produce<State, State>(state, (draftState) => {
                draftState.activityGeneratedDates = [];
            });
            return nextState;
        }
        case "UPDATE_ONE_ACTIVITY_GENERATED_DATE": {
            const nextState = produce<State, State>(state, (draftState) => {
                const { index, newActivityDateAndEndTime } = action.payload;

                draftState.activityGeneratedDates[index] =
                    newActivityDateAndEndTime;
            });
            return nextState;
        }
        case "DELETE_ONE_ACTIVITY_GENERATED_DATE": {
            const nextState = produce<State, State>(state, (draftState) => {
                const { index } = action.payload;
                draftState.activityGeneratedDates.splice(index, 1);
            });
            return nextState;
        }
        case "ADD_ACTIVITY_DATE": {
            const nextState = produce<State, State>(state, (draftState) => {
                draftState.activityGeneratedDates.push(action.payload);
                draftState.activityGeneratedDates =
                    draftState.activityGeneratedDates
                        .map((d: IDateAndEndTime) => d)
                        .sort((date1, date2) =>
                            sortByDate(date1.date, date2.date)
                        );
            });
            return nextState;
        }
        default:
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return state;
    }
}
