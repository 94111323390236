import { InputAdornment } from "@material-ui/core";
import RichTextInput from "ra-input-rich-text";
import React from "react";
import {
    BooleanInput,
    Edit,
    FormTab,
    NumberInput,
    ReferenceArrayInput,
    required,
    SelectArrayInput,
    SelectInput,
    TabbedForm,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    ArrayField,
    Datagrid,
    EditProps
} from "react-admin";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import DocumentationLink from "../shared/DocumentationLink";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import daysOfTheWeek from "../../daysOfTheWeek";

const MembershipEdit = (props: EditProps) => (
    <>
        <Edit title="Edit a Membership" {...props}>
            <TabbedForm redirect="edit">
                <FormTab label="summary">
                    <TextInput
                        source="name"
                        fullWidth
                        validate={[required()]}
                        helperText="The publicly visible name for this Membership"
                    />
                    <RichTextInput source="description" />
                    <DisabledHiddenOrganisationIdInput />
                    <NumberInput
                        source="allowedBookingsPerDay"
                        validate={[required()]}
                        helperText="The number of booking allowed in the same day"
                    />
                    <BooleanInput
                        source="isLimitlessInBookingsPerDay"
                        defaultValue={false}
                        validate={[required()]}
                        helperText="If set, it allows for an unlimited number of bookings per day"
                    />
                    <TextInput
                        source="imageUrl"
                        fullWidth
                        helperText="For now, images should be uploaded on https://www.lesormesjersey.co.uk/umbraco and the URL entered here"
                    />
                    <NumberInput
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    £
                                </InputAdornment>
                            )
                        }}
                        source="price"
                        validate={[required()]}
                        helperText="The Membership price"
                    />
                    <ReferenceArrayInput
                        label="Valid resources? Blank for all"
                        source="resourcesIds"
                        reference="Resource"
                        sort={{ field: "name", order: "ASC" }}
                        perPage={200}
                    >
                        <SelectArrayInput optionText="name" />
                    </ReferenceArrayInput>
                    <BooleanInput
                        source="doesRequirePhoto"
                        defaultValue={true}
                        validate={[required()]}
                        helperText="If not set, the membership will require a photo"
                    />
                    <BooleanInput
                        source="isAvailable"
                        defaultValue={true}
                        validate={[required()]}
                        helperText="If not set, this resource will not be displayed anywhere (website or mid-admin)"
                    />
                    <SelectInput
                        source="discountType"
                        choices={[
                            { id: "MONEY", name: "MONEY" },
                            { id: "PERCENTAGE", name: "PERCENTAGE" }
                        ]}
                        validate={[required()]}
                        helperText="Money (£) or percentage (%)"
                    />
                    <NumberInput
                        source="discountAmount"
                        validate={[required()]}
                        helperText="The amount of discount the membership provides"
                    />
                    <NumberInput
                        source="lengthInDays"
                        validate={[required()]}
                        helperText="The number of days the Membership is valid for"
                    />
                    <SelectArrayInput
                        label="Valid days? Blank for all"
                        source="validDaysOfWeek"
                        choices={daysOfTheWeek}
                        optionText="label"
                        optionValue="id"
                    />
                    <ArrayInput source="allowedTimesInDay">
                        <SimpleFormIterator>
                            <TextInput source="startTime" />
                            <TextInput source="endTime" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <BooleanInput
                        source="isRenewable"
                        defaultValue={true}
                        validate={[required()]}
                        helperText="Tick to enable Membership renewal"
                    />
                    <NumberInput
                        label="Renewal days before exp"
                        source="renewalAvailableDaysBeforeExpiry"
                        helperText="How many days before the expiration date the membership can be renewed? Also a reminder will be sent"
                    />
                    <NumberInput
                        label="Renewal last reminder "
                        source="renewalReminderDaysBeforeExpiry"
                        helperText="How many days before the expiration date a last reminder will be sent?"
                    />
                    <BooleanInput
                        source="canBeCrossAuthorised"
                        defaultValue={false}
                        helperText="Tick to enable cross authorisation (sharing)"
                    />
                    <NumberInput
                        source="ordering"
                        helperText="The ordering of the Membership, in listings"
                    />
                    <BooleanInput
                        source="isHiddenFromPublic"
                        defaultValue={false}
                        validate={[required()]}
                        helperText="If not set, this membership group will not be displayed on the website"
                    />
                    <BooleanInput
                        source="isDeleted"
                        defaultValue={false}
                        validate={[required()]}
                        helperText="If set this membership group will be deleted"
                    />
                    <TextInput source="createdAt" disabled />
                    <TextInput source="updatedAt" disabled />
                </FormTab>
                <FormTab label="Customer Memberships">
                    <ArrayField source="customerMemberships">
                        <Datagrid>
                            <LinkToReferenceField
                                label="Membership"
                                source="name"
                                reference="CustomerMembership"
                            />
                            <LinkToReferenceField
                                label="Customer"
                                source="customer.name"
                                sourceId="customer.id"
                                reference="Customer"
                            />
                        </Datagrid>
                    </ArrayField>
                </FormTab>
                <FormTab label="Membership Groups">
                    <ArrayField source="membershipGroups">
                        <Datagrid>
                            <LinkToReferenceField
                                label="name"
                                source="name"
                                reference="MembershipGroup"
                            />
                        </Datagrid>
                    </ArrayField>
                </FormTab>
            </TabbedForm>
        </Edit>
        <DocumentationLink link="https://docs.google.com/document/d/1ROf-YO7t5G81iUA4hWXDjV4DQ9pOmp2OED9tKh7_xN8/edit" />
    </>
);

export default MembershipEdit;
