import useDashboard from "../useDashboard";
import SelectInput from "../../shared/SelectInput";
import React from "react";

const MembershipUsedFilter = () => {
    const { membershipUsedFilter, setFilter } = useDashboard();

    return membershipUsedFilter ? (
        <SelectInput
            label="Membership used"
            options={membershipUsedFilter.options}
            isDisabled={false}
            onChangeHandler={(e) => {
                setFilter(e, membershipUsedFilter.filterName);
            }}
            selectedValue={membershipUsedFilter.selectedValue}
        />
    ) : null;
};

export default MembershipUsedFilter;
