import { Grid, Typography, Paper } from "@material-ui/core";
import React from "react";
import ActivityFilter from "./Filters/ActivityFilter";
import MembershipFilter from "./Filters/MembershipFilter";
import IsAdminBookingFilter from "./Filters/IsAdminBookingFilter";
import CompletedDateFromFilter from "./Filters/CompletedDateFromFilter";
import CompletedDateToFilter from "./Filters/CompletedDateToFilter";
import ActivityDateFromFilter from "./Filters/ActivityDateFromFilter";
import ActivityDateToFilter from "./Filters/ActivityDateToFilter";
import CouponFilter from "./Filters/CouponFiler";
import EndTimeFilter from "./Filters/EndTimeFilter";
import ExtraFilter from "./Filters/ExtraFilter";
import MembershipUsedFilter from "./Filters/MembershipUsedFilter";
import FacilityFilter from "./Filters/FacilityFilter";
import HasBalanceFilter from "./Filters/HasBalanceFilter";
import HasDiscountFilter from "./Filters/HasDiscountFilter";
import IsCheckedInFilter from "./Filters/IsCheckedInFilter";
import ReportTypeFilter from "./Filters/ReportTypeFilter";
import ResourceFilter from "./Filters/ResourceFilter";
import StartTimeFilter from "./Filters/StartTimeFilter";
import RequestReportButton from "./RequestReportButton";
import ReportSummary from "./ReportSummary";
import ResetFilters from "./ResetFiltersButton";
import UpdateDataButton from "./UpdateDataButton";
import RefreshDataButton from "./RefreshDataButton";
import ReportUpdateInfo from "./ReportUpdateInfo";
import useDashboard from "./useDashboard";
import Loading from "../../shared/Loading";

const Dashboard = () => {
    const { isLoading } = useDashboard();
    return (
        <>
            <Paper style={{ padding: "20px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <ReportUpdateInfo />
                    </Grid>
                    <Grid item xs={6}>
                        <UpdateDataButton />
                    </Grid>
                    {/* <Grid item xs={3}>
                        <RefreshDataButton />
                    </Grid> */}
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom align="center">
                            Reports
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5">Report details</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Dates are from/to midnight, so to include the end
                            date, select the following day.
                        </Typography>
                        <Typography variant="body1">
                            The transaction report will only take into account
                            the Completed Date from and to fields.
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <ReportTypeFilter />
                    </Grid>
                    <Grid item xs={9}></Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5">
                            What was purchased?
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <FacilityFilter />
                    </Grid>
                    <Grid item xs={3}>
                        <ResourceFilter />
                    </Grid>
                    <Grid item xs={3}>
                        <ActivityFilter />
                    </Grid>
                    <Grid item xs={3}>
                        <MembershipFilter />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5">Narrow results</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <ExtraFilter />
                    </Grid>
                    <Grid item xs={3}>
                        <MembershipUsedFilter />
                    </Grid>
                    <Grid item xs={3}>
                        <CouponFilter />
                    </Grid>
                    <Grid item xs={3}></Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Transaction details
                        </Typography>
                    </Grid>

                    <Grid item xs={3}>
                        <CompletedDateFromFilter />
                    </Grid>
                    <Grid item xs={3}>
                        <CompletedDateToFilter />
                    </Grid>
                    <Grid item xs={6}></Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5">Activity details</Typography>
                    </Grid>

                    <Grid item xs={3}>
                        <ActivityDateFromFilter />
                    </Grid>
                    <Grid item xs={3}>
                        <ActivityDateToFilter />
                    </Grid>
                    <Grid item xs={3}>
                        <StartTimeFilter />
                    </Grid>
                    <Grid item xs={3}>
                        <EndTimeFilter />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5">Other filters</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <IsCheckedInFilter />
                    </Grid>
                    <Grid item xs={3}>
                        <HasBalanceFilter />
                    </Grid>
                    <Grid item xs={3}>
                        <HasDiscountFilter />
                    </Grid>
                    <Grid item xs={3}>
                        <IsAdminBookingFilter />
                    </Grid>
                    <Grid item xs={10}>
                        <RequestReportButton />
                    </Grid>
                    <Grid item xs={2}>
                        <ResetFilters />
                    </Grid>
                    <Grid item xs={12} />
                    <ReportSummary />
                    <Grid item xs={12} />
                </Grid>
            </Paper>
            {isLoading ? <Loading /> : null}
        </>
    );
};

export default Dashboard;
