import { gql } from "@apollo/client";

const taskFragment = gql`
    fragment task on Task {
        id
        name
        description
        availableForRoles {
            id
            name
        }
    }
`;

export default taskFragment;
