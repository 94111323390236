import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import {
    AUTH_ERROR,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_CHECK,
    AUTH_GET_PERMISSIONS,
    LegacyAuthProvider
} from "react-admin";
import decodeJwt from "jwt-decode";

const createAuthProvider = (
    apolloClient: ApolloClient<NormalizedCacheObject>
): LegacyAuthProvider => {
    return (type: string, params) => {
        if (type === AUTH_LOGIN) {
            const { username, password } = params;
            apolloClient
                .mutate({
                    mutation: gql`
                        mutation loginUser(
                            $email: String!
                            $password: String!
                        ) {
                            loginUser(
                                data: { email: $email, password: $password }
                            ) {
                                token
                            }
                        }
                    `,
                    variables: {
                        email: username,
                        password
                    },
                    fetchPolicy: "no-cache"
                })
                .then((result) => {
                    const { data } = result;

                    if (!data || !data.loginUser.token) {
                        throw new Error("Token not provided");
                    } else {
                        const { token } = data.loginUser;

                        localStorage.setItem("token", token);

                        // not ideal, but the reload of the page forces the apollo link to be created.
                        window.history.pushState({}, "/#/", "/#/");
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        if (type === AUTH_LOGOUT) {
            // localStorage.removeItem("token");
            // window.history.pushState({}, "/#/login", "/#/login");
            // window.location.reload();
            // return Promise.resolve();
        }
        if (type === AUTH_ERROR) {
            if (params instanceof Error) {
                const { message } = params;
                if (message.toLowerCase().includes("not authorised")) {
                    localStorage.removeItem("token");
                    return Promise.reject();
                }
            }
            return Promise.resolve();
        }
        if (type === AUTH_CHECK) {
            return localStorage.getItem("token")
                ? Promise.resolve()
                : Promise.reject();
        }
        if (type === AUTH_GET_PERMISSIONS) {
            const token = localStorage.getItem("token");
            const decodedToken: null | { role: string } = token
                ? decodeJwt(token)
                : null;
            return decodedToken
                ? Promise.resolve(decodedToken.role)
                : Promise.reject();
        }

        return Promise.resolve();
    };
};

export default createAuthProvider;
