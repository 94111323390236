import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "./AuthContext";
import ReactAdmin from "./ReactAdmin";
import { Login } from "./Login";
import { enableES5 } from "immer";

enableES5();

const App = () => {
    const { token } = useAuthContext();

    return token ? <ReactAdmin token={token} /> : <Login />;
};

export default App;
