import DateFilter from "./DateFilter";
import React from "react";
import useDashboard from "../useDashboard";
import { FilterNames } from "../useDashboard/interfaces/FilterNames";

const ActivityDateFromFilter = () => {
    const { activityDateFrom, setFilter } = useDashboard();

    return (
        <DateFilter
            label="Activity Date from"
            value={activityDateFrom}
            onChangeHandler={(date) => {
                setFilter(date, FilterNames.activityDateFromFilterName);
            }}
        />
    );
};

export default ActivityDateFromFilter;
