import { Button, Record } from "react-admin";
import React from "react";
import LinkField from "./LinkField";
import { startCase } from "lodash";
import mapRecordToReferencedField from "./mapRecordToReferencedField";

interface ICreateReferencedResourceButtonProps {
    record: Record;
    reference: string;
    currentUrl: string;
    mappings: string[][];
}

const CreateReferencedResourceButton = ({
    record,
    reference,
    currentUrl,
    mappings
}: ICreateReferencedResourceButtonProps) => {
    if (!record) {
        return null;
    }

    return (
        <Button>
            <LinkField
                pathname={`/${reference}/create`}
                prefilledRecord={mapRecordToReferencedField(record, mappings)}
                shouldRedirect={true}
                redirectUrl={currentUrl}
                linkText={`Create new ${startCase(reference)}`}
            />
        </Button>
    );
};

export default CreateReferencedResourceButton;
