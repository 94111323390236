import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { toast } from "react-toastify";
export const RESOURCES_QUERY = gql`
    query resources {
        resources {
            id
            name
        }
    }
`;
export const useLoadResources = () => {
    const { data, error, loading } = useQuery(RESOURCES_QUERY);
    if (error) {
        toast(
            `An error occurred when loading the Resources. Error message: ${error.message}`
        );
    }

    return { resources: data && data.resources ? data.resources : null };
};
