import React from "react";
import {
    BooleanField,
    Datagrid,
    List,
    TextField,
    EditButton,
    ListProps
} from "react-admin";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import DocumentationLink from "../shared/DocumentationLink";

const UserList = (props: ListProps) => (
    <>
        <List {...props}>
            <Datagrid>
                <LinkToReferenceField
                    source="name"
                    sourceId="id"
                    reference="User"
                    label="Name"
                />
                <TextField source="emailAddress" />
                <BooleanField source="isDisabled" />
                <LinkToReferenceField
                    source="organisation.name"
                    sourceId="organisation.id"
                    reference="Organisation"
                />
                <LinkToReferenceField
                    source="role.name"
                    sourceId="role.id"
                    reference="Role"
                />
                <EditButton />
            </Datagrid>
        </List>
        <DocumentationLink link="https://docs.google.com/document/d/1fgEhIQW7nNxoTTEVPGHTRufJU3iT6TCKwxbtEU6aBGk/edit" />
    </>
);

export default UserList;
