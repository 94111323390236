import { gql } from "@apollo/client";

const roleFragment = gql`
    fragment role on Role {
        id
        name
        description
        users {
            id
            name
        }
        availableTasks {
            id
            name
        }
    }
`;

export default roleFragment;
