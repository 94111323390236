import { addMinutesToTimeOfDay } from "@les-ormes/lib";
import { Button } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { IActivityInstance } from "../../../../generated/dataInterfaces";
import timelineUtil from "../../utils/timelineUtil";
import CreateForm from "../Form/CreateForm";
import useModal from "../Modal/useModal";
import useWeekScheduler from "../useWeekScheduler";
import useDeleteDaySchedule from "../useWeekScheduler/useDeleteDaySchedule";
import ActivityInstance from "./ActivityInstance";

const DaySchedule = ({
    dayOfWeek,
    id,
    dailyStartTime,
    activityInstances,
    date,
    timelinePrecision,
    timelineCount,
    reloadWeekSchedule
}: IDayScheduleProps) => {
    const dayScheduleDate = new Date(date);
    const { toggleModal, setModalTitle, setModalBodyComponent } = useModal();
    const {
        resource,
        timeLineTimes,
        createActivityInstance
    } = useWeekScheduler();

  

    if (!resource || !timeLineTimes) {
        return null;
    }

    const startEndTimeWithIntervals = activityInstances.map((a) => {
        return {
            startTime: a.startTime,
            endTime: a.endTime
        };
    });

    const availableStartTimes = timelineUtil.computeAvailableTimes(
        timeLineTimes,
        startEndTimeWithIntervals,
        resource.greatestCommonDivisorOfActivitiesDuration
    );

    const availableEndTimes = availableStartTimes.map((t) =>
        addMinutesToTimeOfDay(
            t,
            resource.greatestCommonDivisorOfActivitiesDuration
        )
    );

    const { activities } = resource;

    const onDayScheduleClickHandler = () => {
        setModalTitle(`New Activity on ${dayScheduleDate.toDateString()}`);
        setModalBodyComponent(() => (
            <CreateForm
                dayScheduleId={id}
                availableStartTimes={availableStartTimes}
                availableEndTimes={availableEndTimes}
                activities={activities}
                submitHandler={(newActivityInstance) => {
                    createActivityInstance(newActivityInstance);
                    toggleModal();
                }}
            />
        ));
        toggleModal();
    };

    const height = timelineCount * 50;




    const deleteDaySchedule = useDeleteDaySchedule(resource, dayScheduleDate);


    // const {
    //     reloadWeekSchedule
    // } = useWeekScheduler(resource.id, date);

  

    const deleteDay = async (dayScheduleDate: Date) => {
        const confirmed = confirm("This will delete all activities from this day, except those with bookings. Are you sure you want to continue?\r\n\r\nIf you click OK, please wait until you see another message to confirm deletion before continuing work");
        if (confirmed){

            await deleteDaySchedule();
            reloadWeekSchedule();
            
            alert("Success! Deleted " + moment(dayScheduleDate).format("YYYY-MM-DD") + " for resource " + resource.name + ". You can now continue working on the schedule.");
        }

       
    }

    return (
        <li
            onClick={onDayScheduleClickHandler}
            key={dayOfWeek}
            className="events-group"
        >
            <div
                className="top-info"
                style={{ height: "49px" }}
                
            >
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={async (e) => {
                         e.stopPropagation();
                        await deleteDay(dayScheduleDate);
                       
                    }}
                    data-testid="save-week-schedule-button"
                    fullWidth={true}
                >
                    Delete day
                </Button>
            </div>
            <div
                className="top-info"
                style={{ height: "49px" }}
                data-testid={`${dayOfWeek}-day-week-column`}
            >
                <span>{moment(dayScheduleDate).format("DD/MM/YYYY")}</span>
            </div>
            <ul style={{ height: `${height}px` }}>
                {activityInstances
                    ? activityInstances.map((activityInstance, index) => (
                          <ActivityInstance
                              key={index}
                              dayScheduleId={id}
                              dayScheduleDate={dayScheduleDate}
                              dailyStartTime={dailyStartTime}
                              activityInstance={activityInstance}
                              activity={activityInstance.activity}
                              timelinePrecision={timelinePrecision}
                          />
                      ))
                    : null}
              
            </ul>
        </li>
    );
};

interface IDayScheduleProps {
    dayOfWeek: number;
    id: string;
    dailyStartTime: string;
    activityInstances: Array<IActivityInstance>;
    date: Date;
    times: string[];
    timelinePrecision: number;
    timelineCount: number;
    reloadWeekSchedule: () => void;
}

export default DaySchedule;
