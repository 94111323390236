import React from "react";
import BooleanFilter from "./BooleanFilter";
import { FilterNames } from "../useDashboard/interfaces/FilterNames";
import useDashboard from "../useDashboard";
import SelectInput from "../../shared/SelectInput";

const IsCheckedInFilter = () => {
    const { isCheckedInFilter, setFilter } = useDashboard();

    return isCheckedInFilter ? (
        <SelectInput
            label="Is it Checked in?"
            options={isCheckedInFilter.options}
            isDisabled={false}
            onChangeHandler={(e) => {
                setFilter(e, isCheckedInFilter.filterName);
            }}
            selectedValue={isCheckedInFilter.selectedValue}
        />
    ) : null;
};

export default IsCheckedInFilter;
