
import { compact, flatten } from "lodash";
import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { IResource } from "../../../../generated/dataInterfaces";
import { DELETE_DAY_SCHEDULE_MUTATION } from "./queries";
import useWeekScheduler from ".";
//import { DeleteDayScheduleWithoutBookingsInput } from "../../../../../../../services/admin-service/src/generated/nexusTypes/";


const useDeleteDaySchedule = (
    resource: IResource,
    date: Date
) => {

    const [deleteDaySchedule] = useMutation(DELETE_DAY_SCHEDULE_MUTATION);
    const deleteDayScheduleExtended = useCallback(async () => {
        if (!resource || !date) {
            return;
        }

        const deleteDayScheduleInput: any = {
            resourceId: resource.id,
            day: date
        };

        const { data, errors } = await deleteDaySchedule({
                variables: { 
                    deleteDayScheduleInput
                }
            });

        console.log("useDeleteDaySchedule data", data);
        console.log("useDeleteDaySchedule errors", errors);

       
        
    }, [
        resource, date
    ]);

    return deleteDayScheduleExtended;
    
}

export default useDeleteDaySchedule;

    // const deleteDayScheduleInput: IDeleteDay
    //  const { data, errors } = await deleteDaySchedule({
    //         variables: { 
                
    //          }
    //     });
