import React from "react";
import { getResources, MenuItemLink, MenuProps } from "react-admin";
import { connect, useSelector } from "react-redux";

const MyMenu = ({ onMenuClick }: MenuProps) => {
    const resources = useSelector(getResources);

    return (
        <div style={{ padding: "5px" }}>
            <MenuItemLink
                to="/"
                primaryText="Dashboard"
                onClick={onMenuClick}
            />
            <hr />
            {resources.map((resource, index) =>
                resource.options && resource.options.hidden ? null : (
                    <div key={index}>
                        {resource.options && resource.options.breakAbove ? (
                            <hr />
                        ) : null}
                        <MenuItemLink
                            to={`/${resource.name}`}
                            data-testid={`${resource.name.toLowerCase()}-menu-link`}
                            primaryText={
                                (resource.options && resource.options.label) ||
                                resource.name
                            }
                            onClick={onMenuClick}
                        />
                    </div>
                )
            )}

            <hr />
            <MenuItemLink
                to="/c/reports"
                primaryText="Reports"
                onClick={onMenuClick}
            />
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    resources: getResources(state)
});

export default connect(mapStateToProps)(MyMenu);
