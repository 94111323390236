import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-admin";
import { useLoadResources } from "../shared/useLoadResources";

const SubMenuWeekSchedule = () => {
    const { resources } = useLoadResources();

    return (
        <Grid container spacing={24}>
            <Grid item xs={6} sm={3} />
            <Grid item xs={12} sm={6} />
            <Grid item xs={6} sm={3} />
            <Grid item xs={12}>
                <Typography variant="title" gutterBottom>
                    Resources week scheduler
                </Typography>
                <Paper>
                    {resources
                        ? resources.map((resource) => (
                              <Button
                                  style={{ margin: "10px 10px" }}
                                  key={resource.id}
                                  variant="contained"
                                  color="default"
                                  component={Link}
                                  to={`/c/resource/${resource.id}/week-scheduler`}
                              >
                                  {resource.name}
                              </Button>
                          ))
                        : null}
                </Paper>
            </Grid>
            <Grid item xs={12} />
        </Grid>
    );
};

export default SubMenuWeekSchedule;
