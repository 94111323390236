import {
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    CreateProps
} from "react-admin";
import React from "react";

const OrganisationCreate = (props: CreateProps) => (
    <Create title="Create an Organisation" {...props}>
        <SimpleForm redirect="edit">
            <TextInput source="name" validate={[required()]} />
            <BooleanInput source="isEnabled" defaultValue={true} />
            <TextInput source="logoUrl" />
            <TextInput source="midAdminScheme" />
            <TextInput source="adminScheme" />
            <TextInput source="hosts" validate={[required()]} />
        </SimpleForm>
    </Create>
);

export default OrganisationCreate;
