import React from "react";
import BooleanFilter from "./BooleanFilter";
import { FilterNames } from "../useDashboard/interfaces/FilterNames";
import useDashboard from "../useDashboard";
import SelectInput from "../../shared/SelectInput";

const HasBalanceFilter = () => {
    const { hasBalanceFilter, setFilter } = useDashboard();

    return hasBalanceFilter ? (
        <SelectInput
            label="Does it have a balance?"
            options={hasBalanceFilter.options}
            isDisabled={false}
            onChangeHandler={(e) => {
                setFilter(e, hasBalanceFilter.filterName);
            }}
            selectedValue={hasBalanceFilter.selectedValue}
        />
    ) : null;
};

export default HasBalanceFilter;
