import {
    TextInput,
    Filter,
    ReferenceInput,
    SelectInput,
    FilterProps
} from "react-admin";
import React from "react";

const PackageFilter = (props: Omit<FilterProps, "children">) => (
    <Filter {...props}>
        <TextInput
            label="Search by name"
            source="name_contains"
            alwaysOn
            resettable
        />
        {/* Facility filter not working, query comes back with results, but the count is 0 */}
        <ReferenceInput
            label="Activity"
            source="activity.id"
            reference="Activity"
            sort={{ field: "name", order: "ASC" }}
            filter={{ isPackaged: true }}
            perPage={200}
            alwaysOn
            resettable
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export default PackageFilter;
