import React from "react";
import useStateWithLocalStorageSimple from "../useStateWithLocalStorageSimple";

export interface IAuthState {
    token: string;
    setToken: React.Dispatch<React.SetStateAction<string>>;
}

const AuthContext = React.createContext<IAuthState | undefined>(undefined);

interface IAuthContextProviderProps {
    children: React.ReactNode;
}

export const AuthContextProvider = ({
    children
}: IAuthContextProviderProps) => {
    const [token, setToken] = useStateWithLocalStorageSimple<string>("token");

    const value = {
        token,
        setToken
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export const useAuthContext = () => {
    const context = React.useContext(AuthContext);

    if (context === undefined) {
        throw new Error(
            "useAuthContext must be used within a AuthContextProvider"
        );
    }

    return context;
};
