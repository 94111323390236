import { GET_LIST, UPDATE, GET_ONE, CREATE } from "react-admin";
import organisationFragment from "./organisationFragment";
import resourceFragment from "./resourceFragment";
import facilityFragment from "./facilityFragment";
import activityFragment from "./activityFragment";
import weekTemplateFragment from "./weekTemplateFragment";
import activityInstanceTemplateFragment from "./activityInstanceTemplateFragment";
import dayScheduleTemplateFragment from "./dayScheduleTemplateFragment";
import customerFragment from "./customerFragment";
import credentialFragment from "./credentialFragment";
import discountFragment from "./discountFragment";
import couponFragment from "./couponFragment";
import bookingFragment from "./bookingFragment";
import dayScheduleFragment from "./daySchedule";
import userFragment from "./userFragment";
import roleFragment from "./roleFragment";
import taskFragment from "./taskFragment";
import customerBenefitFragment from "./customerBenefitFragment";
import extraFragment from "./extraFragment";
import membershipFragment from "./membershipFragment";
import resourceGroupFragment from "./resourceGroupFragment";
import membershipGroupFragment from "./membershipGroupFragment";
import customerMembershipFragment from "./customerMembershipFragment";
import customerMembershipWithPhotoFragment from "./customerMembershipWithPhotoFragment";
import packageFragment from "./packageFragment";

export default {
    Organisation: {
        [GET_ONE]: organisationFragment,
        [GET_LIST]: organisationFragment,
        [UPDATE]: organisationFragment,
        [CREATE]: organisationFragment
    },
    Facility: {
        [GET_ONE]: facilityFragment,
        [GET_LIST]: facilityFragment,
        [UPDATE]: facilityFragment,
        [CREATE]: facilityFragment
    },
    Resource: {
        [GET_ONE]: resourceFragment,
        [UPDATE]: resourceFragment,
        [GET_LIST]: resourceFragment,
        [CREATE]: resourceFragment
    },
    Extra: {
        [GET_ONE]: extraFragment,
        [UPDATE]: extraFragment,
        [GET_LIST]: extraFragment,
        [CREATE]: extraFragment
    },
    Activity: {
        [GET_ONE]: activityFragment,
        [UPDATE]: activityFragment,
        [GET_LIST]: activityFragment,
        [CREATE]: activityFragment
    },
    WeekTemplate: {
        [GET_ONE]: weekTemplateFragment,
        [UPDATE]: weekTemplateFragment,
        [GET_LIST]: weekTemplateFragment,
        [CREATE]: weekTemplateFragment
    },
    DayScheduleTemplate: {
        [GET_ONE]: dayScheduleTemplateFragment,
        [UPDATE]: dayScheduleTemplateFragment,
        [GET_LIST]: dayScheduleTemplateFragment,
        [CREATE]: dayScheduleTemplateFragment
    },
    ActivityInstanceTemplate: {
        [GET_ONE]: activityInstanceTemplateFragment,
        [UPDATE]: activityInstanceTemplateFragment,
        [GET_LIST]: activityInstanceTemplateFragment,
        [CREATE]: activityInstanceTemplateFragment
    },
    Customer: {
        [GET_ONE]: customerFragment,
        [UPDATE]: customerFragment,
        [GET_LIST]: customerFragment,
        [CREATE]: customerFragment
    },
    CustomerBenefit: {
        [GET_ONE]: customerBenefitFragment,
        [UPDATE]: customerBenefitFragment,
        [GET_LIST]: customerBenefitFragment,
        [CREATE]: customerBenefitFragment
    },
    Credential: {
        [GET_ONE]: credentialFragment,
        [UPDATE]: credentialFragment,
        [GET_LIST]: credentialFragment,
        [CREATE]: credentialFragment
    },
    Discount: {
        [GET_ONE]: discountFragment,
        [UPDATE]: discountFragment,
        [GET_LIST]: discountFragment,
        [CREATE]: discountFragment
    },
    Coupon: {
        [GET_ONE]: couponFragment,
        [UPDATE]: couponFragment,
        [GET_LIST]: couponFragment,
        [CREATE]: couponFragment
    },
    Booking: {
        [GET_ONE]: bookingFragment,
        [UPDATE]: bookingFragment,
        [GET_LIST]: bookingFragment,
        [CREATE]: bookingFragment
    },
    DaySchedule: {
        [GET_ONE]: dayScheduleFragment,
        [UPDATE]: dayScheduleFragment,
        [GET_LIST]: dayScheduleFragment,
        [CREATE]: dayScheduleFragment
    },
    User: {
        [GET_ONE]: userFragment,
        [UPDATE]: userFragment,
        [GET_LIST]: userFragment,
        [CREATE]: userFragment
    },
    Role: {
        [GET_ONE]: roleFragment,
        [UPDATE]: roleFragment,
        [GET_LIST]: roleFragment,
        [CREATE]: roleFragment
    },
    Task: {
        [GET_ONE]: taskFragment,
        [UPDATE]: taskFragment,
        [GET_LIST]: taskFragment,
        [CREATE]: taskFragment
    },
    Membership: {
        [GET_ONE]: membershipFragment,
        [UPDATE]: membershipFragment,
        [GET_LIST]: membershipFragment,
        [CREATE]: membershipFragment
    },
    ResourceGroup: {
        [GET_ONE]: resourceGroupFragment,
        [UPDATE]: resourceGroupFragment,
        [GET_LIST]: resourceGroupFragment,
        [CREATE]: resourceGroupFragment
    },
    MembershipGroup: {
        [GET_ONE]: membershipGroupFragment,
        [UPDATE]: membershipGroupFragment,
        [GET_LIST]: membershipGroupFragment,
        [CREATE]: membershipGroupFragment
    },
    CustomerMembership: {
        [GET_ONE]: customerMembershipWithPhotoFragment,
        [UPDATE]: customerMembershipFragment,
        [GET_LIST]: customerMembershipFragment,
        [CREATE]: customerMembershipFragment
    },
    Package: {
        [GET_ONE]: packageFragment,
        [UPDATE]: packageFragment,
        [GET_LIST]: packageFragment,
        [CREATE]: packageFragment
    }
};
