import React from "react";
import {
    Create,
    CreateProps,
    NumberInput,
    ReferenceArrayInput,
    required,
    SelectArrayInput,
    SimpleForm,
    TextInput
} from "react-admin";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import DocumentationLink from "../shared/DocumentationLink";

const ResourceGroupCreate = (props: CreateProps) => (
    <>
        <Create title="Create Resource Group" {...props}>
            <SimpleForm redirect="edit">
                <DisabledHiddenOrganisationIdInput />
                <TextInput
                    source="name"
                    fullWidth
                    validate={[required()]}
                    helperText=""
                />
                <TextInput
                    source="stub"
                    fullWidth
                    validate={[required()]}
                    helperText="The stub to appear after /group/ e.g. jump-jersey"
                />
                <NumberInput
                    source="ordering"
                    helperText="The ordering of the Resource group, in listings"
                />
                <ReferenceArrayInput
                    label="Resources to display"
                    source="resourcesIds"
                    reference="Resource"
                    sort={{ field: "name", order: "ASC" }}
                    perPage={200}
                    fullWidth
                >
                    <SelectArrayInput fullWidth optionText="name" />
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
        <DocumentationLink link="https://docs.google.com/document/d/1iuR_r565tcaKRWn6L7-JNBrCuq7JC1X02j8ABsAYBeo/edit" />
    </>
);

export default ResourceGroupCreate;
