import React, { createContext, useReducer } from "react";
import { ActionType, dashboardReducer } from "./dashboardReducer";
import { IAction } from "./interfaces/IAction";
import { IDashboardContext } from "./interfaces/IDashboardContext";
import { IState } from "./interfaces/IState";

export const DashboardContext = createContext<IDashboardContext | undefined>(
    undefined
);

export const DashboardContextProvider = ({
    children
}: {
    children: React.ReactNode;
}) => {
    const initialState: IState = {
        activityFilter: null,
        resourceFilter: null,
        facilityFilter: null,
        membershipFilter: null,
        completedDateFrom: null,
        completedDateTo: null,
        activityDateFrom: null,
        activityDateTo: null,
        startTimeFilter: null,
        endTimeFilter: null,
        hasBalanceFilter: null,
        hasDiscountFilter: null,
        isCheckedInFilter: null,
        couponFilter: null,
        extraFilter: null,
        membershipUsedFilter: null,
        isAdminBookingFilter: null,
        reportTypeFilter: null,
        reportSummary: null,
        reportUpdateInfo: null,
        shouldReloadUpdateInfo: true,
        isLoading: false
    };

    const [state, dispatch] = useReducer<React.Reducer<IState, IAction>>(
        dashboardReducer,
        initialState
    );

    const populateFilters = (filtersData): void => {
        dispatch({
            type: ActionType.PopulateFilters,
            payload: filtersData
        });
    };

    const setFilter = (value, filterName) => {
        dispatch({
            type: ActionType.SetFilter,
            payload: {
                value,
                filterName
            }
        });
    };

    const resetFilters = () => {
        dispatch({
            type: ActionType.ResetFilters,
            payload: null
        });
    };

    const setReportResult = (requestReport) => {
        dispatch({
            type: ActionType.RequestReport,
            payload: {
                requestReport
            }
        });
    };

    const setIsLoading = () => {
        dispatch({
            type: ActionType.SetIsLoading,
            payload: {}
        });
    };

    const setReportUpdateInfo = (reportUpdateInfo) => {
        dispatch({
            type: ActionType.SetUpdateReportInfo,
            payload: {
                reportUpdateInfo
            }
        });
    };

    const contextValue: IDashboardContext = {
        ...state,
        populateFilters,
        setFilter,
        setReportResult,
        setIsLoading,
        resetFilters,
        setReportUpdateInfo
    };
    return (
        <DashboardContext.Provider value={contextValue}>
            {children}
        </DashboardContext.Provider>
    );
};
export default DashboardContext;
