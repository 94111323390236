import { gql } from "@apollo/client";

const membershipGroupFragment = gql`
    fragment membershipGroupFragment on MembershipGroup {
        id
        name
        stub
        organisation {
            id
            name
        }
        memberships(orderBy: name_ASC, where: { isDeleted_not: true }) {
            id
            name
        }
        createdAt
        updatedAt
        ordering
        isAvailable
        isHiddenFromPublic
        isDeleted
    }
`;

export default membershipGroupFragment;
