import React from "react";
import {
    BooleanField,
    Datagrid,
    EditButton,
    List,
    ListProps,
    TextField
} from "react-admin";

const OrganisationList = (props: ListProps) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <BooleanField source="isEnabled" />
            <EditButton />
        </Datagrid>
    </List>
);

export default OrganisationList;
