import { gql } from "@apollo/client";

const membershipFragment = gql`
    fragment membership on Membership {
        id
        name
        description
        organisation {
            id
            name
        }
        imageUrl
        isAvailable
        price
        resources(orderBy: name_ASC, where: { isDeleted_not: true }) {
            id
            name
        }
        membershipGroups(orderBy: name_ASC, where: { isDeleted_not: true }) {
            id
            name
        }
        customerMemberships {
            id
            name
            customer {
                id
                name
            }
        }
        allowedBookingsPerDay
        isLimitlessInBookingsPerDay
        doesRequirePhoto
        discountType
        discountAmount
        lengthInDays
        validDaysOfWeek
        renewalReminderDaysBeforeExpiry
        renewalAvailableDaysBeforeExpiry
        isRenewable
        canBeCrossAuthorised
        createdAt
        updatedAt
        ordering
        allowedTimesInDay {
            startTime
            endTime
        }
        isHiddenFromPublic
        isDeleted
        deletedAt
        deletedByUser {
            id
            name
        }
    }
`;

export default membershipFragment;
