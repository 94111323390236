import InputAdornment from "@material-ui/core/InputAdornment";
import React from "react";
import {
    ArrayField,
    BooleanInput,
    Datagrid,
    DateField,
    Edit,
    FormTab,
    FunctionField,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
    NumberInput,
    DateTimeInput,
    EditProps
} from "react-admin";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import CustomerMembershipUploadPhoto from "./CustomerMembershipUploadPhoto";
import CustomerMembershipViewPhoto from "./CustomerMembershipViewPhoto";

const CustomerMembershipEdit = (props: EditProps) => (
    <Edit title="Edit Customer Membership" {...props}>
        <TabbedForm redirect="edit">
            <FormTab label="summary">
                <TextInput
                    source="name"
                    fullWidth
                    validate={[required()]}
                    helperText="The name of the Customer Membership"
                />
                <ReferenceInput
                    label="Membership"
                    source="membership.id"
                    reference="Membership"
                    validate={[required()]}
                    sort={{ field: "name", order: "ASC" }}
                    perPage={200}
                    fullWidth
                    helperText="Please select a Membership"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                    label="Customer"
                    source="customer.id"
                    reference="Customer"
                    validate={[required()]}
                    sort={{ field: "name", order: "ASC" }}
                    fullWidth
                    helperText="Please select a Customer"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <NumberInput
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                        )
                    }}
                    source="amountPaid"
                    validate={[required()]}
                    defaultValue={0}
                />
                <BooleanInput source="hasBeenActivated" defaultValue={false} />
                <DateTimeInput source="activatedAt" label="Activated at" />
                <BooleanInput source="isDisabled" defaultValue={false} />
                <DateTimeInput label="disabledAt" source="disabledAt" />
                <ReferenceInput
                    source="disabledByUser.id"
                    reference="User"
                    sort={{ field: "name", order: "ASC" }}
                    perPage={200}
                    fullWidth
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <DateTimeInput label="Expired at" source="expiresAt" />
                <DateTimeInput label="Completed at" source="completedAt" />
                <DateTimeInput
                    label="Photo uploaded at"
                    source="photoUploadedAt"
                />
                <ReferenceInput
                    source="photoUpdatedByUser.id"
                    reference="User"
                    sort={{ field: "name", order: "ASC" }}
                    perPage={200}
                    fullWidth
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <DateTimeInput
                    label="Photo updated By User at"
                    source="photoUpdatedByUserAt"
                />
                <BooleanInput
                    source="isActivationEmailSent"
                    defaultValue={false}
                />
                <DateTimeInput
                    label="Activation email sent at"
                    source="activationEmailSentAt"
                />
                <DateTimeInput
                    label="Renewal available date"
                    source="renewalAvailableAt"
                />
                <BooleanInput source="canPhotoBeChanged" defaultValue={false} />
                <DateTimeInput
                    label="First renewal reminder send on"
                    source="renewalReminder1SendOn"
                />
                <BooleanInput
                    source="hasRenewalReminder1BeenSent"
                    defaultValue={false}
                />
                <DateTimeInput
                    label="Second renewal reminder send on"
                    source="renewalReminder2SendOn"
                />
                <BooleanInput
                    source="hasRenewalReminder2BeenSent"
                    defaultValue={false}
                />
                <DateTimeInput
                    label="Photo last changed at"
                    source="photoLastChangedAt"
                />
                <ReferenceInput
                    source="createdByUser.id"
                    reference="User"
                    sort={{ field: "name", order: "ASC" }}
                    perPage={200}
                    fullWidth
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="createdAt" disabled />
                <TextInput source="updatedAt" disabled />
            </FormTab>
            <FormTab label="Photo upload">
                <CustomerMembershipUploadPhoto />
            </FormTab>
            <FormTab label="View Photo">
                <CustomerMembershipViewPhoto />
            </FormTab>
            <FormTab label="Transactions">
                <ArrayField source="transactions">
                    <Datagrid>
                        <LinkToReferenceField
                            label="Provider identifier"
                            source="providerIdentifier"
                            reference="Transaction"
                        />
                    </Datagrid>
                </ArrayField>
            </FormTab>
            <FormTab label="Bookings">
                <ArrayField source="bookings">
                    <Datagrid>
                        <LinkToReferenceField
                            label="reference"
                            source="reference"
                            reference="Booking"
                        />
                    </Datagrid>
                </ArrayField>
            </FormTab>
            <FormTab label="Console Notes">
                <ArrayField source="midAdminNotes">
                    <Datagrid>
                        <DateField showTime source="createdAt" />
                        <FunctionField
                            label="Type"
                            render={(record) => `${record.noteType}`}
                        />
                        <TextField source="content" />
                        <LinkToReferenceField
                            label="By User"
                            source="createdByUser.name"
                            sourceId="createdByUser.id"
                            reference="User"
                        />
                    </Datagrid>
                </ArrayField>
            </FormTab>
            <FormTab label="Customer Notes">
                <ArrayField source="customerNotes">
                    <Datagrid>
                        <DateField showTime source="createdAt" />
                        <FunctionField
                            label="Type"
                            render={(record) => `${record.noteType}`}
                        />
                        <TextField source="content" />
                    </Datagrid>
                </ArrayField>
            </FormTab>
            <FormTab label="renewals">
                <ArrayField source="renewals">
                    <Datagrid>
                        <DateField showTime source="completedAt" />

                        <LinkToReferenceField
                            label="Renewals"
                            source="id"
                            reference="CustomerMembershipRenewal"
                        />
                    </Datagrid>
                </ArrayField>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default CustomerMembershipEdit;
