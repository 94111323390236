import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import enGB from "date-fns/locale/en-GB";
import React, { useState, useCallback } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ModalContextProvider } from "./Modal/ModalContext";
import useWeekScheduler from "./useWeekScheduler";
import WeekSchedule from "./WeekSchedule";
import WeekTemplatePicker from "./WeekTemplatePicker";
import Grid from "@material-ui/core/Grid";
import confirm from "../shared/confirmAlert/confirm";
import { Prompt } from "react-router";
import { Button } from "@material-ui/core";

registerLocale("en-GB", enGB);

const getNextWeekFromToday = () => {
    const nextWeekDate = new Date();
    nextWeekDate.setDate(nextWeekDate.getDate() + 7);
    return nextWeekDate;
};

interface IWeekSchedulerProps {
    resourceId: string;
}

const WeekScheduler = ({ resourceId }: IWeekSchedulerProps) => {
    const [selectedDate, setSelectedDate] = useState(getNextWeekFromToday());

    const {
        weekSchedule,
        resource,
        reloadWeekSchedule,
        checkAreThereUnsavedChanges
    } = useWeekScheduler(resourceId, selectedDate);

    if (!resource || !weekSchedule) {
        return null;
    }

    const isUnsaved = checkAreThereUnsavedChanges();

    return (
        <Grid container spacing={24}>
            <Grid item xs={12}>
                <Typography variant="h3" gutterBottom>
                    {resource.name} Week Scheduler{" "}
                    <div style={{ float: "right", margin: "" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            href={`/#/Resource/${resource.id}`}
                        >
                            Return to Resource
                        </Button>
                    </div>
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <DatePicker
                    inline
                    selected={selectedDate}
                    scrollableMonthYearDropdown={true}
                    locale="en-GB"
                    onChange={(date : Date) => {
                        console.log("Date:", date);
                        if (checkAreThereUnsavedChanges()) {
                            confirm(
                                "Unsaved changes, do you want to lose them and load another week?"
                            ).then(() => {
                                setSelectedDate(date);
                                reloadWeekSchedule();
                            });
                        } else {
                            setSelectedDate(date);
                            reloadWeekSchedule();
                        }
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <Typography
                    variant="h4"
                    gutterBottom
                    style={{ float: "right" }}
                >
                    {isUnsaved ? "*UNSAVED CHANGES ON PAGE*" : ""}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Paper>
                    <WeekTemplatePicker resourceId={resourceId} />
                    <ModalContextProvider>
                        <WeekSchedule />
                    </ModalContextProvider>
                </Paper>
            </Grid>
            <Prompt
                when={isUnsaved}
                message="You have unsaved changes. Are you sure you want to leave this page? Changes will be lost."
            />
        </Grid>
    );
};
export default WeekScheduler;
