import React from "react";
import {
    BooleanField,
    Datagrid,
    EditButton,
    List,
    NumberField,
    TextField,
    Pagination,
    ListProps,
    PaginationProps
} from "react-admin";
import DocumentationLink from "../shared/DocumentationLink";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import ResourceFilter from "./ResourceFilter";

const ResourceList = (props: ListProps) => {
    const PostPagination = (props: PaginationProps) => (
        <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
    );

    return (
        <>
            <List
                perPage={25}
                filters={<ResourceFilter />}
                filter={{ isDeleted_not: true }}
                pagination={<PostPagination />}
                {...props}
            >
                <Datagrid>
                    {/* <TextField source="id" /> */}
                    <LinkToReferenceField
                        source="name"
                        sourceId="id"
                        reference="Resource"
                        label="Name"
                    />
                    <LinkToReferenceField
                        source="facility.name"
                        sourceId="facility.id"
                        reference="Facility"
                        label="Facility"
                    />
                    <TextField source="stub" label="/resource/stub" />
                    <NumberField source="ordering" />
                    <BooleanField source="isHiddenFromPublic" label="Hidden?" />
                    <BooleanField source="isAvailable" label="Available?" />
                    <BooleanField
                        source="isShownOnDefaultView"
                        label="Default view?"
                    />
                    <EditButton />
                </Datagrid>
            </List>
            <DocumentationLink link="https://docs.google.com/document/d/1VR4obxWc06EMoiuL0Ytqp53-oJhXz_MeX5v_2gSP-yk/edit" />
        </>
    );
};

export default ResourceList;
