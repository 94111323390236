import { IDaySchedule } from "../../../../generated/dataInterfaces";

import { flatten, compact } from "lodash";

const computeNewActivityInstances = (weekSchedule: Array<IDaySchedule>) => {
    const newActivityInstances = flatten(
        compact(
            weekSchedule.map((d) =>
                d.activityInstances.filter((a) => a.id === "")
            )
        )
    );

    return newActivityInstances;
};

export default computeNewActivityInstances;
