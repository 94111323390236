import { gql } from "@apollo/client";

const dayScheduleTemplateFragment = gql`
    fragment dayScheduleTemplate on DayScheduleTemplate {
        id
        dayOfWeek
        weekTemplate {
            id
            name
            resource {
                id
                name
                activities {
                    id
                    name
                }
            }
        }
        activityInstanceTemplates {
            id
            startTime
            endTime
            activity {
                id
                name
            }
            dayScheduleTemplate {
                id
            }
        }
        createdAt
        updatedAt
    }
`;

export default dayScheduleTemplateFragment;
