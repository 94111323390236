import { Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { DatePicker, DateTimePicker, TimePicker } from "@material-ui/pickers";
import moment from "moment";
import React from "react";

moment.locale("en");

interface IActivityDateStartTimeEndTimeProps {
    date: moment.Moment;
    endTime: moment.Moment;
    mustDisableDateInput: boolean;
    index: number;
    onDateEndTimeChange: (
        date: moment.Moment,
        endTime: moment.Moment,
        index: number
    ) => void;
    onDelete?: (index: number) => void;
}

const ActivityDateStartTimeEndTime = ({
    date,
    endTime,
    index,
    mustDisableDateInput,
    onDateEndTimeChange,
    onDelete
}: IActivityDateStartTimeEndTimeProps) => {
    console.log({ date });
    return (
        <Grid item xs={12}>
            {mustDisableDateInput ? (
                <>
                    <DatePicker
                        label={
                            index !== undefined
                                ? `Activity ${index + 1} date`
                                : "Activity date"
                        }
                        onChange={() => {
                            onDateEndTimeChange(
                                date,
                                moment(date).add(1, "hours"),
                                index
                            );
                        }}
                        disabled
                        format="dd/MM/yyyy"
                        value={date}
                    />
                    <TimePicker
                        label={`Start time`}
                        onChange={(newDate: moment.Moment | null) => {
                            onDateEndTimeChange(
                                newDate ?? moment(),
                                endTime,
                                index
                            );
                        }}
                        ampm={false}
                        format="HH:mm"
                        value={date}
                    />
                </>
            ) : (
                <DateTimePicker
                    label={`Date and Start time`}
                    onChange={(newDate: moment.Moment | null) => {
                        onDateEndTimeChange(
                            newDate ?? moment(),
                            endTime,
                            index
                        );
                    }}
                    ampm={false}
                    format="dd/MM/yyyy HH:mm"
                    value={date}
                />
            )}

            <TimePicker
                label="End time"
                onChange={(newEndTimeDate: moment.Moment | null) => {
                    onDateEndTimeChange(
                        date,
                        moment(newEndTimeDate, "HH:mm"),
                        index
                    );
                }}
                ampm={false}
                format="HH:mm"
                value={endTime}
            />
            {index !== undefined ? (
                <IconButton
                    aria-label="Delete"
                    color="primary"
                    onClick={() => {
                        if (onDelete) {
                            onDelete(index);
                        }
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            ) : null}
        </Grid>
    );
};

export default ActivityDateStartTimeEndTime;
