import React from "react";
import {
    BooleanInput,
    Edit,
    EditProps,
    FormTab,
    NumberInput,
    ReferenceArrayInput,
    required,
    SelectArrayInput,
    TabbedForm,
    TextInput
} from "react-admin";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import DocumentationLink from "../shared/DocumentationLink";

const MembershipGroupEdit = (props: EditProps) => (
    <>
        <Edit title="Edit Membership Group" {...props}>
            <TabbedForm redirect="edit">
                <FormTab label="summary">
                    <DisabledHiddenOrganisationIdInput />
                    <TextInput
                        source="name"
                        fullWidth
                        validate={[required()]}
                        helperText="The name of the group, used as the title"
                    />
                    <TextInput
                        source="stub"
                        fullWidth
                        validate={[required()]}
                        helperText="The stub to appear after /memberships/groups/ e.g. golf-course"
                    />
                    <NumberInput
                        source="ordering"
                        helperText="The ordering of the Membership group, in listings"
                    />
                    <ReferenceArrayInput
                        label="Pick the memberships for this group"
                        source="membershipsIds"
                        reference="Membership"
                        sort={{ field: "name", order: "ASC" }}
                        perPage={200}
                        fullWidth
                    >
                        <SelectArrayInput optionText="name" />
                    </ReferenceArrayInput>
                    <BooleanInput
                        source="isAvailable"
                        defaultValue={true}
                        validate={[required()]}
                        helperText="If not set, this membership group will not be displayed anywhere (website or mid-admin)"
                    />
                    <BooleanInput
                        source="isHiddenFromPublic"
                        defaultValue={false}
                        validate={[required()]}
                        helperText="If not set, this membership group will not be displayed on the website"
                    />
                    <BooleanInput
                        source="isDeleted"
                        defaultValue={false}
                        validate={[required()]}
                        helperText="If set this membership group will be deleted"
                    />
                </FormTab>
            </TabbedForm>
        </Edit>
        <DocumentationLink link="https://docs.google.com/document/d/1BP4TaqmmvvuNX1ePg9Ic34uWPnzlgUawBPVVJVxV-y4/edit" />
    </>
);

export default MembershipGroupEdit;
