import produce from "immer";
import { IWeekTemplateWithTimeLine } from "./interfaces";

const deleteActivityInstanceFromWeekTemplate: IDeleteActivityInstanceFromWeekTemplate =
    (weekTemplate, dayOfWeek, activityId, startTime, endTime) => {
        const dayScheduleTemplate =
            weekTemplate.dayScheduleTemplates[dayOfWeek - 1];

        const activityInstanceTemplateIndex =
            dayScheduleTemplate.activityInstanceTemplates.findIndex(
                (a) =>
                    a.activity.id === activityId &&
                    a.startTime === startTime &&
                    a.endTime === endTime
            );

        if (activityInstanceTemplateIndex >= 0) {
            const newWeekTemplate = produce(
                weekTemplate,
                (draftWeekTemplate) => {
                    draftWeekTemplate.dayScheduleTemplates[
                        dayOfWeek - 1
                    ].activityInstanceTemplates.splice(
                        activityInstanceTemplateIndex,
                        1
                    );
                }
            );

            return newWeekTemplate;
        }

        return null;
    };

export interface IDeleteActivityInstanceFromWeekTemplate {
    (
        weekTemplate: IWeekTemplateWithTimeLine,
        dayOfWeek: number,
        activityId: string,
        startTime: string,
        endTime: string
    ): IWeekTemplateWithTimeLine;
}

export default deleteActivityInstanceFromWeekTemplate;
