import React, { createContext, useContext, useState } from "react";
import { IActivityInstanceTemplate } from "../../../generated/dataInterfaces";
import { WeekTemplateContext } from "./WeekTemplateContext";
import { IWeekTemplateContext } from "./WeekTemplateContext/interfaces";

export const ModalContext = createContext({});

export const ModalContextProvider = ({ children }) => {
    const { getActivityInstanceTemplateOnWeekTemplate } = useContext(
        WeekTemplateContext
    ) as IWeekTemplateContext;

    const [selectedDayName, setSelectedDayName] = useState("Monday");
    const [selectedDay, setSelectedDay] = useState(1);
    const [isModalOpen, setModalOpenTo] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const [
        selectedActivityInstanceTemplate,
        setSelectedActivityInstanceTemplate
    ] = useState<IActivityInstanceTemplate>(null);

    const toggleModalInAddingMode = (dayOfWeek, dayOfWeekName) => {
        setIsEditMode(false);
        setSelectedActivityInstanceTemplate(null);
        setSelectedDay(dayOfWeek);
        setSelectedDayName(dayOfWeekName);
        setModalOpenTo(!isModalOpen);
    };

    const toggleModalInEditingMode = (
        dayOfWeek,
        dayOfWeekName,
        activityId,
        startTime,
        endTime
    ) => {
        setIsEditMode(true);
        setSelectedDay(dayOfWeek);
        setSelectedDayName(dayOfWeekName);
        setModalOpenTo(!isModalOpen);

        const activityInstanceTemplate = getActivityInstanceTemplateOnWeekTemplate(
            dayOfWeek,
            activityId,
            startTime,
            endTime
        );
        console.log("TCL: activityInstanceTemplate", activityInstanceTemplate);
        setSelectedActivityInstanceTemplate(activityInstanceTemplate);
    };

    const closeModal = () => {
        setIsEditMode(false);
        setSelectedActivityInstanceTemplate(null);
        setModalOpenTo(false);
    };

    const weekTemplateContext: IModalContext = {
        isModalOpen,
        selectedDay,
        selectedDayName,
        isEditMode,
        toggleModalInAddingMode,
        toggleModalInEditingMode,
        closeModal,
        selectedActivityInstanceTemplate
    };

    return (
        <ModalContext.Provider value={weekTemplateContext}>
            {children}
        </ModalContext.Provider>
    );
};

export interface IModalContext {
    selectedDay: number;
    selectedDayName: string;
    isModalOpen: boolean;
    isEditMode: boolean;
    toggleModalInAddingMode: (dayOfWeek: number, dayOfWeekName: string) => void;
    toggleModalInEditingMode: (
        dayOfWeek: number,
        dayOfWeekName: string,
        activityId?: string,
        startTime?: string,
        endTime?: string
    ) => void;
    closeModal: () => void;
    selectedActivityInstanceTemplate: IActivityInstanceTemplate;
}
