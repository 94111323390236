import React from "react";
import RecordLink from "./RecordLink";

const LinkToReferenceField = ({
    record,
    reference,
    source,
    sourceId = "id",
    prefilledRecord = {},
    shouldRedirect = false,
    redirectUrl = ""
}: ILinkToReferenceFieldProps) => {
    return record ? (
        <RecordLink
            record={record}
            baseUrl={`/${reference}`}
            source={source}
            sourceId={sourceId}
            otherProps={{ prefilledRecord, shouldRedirect, redirectUrl }}
        />
    ) : null;
};

interface ILinkToReferenceFieldProps {
    record?: any;
    reference: string;
    source: string;
    label?: string;
    sourceId?: string;
    prefilledRecord?: any;
    shouldRedirect?: boolean;
    redirectUrl?: string;
}

export default LinkToReferenceField;
