import DateFilter from "./DateFilter";
import React from "react";
import useDashboard from "../useDashboard";
import { FilterNames } from "../useDashboard/interfaces/FilterNames";

const ActivityDateToFilter = () => {
    const { activityDateTo, setFilter } = useDashboard();

    return (
        <DateFilter
            label="Activity Date to"
            value={activityDateTo}
            onChangeHandler={(date) => {
                setFilter(date, FilterNames.activityDateToFilterName);
            }}
        />
    );
};

export default ActivityDateToFilter;
