import { Typography } from "@material-ui/core";
import React from "react";
import { FormDataConsumer } from "react-admin";
import CreateActivityInstancesWithRRule from "./CreateActivityInstancesWithRRule";

interface ICreateActivityInstancesWithRRuleFormProps {
    record?: any;
}
const CreateActivityInstancesWithRRuleForm = ({
    record
}: ICreateActivityInstancesWithRRuleFormProps) => {
    return record &&
        record.activityInstances &&
        record.activityInstances.length === 0 ? (
        <FormDataConsumer>
            {({ formData }) => {
                return (
                    <CreateActivityInstancesWithRRule
                        activity={
                            formData && formData.activity
                                ? formData.activity
                                : null
                        }
                        packageId={formData && formData.id ? formData.id : null}
                    />
                );
            }}
        </FormDataConsumer>
    ) : (
        <Typography variant="h4">
            To recreate activity instances you need to delete them first on the
            Activity Instances tab.
        </Typography>
    );
};

export default CreateActivityInstancesWithRRuleForm;
