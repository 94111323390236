import useDashboard from "../useDashboard";
import SelectInput from "../../shared/SelectInput";
import React from "react";

const MembershipFilter = () => {
    const { membershipFilter, setFilter } = useDashboard();

    return membershipFilter ? (
        <SelectInput
            label="Membership"
            options={membershipFilter.options}
            isDisabled={false}
            onChangeHandler={(e) => {
                setFilter(e, membershipFilter.filterName);
            }}
            selectedValue={membershipFilter.selectedValue}
        />
    ) : null;
};

export default MembershipFilter;
