import { gql } from "@apollo/client";

//DO NOT ADD dayScheduleTemplates id
const weekTemplateFields = `
id
name
dayScheduleTemplates {
    dayOfWeek
    dayOfWeekName
  	activityInstanceTemplates {
      startTime
      endTime
      pricePerBooking
      maxCapacity
      activity {
          id
          name
          colorScheme
      }
    }
}
resource {
    id
    name
    dailyStartTime
    dailyEndTime
    greatestCommonDivisorOfActivitiesDuration
    activities {
        id
        name
        defaultSlotLengthMinutes
        colorScheme
        defaultPricePerBooking
        resource {
            defaultMaxCapacity
        }
    }
}
`;

export const EMPTY_WEEK_TEMPLATE_QUERY = gql`
    query emptyWeekTemplate($resourceId: ID!) {
        resourceEmptyWeekTemplateQuery(input: { resourceId: $resourceId }) {
            ${weekTemplateFields}
        }
    }
`;

export const WEEK_TEMPLATE_QUERY = gql`
    query weekTemplate($weekTemplateId: ID!){
        weekTemplate(where: {id: $weekTemplateId}){
            ${weekTemplateFields}
        }
    }
`;

export const CREATE_WEEK_TEMPLATE_MUTATION = gql`
    mutation createWeekTemplateForResource(
        $createWeekTemplateForResourceInput: CreateWeekTemplateForResourceInput!
    ) {
        createWeekTemplateForResource(
            input: $createWeekTemplateForResourceInput
        ) {
            id
        }
    }
`;

export const UPDATE_WEEK_TEMPLATE_MUTATION = gql`
    mutation updateWeekTemplateForResource(
        $updateWeekTemplateForResourceInput: UpdateWeekTemplateForResourceInput!
    ) {
        updateWeekTemplateForResource(
            input: $updateWeekTemplateForResourceInput
        ) {
            id
        }
    }
`;

export const DELETE_WEEK_TEMPLATE_MUTATION = gql`
    mutation deleteWeekTemplate(
        $weekTemplateWhereUniqueInput: WeekTemplateWhereUniqueInput!
    ) {
        deleteWeekTemplate(where: $weekTemplateWhereUniqueInput) {
            id
        }
    }
`;
