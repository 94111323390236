import React from "react";
import {
    Edit,
    EditProps,
    ReferenceArrayInput,
    required,
    SelectArrayInput,
    SimpleForm,
    TextInput
} from "react-admin";
import DocumentationLink from "../shared/DocumentationLink";

const RoleEdit = (props: EditProps) => (
    <>
        <Edit title="Edit Role" {...props}>
            <SimpleForm redirect="edit">
                <TextInput source="name" validate={[required()]} />
                <TextInput source="description" validate={[required()]} />
                <ReferenceArrayInput
                    label="Available Tasks"
                    source="availableTasksIds"
                    reference="Task"
                    sort={{ field: "name", order: "ASC" }}
                    perPage={200}
                >
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
        <DocumentationLink link="https://docs.google.com/document/d/1ZyKM5svVs8FL6QDxQ59u25tqMiFeugagBzUNKyCMVQQ/edit" />
    </>
);

export default RoleEdit;
