import { gql } from "@apollo/client";

const discountFragment = gql`
    fragment discount on Discount {
        id
        name
        organisation {
            id
            name
        }
        description
        isActive
        discountAmount
        validDaysOfWeek
        validActivityIds
        validForMaxQuantity
        appliesToExtras
        appliesToActivities
        discountType
        createdAt
        updatedAt
    }
`;

export default discountFragment;
