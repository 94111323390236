import React from "react";
import {
    BooleanField,
    Datagrid,
    EditButton,
    List,
    ListProps,
    Pagination,
    PaginationProps
} from "react-admin";
import DocumentationLink from "../shared/DocumentationLink";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import CustomerFilter from "./CustomerFilter";

const CustomerList = (props: ListProps) => {
    const PostPagination = (props: PaginationProps) => (
        <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
    );

    return (
        <>
            <List
                filters={<CustomerFilter />}
                perPage={50}
                pagination={<PostPagination />}
                {...props}
            >
                <Datagrid>
                    <LinkToReferenceField
                        source="name"
                        sourceId="id"
                        reference="Customer"
                        label="Name"
                    />
                    <BooleanField source="isDisabled" />
                    <EditButton />
                </Datagrid>
            </List>
            <DocumentationLink link="https://docs.google.com/document/d/16mr-Y7K11cQX0AhplTffM0-6frYCfwlfZqZeTi4Z8bA/edit" />
        </>
    );
};

export default CustomerList;
