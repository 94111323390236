import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import {
    IReportFilterInput,
    IReportResponse
} from "../../../../generated/dataInterfaces";
import { IState } from "./interfaces/IState";

export const REQUEST_REPORT_MUTATION = gql`
    mutation requestReportMutation($requestReportInput: ReportFilterInput!) {
        requestReport(input: $requestReportInput) {
            name
            downloadLink
            requestedAt
            numberOfSeconds
            details {
                key
                value
            }
        }
    }
`;

interface IRequestReportMutationResult {
    requestReport: IReportResponse;
}

const mapEitherYesNo = (value: string | null) => {
    let result = null;
    switch (value) {
        case "either":
            result = null;
            break;
        case "yes":
            result = true;
            break;
        case "no":
            result = false;
            break;
    }
    return result;
};

const mapAnyToEmptyString = (value: string) => {
    return value === "any" ? "" : value;
};

export const useRequestReport = (
    setReportResult: (report: IReportResponse) => void
) => {
    const [requestReportMutation] = useMutation<IRequestReportMutationResult>(
        REQUEST_REPORT_MUTATION
    );

    const requestReport = async (filters: IState) => {
        const requestReportInput: IReportFilterInput = {
            reportType: mapAnyToEmptyString(
                filters.reportTypeFilter.selectedValue
            ),
            activityDateFrom: filters.activityDateFrom,
            activityDateTo: filters.activityDateTo,
            activityId: mapAnyToEmptyString(
                filters.activityFilter.selectedValue
            ),
            facilityId: mapAnyToEmptyString(
                filters.facilityFilter.selectedValue
            ),
            resourceId: mapAnyToEmptyString(
                filters.resourceFilter.selectedValue
            ),
            membershipId: mapAnyToEmptyString(
                filters.membershipFilter.selectedValue
            ),
            membershipUsedId: mapAnyToEmptyString(
                filters.membershipUsedFilter.selectedValue
            ),
            completeDateFrom: filters.completedDateFrom,
            completeDateTo: filters.completedDateTo,
            couponId: mapAnyToEmptyString(filters.couponFilter.selectedValue),
            extraId: mapAnyToEmptyString(filters.extraFilter.selectedValue),
            startTime: mapAnyToEmptyString(
                filters.startTimeFilter.selectedValue
            ),
            endTime: mapAnyToEmptyString(filters.endTimeFilter.selectedValue),
            hasBalance: mapEitherYesNo(filters.hasBalanceFilter.selectedValue),
            hasCheckedIn: mapEitherYesNo(
                filters.isCheckedInFilter.selectedValue
            ),
            hasDiscount: mapEitherYesNo(
                filters.hasDiscountFilter.selectedValue
            ),
            isAdminBooking: mapEitherYesNo(
                filters.isAdminBookingFilter.selectedValue
            )
        };
        const { data, errors } = await requestReportMutation({
            variables: { requestReportInput }
        });

        if (data && data.requestReport) {
            setReportResult(data.requestReport);
        }
    };

    return { requestReport };
};

export default useRequestReport;
