import { INewActivityInstanceInput } from "./interfaces";
import {
    IActivityInstance,
    IActivity
} from "../../../../generated/dataInterfaces";

const mapToActivityInstance = (
    activities: Array<IActivity>,
    activityInstanceInput: INewActivityInstanceInput
) => {
    const {
        dayScheduleId,
        maxCapacity,
        quantityUnavailable,
        startTime,
        endTime,
        pricePerBooking,
        activityId
    } = activityInstanceInput;

    const activityInstance: IActivityInstance = {
        id: "",
        activity: activities.find((a) => a.id === activityId),
        daySchedule: { id: dayScheduleId, resource: null, date: null },
        maxCapacity,
        startTime,
        endTime,
        quantityUnavailable,
        pricePerBooking
    };

    return activityInstance;
};

export default mapToActivityInstance;
