import { FetchResult, gql, MutationFunctionOptions } from "@apollo/client";
import { useMutation } from "@apollo/client";
import {
    IPackage,
    IPackageWhereUniqueInput,
    ICreatePackageActivityInstancesInput
} from "../../../../generated/dataInterfaces";

const CREATE_PACKAGE_ACTIVITY_INSTANCES_MUTATION = gql`
    mutation createPackageActivityInstances(
        $packageWhereUniqueInput: PackageWhereUniqueInput!
        $createPackageActivityInstancesInput: CreatePackageActivityInstancesInput!
    ) {
        createPackageActivityInstances(
            data: $createPackageActivityInstancesInput
            where: $packageWhereUniqueInput
        ) {
            id
            name
        }
    }
`;

interface IUseCreateActivityInstances {
    createPackageActivityInstances: (
        options?:
            | MutationFunctionOptions<
                  { createPackageActivityInstances: IPackage },
                  {
                      packageWhereUniqueInput: IPackageWhereUniqueInput;
                      createPackageActivityInstancesInput: ICreatePackageActivityInstancesInput;
                  }
              >
            | undefined
    ) => Promise<
        FetchResult<
            { createPackageActivityInstances: IPackage },
            Record<string, any>,
            Record<string, any>
        >
    >;
    loading: boolean;
}

const useCreateActivityInstances = (): IUseCreateActivityInstances => {
    const [createPackageActivityInstances, { loading }] = useMutation<
        { createPackageActivityInstances: IPackage },
        {
            packageWhereUniqueInput: IPackageWhereUniqueInput;
            createPackageActivityInstancesInput: ICreatePackageActivityInstancesInput;
        }
    >(CREATE_PACKAGE_ACTIVITY_INSTANCES_MUTATION, {
        onCompleted: (data) => {
            console.log(data);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    return { createPackageActivityInstances, loading };
};

export default useCreateActivityInstances;
