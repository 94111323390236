import { useState, useEffect } from "react";

export function useStateWithLocalStorageSimple<T>(
    localStorageKey: string
): [T | null, (value: T) => void] {
    let itemFromLocalStorage: T | null = null;

    try {
        itemFromLocalStorage = JSON.parse(
            localStorage.getItem(localStorageKey) ?? ""
        ) as T;
    } catch (error) {
        console.log("error: ", error);
        localStorage.removeItem(localStorageKey);
    }

    const [value, setValue] = useState<T | null>(itemFromLocalStorage);

    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(value));
    }, [value, localStorageKey]);

    return [value, setValue];
}

export default useStateWithLocalStorageSimple;
