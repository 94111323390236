import {
    List,
    TextField,
    Datagrid,
    NumberField,
    EditButton,
    DateField,
    ListProps
} from "react-admin";
import React from "react";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import BookingFilter from "./BookingFilter";
import DocumentationLink from "../shared/DocumentationLink";

const BookingList = (props: ListProps) => (
    <>
        <List filters={<BookingFilter />} {...props} hasCreate={false}>
            <Datagrid rowClick="edit">
                <LinkToReferenceField
                    source="reference"
                    sourceId="id"
                    reference="Booking"
                    label="Reference"
                />
                <LinkToReferenceField
                    source="customer.name"
                    sourceId="customer.id"
                    reference="Customer"
                    label="Customer"
                />
                <TextField source="bookingStatus" />
                <DateField
                    source="activityStartsAt"
                    label="Starts at"
                    showTime
                    locales="en-UK"
                />
                <NumberField source="total" />
                <EditButton />
            </Datagrid>
        </List>
        <DocumentationLink link="https://docs.google.com/document/d/1whBHJmqkdfGRnHaMZcPptx-11lJtN1i4n_6vsn6-Rpg/edit" />
    </>
);

export default BookingList;
