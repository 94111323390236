import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import "../shared/styles/reset.css";
import "../shared/styles/schedule.css";
import TimeLine from "../shared/WeekView/TimeLine";
import ActivityInstanceTemplateModal from "./ActivityInstanceTemplateModal";
import DayScheduleTemplate from "./DayScheduleTemplate";
import { WeekTemplateContext } from "./WeekTemplateContext";

const WeekTemplate = ({
    resourceId,
    weekTemplateId,
    isUpdateMode = false
}: IWeekTemplateProps) => {
    const {
        weekTemplate,
        setWeekTemplateId,
        setResourceId,
        setWeekTemplateName,
        setWeekTemplate,
        createWeekTemplate,
        updateWeekTemplate,
        deleteWeekTemplate,
        shouldRedirect,
        createdWeekTemplateId
    } = useContext(WeekTemplateContext);

    if (resourceId) {
        setResourceId(resourceId);
    } else if (weekTemplateId) {
        setWeekTemplateId(weekTemplateId);
    }

    const title = weekTemplate
        ? `Week template "${weekTemplate.name}" for resource "${weekTemplate.resource.name}"`
        : "";

    if (!weekTemplate || !weekTemplate.dayScheduleTemplates) {
        return null;
    }

    const { dayScheduleTemplates } = weekTemplate;

    // calc height
    const timelineCount = weekTemplate.timeLineTimes.length;
    const height = 50 * timelineCount + 50;

    return shouldRedirect ? (
        createdWeekTemplateId ? (
            <Redirect to={`/c/week-template/${createdWeekTemplateId}`} />
        ) : (
            <Redirect to={`/Resource/${weekTemplate.resource.id}`} />
        )
    ) : (
        <Paper>
            <Typography style={{ margin: "50px 115px" }}>
                <div style={{ float: "right", margin: "2% 10% 5% 0" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        href={`/#/Resource/${weekTemplate.resource.id}`}
                        data-testid="return-to-resource-button"
                    >
                        Return to Resource
                    </Button>
                </div>
                <Typography variant="headline" gutterBottom>
                    {title}
                </Typography>
                <TextField
                    label="Week template name"
                    value={weekTemplate.name}
                    data-testid="week-template-name-input"
                    onChange={(event) => {
                        setWeekTemplateName(event.target.value);
                    }}
                    margin="normal"
                />
            </Typography>
            <div className="cd-schedule js-full">
                <TimeLine times={weekTemplate.timeLineTimes} />
                <div className="events" style={{ height: `${height}px` }}>
                    <ul>
                        {dayScheduleTemplates.map((d) => {
                            return (
                                <DayScheduleTemplate
                                    key={d.dayOfWeek}
                                    dayOfWeek={d.dayOfWeek}
                                    dayOfWeekName={d.dayOfWeekName}
                                    activityInstanceTemplates={
                                        d.activityInstanceTemplates || []
                                    }
                                    dailyStartTime={
                                        weekTemplate.resource.dailyStartTime
                                    }
                                    timelinePrecision={
                                        weekTemplate.resource
                                            .greatestCommonDivisorOfActivitiesDuration
                                    }
                                    timelineCount={timelineCount}
                                />
                            );
                        })}
                    </ul>
                </div>
                <ActivityInstanceTemplateModal />
            </div>
            <div style={{ float: "right", margin: "2% 10% 5% 0" }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setWeekTemplate(null)}
                    style={{ marginRight: 20 }}
                >
                    Clear
                </Button>
                {isUpdateMode ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            deleteWeekTemplate();
                        }}
                        style={{ marginRight: 20 }}
                    >
                        Delete
                    </Button>
                ) : null}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={
                        isUpdateMode ? updateWeekTemplate : createWeekTemplate
                    }
                >
                    Save
                </Button>
            </div>
        </Paper>
    );
};

interface IWeekTemplateProps {
    resourceId?: string;
    weekTemplateId?: string;
    isUpdateMode: boolean;
}

export default WeekTemplate;
