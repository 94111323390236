import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { ISelectOption } from "./interfaces/ISelectOption";

const SelectInput = ({
    label,
    options,
    isDisabled,
    selectedValue = "",
    name = "",
    onChangeHandler = (e) => {}
}: ISelectTimeProps) => {
    return (
        <FormControl fullWidth>
            {label ? <InputLabel>{label}</InputLabel> : null}
            <Select
                disabled={isDisabled}
                value={selectedValue}
                onChange={(event) => {
                    onChangeHandler(event.target.value);
                }}
                data-testid={`${name}-select-input`}
            >
                {options.map((option, index) => {
                    return (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            disabled={option.isDisabled}
                            data-testid={`${name}-select-option-${index}`}
                        >
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export interface ISelectTimeProps {
    label: string;
    options: ISelectOption[];
    isDisabled: boolean;
    selectedValue?: string;
    name?: string;
    onChangeHandler?: (event: any) => void;
}

export default SelectInput;
