import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { IActivity } from "../../../../generated/dataInterfaces";

export const ACTIVITY_QUERY = gql`
    query activity($activityId: ID!) {
        activity(where: { id: $activityId }) {
            id
            name
            defaultSlotLengthMinutes
        }
    }
`;

const useGetActivity = (activityId: string): IActivity | null => {
    const { data } = useQuery<{ activity: IActivity }, { activityId: string }>(
        ACTIVITY_QUERY,
        { skip: !activityId, variables: { activityId } }
    );

    return data && data.activity ? data.activity : null;
};

export default useGetActivity;
