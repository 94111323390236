import React from "react";
import {
    BooleanInput,
    Create,
    CreateProps,
    NumberInput,
    regex,
    required,
    SimpleForm,
    TextInput
} from "react-admin";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import DocumentationLink from "../shared/DocumentationLink";

const FacilityCreate = (props: CreateProps) => (
    <>
        <Create title="Create Facility" {...props}>
            <SimpleForm redirect="edit">
                <TextInput
                    source="name"
                    validate={[required()]}
                    fullWidth
                    helperText="The name for this Facility. It will not currently be displayed anywhere public"
                />
                <TextInput
                    source="description"
                    validate={[required()]}
                    helperText="Administrative description for this facility"
                />
                <DisabledHiddenOrganisationIdInput />
                <TextInput
                    source="nominalCode"
                    helperText="The nominal code that will be used for all resources/activities in this facility (unless overridden)"
                />
                <TextInput
                    source="stub"
                    fullWidth
                    validate={[
                        regex(
                            /^(\w+)(-?(\w+)?)+$/,
                            "Letters, numbers and dashes (-) only"
                        ),
                        required()
                    ]}
                    helperText="The last part of the URL for this facility. Should be in the form name-of-facility. Must be unique amongst facilities"
                />
                <NumberInput
                    source="ordering"
                    helperText="Order in which the facility will be displayed"
                    defaultValue={0}
                />
                <BooleanInput
                    source="isAvailable"
                    defaultValue={true}
                    validate={[required()]}
                    helperText="If not set, this resource will not be displayed anywhere (website or mid-admin)"
                />
                <BooleanInput
                    source="isHiddenFromPublic"
                    defaultValue={false}
                    validate={[required()]}
                    helperText="If set, none of the resources or activities will be available on the website"
                />
                <BooleanInput
                    source="isShownOnDefaultView"
                    defaultValue={false}
                    helperText="If set, this will not be available on the default view"
                />
            </SimpleForm>
        </Create>
        <DocumentationLink link="https://docs.google.com/document/d/1LJovGOCPl_oUGPzH17hJiYpFbZgy01Z-g8mL5tSrKLU/edit" />
    </>
);

export default FacilityCreate;
