import React from "react";
import { CardActions } from "react-admin";
import CreateReferencedResourceButton from "../shared/CreateReferencedResourceButton";

const CustomerEditActions = ({
    data,
    currentUrl,
    ...otherProps
}: IEditActionsProps) =>
    data ? (
        <CardActions>
            <CreateReferencedResourceButton
                reference="Credential"
                currentUrl={currentUrl}
                mappings={[["id", "customer.id"]]}
                record={data}
                {...otherProps}
            />
            <CreateReferencedResourceButton
                reference="CustomerBenefit"
                currentUrl={currentUrl}
                mappings={[["id", "customer.id"]]}
                record={data}
                {...otherProps}
            />
            <CreateReferencedResourceButton
                reference="CustomerMembership"
                currentUrl={currentUrl}
                mappings={[["id", "customer.id"]]}
                record={data}
                {...otherProps}
            />
            <CreateReferencedResourceButton
                reference="Booking"
                currentUrl={currentUrl}
                mappings={[
                    ["id", "customer.id"],
                    ["credentials[0].id", "madeByCredential.id"]
                ]}
                record={data}
                {...otherProps}
            />
        </CardActions>
    ) : null;

interface IEditActionsProps {
    data?: { id: string };
    currentUrl: string;
    otherProps?: any;
}

export default CustomerEditActions;
