import { useContext } from "react";
import { ModalContext, IModalContext } from "./ModalContext";

interface IUseModal {
    (): {
        isOpen: boolean;
        openModal: () => void;
        closeModal: () => void;
        toggleModal: () => void;
        setModalTitle: (title: string) => void;
        setModalBodyComponent: (component: React.FC) => void;
    };
}

const useModal: IUseModal = () => {
    const modalContext = useContext<IModalContext>(ModalContext);

    if (!modalContext)
        throw new Error(
            "Modal context is undefined, it might not be wrapped in a provider."
        );

    const {
        isOpen,
        openModal,
        closeModal,
        toggleModal,
        setModalTitle,
        setModalBodyComponent
    } = modalContext;

    return {
        isOpen,
        openModal,
        closeModal,
        toggleModal,
        setModalTitle,
        setModalBodyComponent
    };
};

export default useModal;
