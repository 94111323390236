import React from "react";
import { Datagrid, EditButton, List, ListProps, TextField } from "react-admin";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import DocumentationLink from "../shared/DocumentationLink";

const RoleList = (props: ListProps) => (
    <>
        <List {...props}>
            <Datagrid>
                <LinkToReferenceField
                    source="name"
                    sourceId="id"
                    reference="Role"
                    label="Name"
                />
                <TextField source="description" />
                <EditButton />
            </Datagrid>
        </List>
        <DocumentationLink link="https://docs.google.com/document/d/1ULT4EOozceyAscKhurR6z0Z_-XsDwrGzQpp3Fe86Nps/edit" />
    </>
);

export default RoleList;
