import React, { createContext, useReducer } from "react";
import Modal from "./Modal";

export const ModalContext = createContext<IModalContext | undefined>(undefined);

const modalReducer: React.Reducer<IState, IAction> = (state, action) => {
    switch (action.type) {
        case ActionType.OpenModal: {
            return { ...state, isOpen: true };
        }
        case ActionType.CloseModal: {
            return { ...state, isOpen: false };
        }
        case ActionType.ToggleModal: {
            return { ...state, isOpen: !state.isOpen };
        }
        case ActionType.SetModalTitle: {
            return { ...state, title: action.payload };
        }
        case ActionType.SetModalBodyComponent: {
            return { ...state, BodyComponent: action.payload };
        }
        default: {
            throw new Error(`Unhandled type: ${action.type}`);
        }
    }
};

export const ModalContextProvider = ({ children }) => {
    const initialState: IState = {
        isOpen: false,
        title: "Modal",
        BodyComponent: () => null
    };
    const [state, dispatch] = useReducer<React.Reducer<IState, IAction>>(
        modalReducer,
        initialState
    );

    const { isOpen, title, BodyComponent } = state;

    const openModal = () => {
        dispatch({ type: ActionType.OpenModal });
    };

    const closeModal = () => {
        dispatch({ type: ActionType.CloseModal });
    };

    const toggleModal = () => {
        dispatch({ type: ActionType.ToggleModal });
    };

    const setModalTitle = (title) => {
        dispatch({ type: ActionType.SetModalTitle, payload: title });
    };

    const setModalBodyComponent = (component) => {
        dispatch({
            type: ActionType.SetModalBodyComponent,
            payload: component
        });
    };

    const modalContext: IModalContext = {
        isOpen,
        openModal,
        closeModal,
        toggleModal,
        setModalTitle,
        setModalBodyComponent
    };

    return (
        <ModalContext.Provider value={modalContext}>
            {children}
            <Modal
                isOpen={isOpen}
                closeModal={closeModal}
                title={title}
                BodyComponent={BodyComponent}
            />
        </ModalContext.Provider>
    );
};

export enum ActionType {
    OpenModal = "OPEN_MODAL",
    CloseModal = "CLOSE_MODAL",
    ToggleModal = "TOGGLE_MODAL",
    SetModalTitle = "SET_MODAL_TITLE",
    SetModalBodyComponent = "SET_MODAL_BODY_COMPONENT"
}

export interface IModalContext {
    isOpen: boolean;
    setModalTitle: (title: string) => void;
    setModalBodyComponent: (component: React.FC) => void;
    openModal: () => void;
    closeModal: () => void;
    toggleModal: () => void;
}

export interface IState {
    isOpen: boolean;
    title: string;
    BodyComponent: React.FC;
}

export interface IAction {
    type: ActionType;
    payload?: any;
}
