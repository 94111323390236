import { Typography } from "@material-ui/core";
import RichTextInput from "ra-input-rich-text";
import React from "react";
import {
    ArrayField,
    Datagrid,
    DateField,
    Edit,
    EditProps,
    FormTab,
    FunctionField,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
    Record
} from "react-admin";
import DeleteOneActivityInstanceButton from "./DeleteOneActivityInstanceButton";
import DocumentationLink from "../shared/DocumentationLink";
import LinkToReferenceField from "../shared/LinkToReferenceField";

import CreateActivityInstancesWithRRuleForm from "./CreateActivityInstancesWithRRuleForm";
import DeleteAllActivityInstancesButton from "./DeleteAllActivityInstancesButton";

const PackageEdit = (props: EditProps) => {
    return (
        <>
            <Edit title="Edit a Package" {...props}>
                <TabbedForm redirect="edit">
                    <FormTab label="summary">
                        <TextInput
                            source="name"
                            validate={[required()]}
                            fullWidth
                            helperText={`The name of this Package is just for your own reference in the admin.
                        A comprehensive name would be helpful, for example:
                        Adult and Child Swimming lessons 20/02/20 for 10 weeks at 10:30`}
                        />
                        <ReferenceInput
                            label="Packaged activity"
                            source="activity.id"
                            reference="Activity"
                            sort={{ field: "name", order: "ASC" }}
                            perPage={200}
                            filter={{ isPackaged: true }}
                            helperText="The packaged activity that this package belongs to."
                        >
                            <SelectInput optionText="name" />
                        </ReferenceInput>

                        <RichTextInput
                            label={`The public description for this Package. 
                            This will be displayed to the user once they select the date/time. 
                            `}
                            source="description"
                            validate={[required()]}
                        />

                        <CreateActivityInstancesWithRRuleForm />
                    </FormTab>
                    <FormTab label="Activity Instances">
                        <Typography
                            style={{ paddingTop: "30px", width: "100%" }}
                            variant="body1"
                            gutterBottom
                            align="left"
                        >
                            The activity instances included in this package. If
                            you want to re-create them to add more dates, or
                            modify them you will need to delete all. You will be
                            able to delete them all ONLY if no bookings have
                            been made yet.
                        </Typography>
                        <ArrayField source="activityInstances">
                            <Datagrid>
                                <DateField
                                    source="daySchedule.date"
                                    label="Starts at"
                                    locales="en-GB"
                                />
                                <TextField source="startTime" />
                                <TextField source="endTime" />
                                <FunctionField
                                    label="Price per Booking"
                                    render={(record?: Record) => {
                                        return record && record.pricePerBooking
                                            ? `£ ${record.pricePerBooking.toFixed(
                                                  2
                                              )}`
                                            : "";
                                    }}
                                />

                                <FunctionField
                                    label="Bookings"
                                    render={(record?: Record): string => {
                                        return record && record.bookings
                                            ? `${record.bookings.length}`
                                            : "";
                                    }}
                                />
                                <DeleteOneActivityInstanceButton />
                            </Datagrid>
                        </ArrayField>

                        <DeleteAllActivityInstancesButton />
                    </FormTab>
                    <FormTab label="Bookings">
                        <ArrayField source="bookings">
                            <Datagrid>
                                <LinkToReferenceField
                                    source="reference"
                                    reference="Booking"
                                />
                                <TextField source="packageReference" />
                            </Datagrid>
                        </ArrayField>
                    </FormTab>
                </TabbedForm>
            </Edit>
            <DocumentationLink link="https://docs.google.com/document/d/1EKIUgQBv9K-sISQbTOs6dEd3tZXsca9FguLMy3xIO6g/edit" />
        </>
    );
};

export default PackageEdit;
