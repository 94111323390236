import { IWeekTemplateWithTimeLine } from "./interfaces";

import {
    IDayScheduleTemplateInput,
    IActivityInstanceTemplateInput
} from "../../../../generated/dataInterfaces";

const createDayScheduleTemplateInputs = (
    weekTemplate: IWeekTemplateWithTimeLine
) => {
    const dayScheduleTemplates: Array<IDayScheduleTemplateInput> =
        weekTemplate.dayScheduleTemplates.map((d) => {
            return {
                dayOfWeek: d.dayOfWeek,
                activityInstanceTemplates: d.activityInstanceTemplates
                    ? (d.activityInstanceTemplates.map((a) => {
                          return {
                              activityId: a.activity.id,
                              startTime: a.startTime,
                              endTime: a.endTime,
                              maxCapacity: a.maxCapacity,
                              pricePerBooking: a.pricePerBooking
                          };
                      }) as Array<IActivityInstanceTemplateInput>)
                    : []
            };
        });
    return dayScheduleTemplates;
};

export default createDayScheduleTemplateInputs;
