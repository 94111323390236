import { gql } from "@apollo/client";
import { customerMembershipCommonFields } from "./customerMembershipFragment";

const customerMembershipWithPhotoFragment = gql`
    fragment customerMembershipWithPhotoFragment on CustomerMembership {
        ${customerMembershipCommonFields}
        photo {
            base64
        }
    }
`;

export default customerMembershipWithPhotoFragment;
