import InputAdornment from "@material-ui/core/InputAdornment";
import React from "react";
import {
    BooleanInput,
    Create,
    CreateProps,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import DocumentationLink from "../shared/DocumentationLink";

const ExtraCreate = (props: CreateProps) => (
    <>
        <Create title="Create an Extra" {...props}>
            <SimpleForm redirect="edit">
                <TextInput
                    source="name"
                    validate={[required()]}
                    fullWidth
                    helperText="The name of this Extra"
                />
                <ReferenceInput
                    label="Resource"
                    source="resource.id"
                    reference="Resource"
                    sort={{ field: "name", order: "ASC" }}
                    perPage={200}
                    helperText="Which Resource does this Extra belong to?"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput
                    source="description"
                    validate={[required()]}
                    helperText="The public description for this Extra"
                />
                <NumberInput
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                        )
                    }}
                    source="price"
                    validate={[required()]}
                    helperText="The cost of a this Extra. Enter as ##.## e.g. 10.00"
                />
                <BooleanInput
                    source="isAvailable"
                    helperText="If not set, this resource will not be displayed anywhere (website or mid-admin)"
                />
            </SimpleForm>
        </Create>
        <DocumentationLink link="https://docs.google.com/document/d/1lt3qXtq1WxtjygrZ6zVmUWoHr4vPWC5t5aJXYP4Qx6I/edit" />
    </>
);

export default ExtraCreate;
