import React from "react";
import {
    BooleanField,
    Datagrid,
    EditButton,
    List,
    ListProps,
    TextField
} from "react-admin";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import CustomerMembershipFilter from "./CustomerMembershipFilter";

const CustomerMembershipList = (props: ListProps) => (
    <List filters={<CustomerMembershipFilter />} {...props}>
        <Datagrid>
            <TextField source="name" />
            <LinkToReferenceField
                source="membership.name"
                sourceId="membership.id"
                reference="Membership"
                label="Membership"
            />

            <LinkToReferenceField
                source="customer.name"
                sourceId="customer.id"
                reference="Customer"
                label="Customer"
            />

            <BooleanField source="isActive" label="Active?" />

            <EditButton />
        </Datagrid>
    </List>
);

export default CustomerMembershipList;
