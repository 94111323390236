import { FormControl, InputLabel } from "@material-ui/core";
import enGB from "date-fns/locale/en-GB";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("en-GB", enGB);

const DateFilter = ({ label, value, onChangeHandler }: IDateFilterProps) => {
    return (
        <FormControl fullWidth>
            <InputLabel shrink={true}>{label}</InputLabel>
            <div className="react-date-container">
                <DatePicker
                    selected={value}
                    locale="en-GB"
                    onChange={onChangeHandler}
                    dateFormat="dd/MM/yyyy"
                />
            </div>
        </FormControl>
    );
};

interface IDateFilterProps {
    label: string;
    value: Date;
    onChangeHandler: (
        date: Date,
        event: React.SyntheticEvent<any, Event>
    ) => void;
}

export default DateFilter;
