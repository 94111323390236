import { gql } from "@apollo/client";

const dayScheduleFragment = gql`
    fragment daySchedule on DaySchedule {
        id
        date
        resource {
            id
            name
        }
        activityInstances {
            id
            startTime
            endTime
            quantityAvailable
            activity {
                id
                name
            }
        }
        createdAt
        updatedAt
    }
`;

export default dayScheduleFragment;
