import React from "react";
import { Route } from "react-router-dom";
import CreateWeekTemplate from "../Custom/CreateWeekTemplate";
import UpdateWeekTemplate from "../Custom/UpdateWeekTemplate";
import WeekScheduler from "../Custom/WeekScheduler";
import Dashboard from "../Custom/Dashboard";

export default [
    <Route exact key="Dashboard" path="/" component={Dashboard} />,
    <Route exact key="/c/reports" path="/c/reports" component={Dashboard} />,
    <Route
        exact
        key="/c/resource/:resourceId/create-week-template"
        path="/c/resource/:resourceId/create-week-template"
        component={CreateWeekTemplate}
    />,
    <Route
        exact
        key="/c/week-template/:weekTemplateId"
        path="/c/week-template/:weekTemplateId"
        component={UpdateWeekTemplate}
    />,
    <Route
        exact
        key="/c/resource/:resourceId/week-scheduler"
        path="/c/resource/:resourceId/week-scheduler"
        component={WeekScheduler}
    />
];
