import React from "react";
import {
    BooleanInput,
    Create,
    CreateProps,
    email,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import DisabledHiddenOrganisationIdInput from "../Custom/DisabledHiddenOrganisationIdInput";
import DocumentationLink from "../shared/DocumentationLink";

const UserCreate = (props: CreateProps) => (
    <>
        <Create title="Create a User" {...props}>
            <SimpleForm redirect="edit">
                <TextInput source="name" validate={[required()]} />
                <TextInput
                    source="emailAddress"
                    validate={[required(), email()]}
                />
                <TextInput
                    source="password"
                    type="password"
                    validate={[required()]}
                />
                <BooleanInput source="isDisabled" defaultValue={false} />
                <BooleanInput source="isLockedOut" defaultValue={false} />
                <DisabledHiddenOrganisationIdInput />
                <ReferenceInput
                    label="Role"
                    source="role.id"
                    reference="Role"
                    sort={{ field: "name", order: "ASC" }}
                    perPage={200}
                    validate={[required()]}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
        <DocumentationLink link="https://docs.google.com/document/d/19EytUDi9vjY7nTtQRMlBGFgfc3RaQPFYIvuiUh05Zf0/edit" />
    </>
);

export default UserCreate;
