import Button from "@material-ui/core/Button";
import React from "react";
import "../../shared/styles/reset.css";
import "../../shared/styles/schedule.css";
import TimeLine from "../../shared/WeekView/TimeLine";
import useWeekScheduler from "../useWeekScheduler";
import DaySchedule from "./DaySchedule";

const WeekSchedule = () => {
    const {
        resource,
        weekSchedule,
        timeLineTimes,
        saveWeekSchedule,
        reloadWeekSchedule
    } = useWeekScheduler();

    // calc height
    const timelineCount = timeLineTimes.length;
    const height = 50 * timelineCount + 50;

    return weekSchedule && resource && timeLineTimes ? (
        <div>
            <div className="cd-schedule js-full" data-testid="week-scheduler">
                <TimeLine times={timeLineTimes} />
                <div className="events" style={{ height: `${height}px` }}>
                    <ul>
                        {weekSchedule.map((d, index) => {
                            return (
                                <DaySchedule
                                    key={d.id}
                                    id={d.id}
                                    dayOfWeek={index + 1}
                                    date={d.date}
                                    activityInstances={
                                        d.activityInstances || []
                                    }
                                    dailyStartTime={resource.dailyStartTime}
                                    times={timeLineTimes}
                                    timelinePrecision={
                                        resource.greatestCommonDivisorOfActivitiesDuration
                                    }
                                    timelineCount={timelineCount}
                                    reloadWeekSchedule={reloadWeekSchedule}
                                />
                            );
                        })}
                    </ul>
                </div>
            </div>
            <div style={{ float: "right", margin: "2% 10% 5% 0" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        saveWeekSchedule();
                    }}
                    data-testid="save-week-schedule-button"
                >
                    Save
                </Button>
            </div>
        </div>
    ) : null;
};

export default WeekSchedule;
