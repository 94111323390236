import { createMuiTheme } from "@material-ui/core/styles";
import orange from "@material-ui/core/colors/orange";

const lesOrmesTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#6C773F"
        },
        secondary: {
            main: "#444444"
        },
        error: orange,
        contrastThreshold: 3,
        tonalOffset: 0.2
    },
    overrides: {
        MuiButton: {
            // override the styles of all instances of this component
            root: {
                // Name of the rule
                color: "black" // Some CSS
            }
        }
    }
});

export default lesOrmesTheme;
