import React from "react";
import {
    BooleanInput,
    Create,
    CreateProps,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import { IActivity } from "../../generated/dataInterfaces";
import DocumentationLink from "../shared/DocumentationLink";

const PackageCreate = (props: CreateProps) => (
    <>
        <Create title="Create a Package" {...props}>
            <SimpleForm redirect="edit">
                <TextInput
                    source="name"
                    validate={[required()]}
                    fullWidth
                    helperText="The name of this package. It is never displayed to the user."
                />
                <ReferenceInput
                    label="Packaged activity"
                    source="activity.id"
                    reference="Activity"
                    helperText="The packaged activity that this package belongs to."
                    filter={{ isPackaged: true }}
                    sort={{ field: "name", order: "ASC" }}
                    perPage={200}
                >
                    <SelectInput
                        optionText={(activity: IActivity) =>
                            `${activity.name} - ${activity.resource.name}${
                                activity.resource.resourceGroups &&
                                activity.resource.resourceGroups.length > 0
                                    ? ` - ${activity.resource.resourceGroups[0].name}`
                                    : ""
                            }`
                        }
                    />
                </ReferenceInput>

                <TextInput
                    source="description"
                    validate={[required()]}
                    fullWidth
                    helperText="The public description for this Package. This will be displayed to the user once they select the date/time. You will be able to apply formatting on the nexxt screen."
                />

                <BooleanInput
                    source="mustPreventBookingAfterFirstInstanceIsPast"
                    label="Prevent booking after the first instance has passed?"
                    fullWidth
                    defaultValue={false}
                />
            </SimpleForm>
        </Create>
        <DocumentationLink link="https://docs.google.com/document/d/1EKIUgQBv9K-sISQbTOs6dEd3tZXsca9FguLMy3xIO6g/edit" />
    </>
);

export default PackageCreate;
