import { IActivityInstanceTemplate } from "../../../../generated/dataInterfaces";
import { addMinutesToTimeOfDay } from "@les-ormes/lib";
import { IWeekTemplateWithTimeLine } from "./interfaces";
import timelineUtil, { ITimeInterval } from "../../utils/timelineUtil";
import { toast } from "react-toastify";
import produce from "immer";

const addActivityInstanceToWeekTemplate: IAddActivityInstanceToWeekTemplate = (
    weekTemplate,
    activityId,
    dayOfWeek,
    time,
    maxCapacity,
    pricePerBooking
) => {
    const dayScheduleTemplate =
        weekTemplate.dayScheduleTemplates[dayOfWeek - 1];
    const activityInstanceTemplates =
        dayScheduleTemplate.activityInstanceTemplates
            ? dayScheduleTemplate.activityInstanceTemplates
            : [];
    const { resource } = weekTemplate;

    const activity = resource.activities.find((a) => a.id === activityId);

    const endTime = addMinutesToTimeOfDay(
        time,
        activity.defaultSlotLengthMinutes
    );

    const activityInstanceTemplate: IActivityInstanceTemplate = {
        id: "",
        dayScheduleTemplate: null,
        activity,
        startTime: time,
        endTime,
        maxCapacity,
        pricePerBooking
    };

    const currentBusyStartEndTimes = activityInstanceTemplates.map<
        ITimeInterval<string>
    >((a) => {
        return {
            startTime: a.startTime,
            endTime: a.endTime
        };
    });

    const canActivityInstanceTemplateBeAdded =
        timelineUtil.checkCanAllocateTimeIntervalInTimeline(
            currentBusyStartEndTimes,
            time,
            endTime,
            resource.dailyEndTime
        );

    if (canActivityInstanceTemplateBeAdded) {
        const newWeekTemplate = produce(weekTemplate, (draftWeekTemplate) => {
            if (
                draftWeekTemplate.dayScheduleTemplates[dayOfWeek - 1]
                    .activityInstanceTemplates === null
            ) {
                draftWeekTemplate.dayScheduleTemplates[
                    dayOfWeek - 1
                ].activityInstanceTemplates = [];
            }
            draftWeekTemplate.dayScheduleTemplates[
                dayOfWeek - 1
            ].activityInstanceTemplates.push(activityInstanceTemplate);
        });

        return newWeekTemplate;
    } else {
        toast(`${activity.name} overlaps, it cannot be added.`);
    }

    return weekTemplate;
};

export interface IAddActivityInstanceToWeekTemplate {
    (
        weekTemplate: IWeekTemplateWithTimeLine,
        activityId: string,
        dayOfWeek: number,
        time: string,
        maxCapacity: number,
        pricePerBooking: number
    ): IWeekTemplateWithTimeLine;
}

export default addActivityInstanceToWeekTemplate;
