import React from "react";
import {
    ArrayField,
    ChipField,
    Datagrid,
    EditButton,
    List,
    ListProps,
    NumberField,
    SingleFieldList,
    TextField
} from "react-admin";
import DocumentationLink from "../shared/DocumentationLink";

const MembershipGroupList = (props: ListProps) => (
    <>
        <List filter={{ isDeleted_not: true }} {...props}>
            <Datagrid>
                <TextField source="name" />
                <TextField source="stub" label="/membership/group/stub" />
                <NumberField source="ordering" label="Ordering" />
                <ArrayField source="memberships" label="Memberships">
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
                <EditButton />
            </Datagrid>
        </List>
        <DocumentationLink link="https://docs.google.com/document/d/1K3RIrU7ZsOUukrYVZ3_DZfiD1mQy9tIf34cHob5QPSk/edit" />
    </>
);

export default MembershipGroupList;
