import React from "react";
import { render } from "react-dom";
import App from "./components/App";
import { AuthContextProvider } from "./components/App/AuthContext";

render(
    <AuthContextProvider>
        <App />
    </AuthContextProvider>,
    document.getElementById("root") as HTMLElement
);
