import InputAdornment from "@material-ui/core/InputAdornment";
import React from "react";
import {
    BooleanInput,
    Create,
    NumberInput,
    ReferenceInput,
    regex,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    CreateProps
} from "react-admin";
import { ColorInput } from "react-admin-color-input";
import DocumentationLink from "../shared/DocumentationLink";

const ActivityCreate = (props: CreateProps) => (
    <>
        <Create title="Create an Activity" {...props}>
            <SimpleForm redirect="edit">
                <TextInput
                    source="name"
                    validate={[required()]}
                    fullWidth
                    helpertext="The public name of this Activity"
                />
                <ReferenceInput
                    label="Resource"
                    source="resource.id"
                    reference="Resource"
                    helpertext="Which Resource does this Activity belong to?"
                    sort={{ field: "name", order: "ASC" }}
                    perPage={200}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <TextInput
                    source="keyInformation"
                    fullWidth
                    helpertext="The text to be displayed below the activity name e.g. "
                />

                <TextInput source="description" validate={[required()]} />

                <TextInput
                    source="imageUrl"
                    validate={[required()]}
                    fullWidth
                    helpertext="For now, images should be uploaded on https://www.lesormesjersey.co.uk/umbraco and the full URL entered here"
                />

                <NumberInput
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                        )
                    }}
                    source="defaultPricePerBooking"
                    validate={[required()]}
                    helpertext="The default cost of a single booking, when adding to the schedule. Enter as ##.## e.g. 10.00"
                />
                <NumberInput
                    source="defaultSlotLengthMinutes"
                    label="Default slot length"
                    defaultValue={60}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                minutes
                            </InputAdornment>
                        )
                    }}
                    helpertext="The default length, when adding to a schedule"
                />
                <NumberInput
                    source="quantityPerBooking"
                    defaultValue={1}
                    helpertext="The number of people per ticket"
                />
                <NumberInput
                    source="minimumQuantityPerBooking"
                    label="Min number of tickets per booking"
                    defaultValue={1}
                    helpertext="The minimum number tickets that can be purchased at one time"
                />
                <NumberInput
                    source="bookMinDaysInAdvance"
                    helpertext="How many days in advance is the first option to book? 0 means 'today'"
                    defaultValue={0}
                />
                <NumberInput
                    source="bookMaxDaysInAdvance"
                    helpertext="How many days in advance is the last option to book? e.g. 21 for 3 weeks, 28 for 4 weeks, etc"
                    defaultValue={14}
                />
                <TextInput
                    source="stub"
                    fullWidth
                    validate={[
                        regex(
                            /^(\w+)(-?(\w+)?)+$/,
                            "Letters, numbers and dashes (-) only"
                        ),
                        required()
                    ]}
                    helpertext="The last part of the URL for this activity. Should be in the form resource-name-of-activity. Must be unique amongst activities"
                />
                <TextInput
                    source="needHelpUrl"
                    fullWidth
                    helpertext="The URL for the FAQs, etc. Will open in a new tab"
                />
                <TextInput
                    source="ticketDescription"
                    fullWidth
                    helpertext="The text to appear alongside the booking quantity e.g. 'Standard Ticket`"
                />

                <TextInput
                    source="bookingConfirmEmailText"
                    helpertext="Text included on the booking confirmation email"
                />
                <TextInput
                    source="checkoutCompleteText"
                    helpertext="Text included on the booking page"
                />
                <ColorInput
                    label="Schedule background colour"
                    defaultValue="#dddddd"
                    source="colorScheme"
                    helpertext="The colour to be used on the schedule"
                />
                <NumberInput
                    source="ordering"
                    helpertext="The ordering of the Activity, in listings"
                    defaultValue={0}
                />
                <BooleanInput
                    source="isAvailable"
                    defaultValue={true}
                    helpertext="If not set, this resource will not be displayed anywhere (website or mid-admin)"
                />
                <BooleanInput
                    source="isHiddenFromPublic"
                    defaultValue={false}
                    helpertext="If set, this will not be available on the website"
                />
            </SimpleForm>
        </Create>
        <DocumentationLink link="https://docs.google.com/document/d/1mpbNO20jYds6fOIjkJA93pXq_ph9deSYW8iidF4AwEQ/edit" />
    </>
);

export default ActivityCreate;
