import Button from "@material-ui/core/Button";
import React from "react";
import useDashboard from "./useDashboard";

const RequestReportButton = () => {
    const { requestReport, setIsLoading, ...filters } = useDashboard();

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={() => {
                setIsLoading();
                requestReport(filters);
            }}
        >
            Generate report
        </Button>
    );
};

export default RequestReportButton;
