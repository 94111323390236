import React from "react";
import {
    BooleanField,
    Datagrid,
    List,
    TextField,
    EditButton,
    ListProps
} from "react-admin";
import LinkToReferenceField from "../shared/LinkToReferenceField";
import { Typography } from "@material-ui/core";

const CouponList = (props: ListProps) => (
    <>
        <List {...props}>
            <Datagrid>
                <LinkToReferenceField
                    source="name"
                    sourceId="id"
                    reference="Coupon"
                    label="Name"
                />

                <LinkToReferenceField
                    source="discount.name"
                    sourceId="discount.id"
                    reference="Discount"
                    label="Discount"
                />
                <TextField source="name" />
                <TextField source="couponCode" />
                <BooleanField source="isSingleUse" />
                <BooleanField source="isActive" />
                <EditButton />
            </Datagrid>
        </List>
        <Typography
            style={{ paddingTop: "30px" }}
            variant="body1"
            gutterBottom
            align="left"
        >
            Docs{" "}
            <a
                target="blank"
                href="https://docs.google.com/document/d/1nv-xG5PXpiSjqpMD-Mlq0I2_5lV9FF-HtKv4PUQYVuY/edit"
            >
                Link
            </a>
        </Typography>
    </>
);

export default CouponList;
