import React from "react";

const TimeLine = ({ times }) => {
    const height = 50 * times.length;

    // const style = ` .cd-schedule .events {
    //     height: ${height + 50}px;
    // }

    // .cd-schedule .timeline ul {
    //     height: ${height}px;
    // }

    // .cd-schedule .events .events-group > ul {
    //     height: ${height}px !important;
    // }`;
    return (
        <>
            {/* <style>{style}</style> */}
            <div className="timeline">
                <ul style={{ height: `${height}px`, marginTop: `50px` }}>
                    {times.map((time) => (
                        <li key={time}>{time}</li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default TimeLine;
