import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useContext, useEffect, useState } from "react";
import { IModalContext, ModalContext } from "../ModalContext";
import { WeekTemplateContext } from "../WeekTemplateContext";
import { IWeekTemplateContext } from "../WeekTemplateContext/interfaces";
import SelectActivity from "./SelectActivity";
import SelectTime from "./SelectTime";
import { difference, dropRight } from "lodash";
import timelineUtil from "../../utils/timelineUtil";

const ActivityInstanceTemplateModal = (props) => {
    const {
        closeModal,
        isModalOpen,
        selectedDay,
        selectedDayName,
        isEditMode,
        selectedActivityInstanceTemplate
    } = useContext(ModalContext) as IModalContext;

    const {
        weekTemplate,
        addActivityInstance,
        deleteActivityInstance
    } = useContext(WeekTemplateContext) as IWeekTemplateContext;

    const {
        activities,
        greatestCommonDivisorOfActivitiesDuration
    } = weekTemplate.resource;

    const { timeLineTimes, dayScheduleTemplates } = weekTemplate; // TODO: refine

    const selectedDayScheduleTemplate = dayScheduleTemplates[selectedDay - 1];

    const startEndTimeWithIntervals =
        selectedDayScheduleTemplate &&
        selectedDayScheduleTemplate.activityInstanceTemplates
            ? selectedDayScheduleTemplate.activityInstanceTemplates.map((a) => {
                  return {
                      startTime: a.startTime,
                      endTime: a.endTime
                  };
              })
            : [];

    const availableStartTimes = timelineUtil.computeAvailableTimes(
        timeLineTimes,
        startEndTimeWithIntervals,
        greatestCommonDivisorOfActivitiesDuration
    );

    const timeDefaultValue = "";
    const getTimeValue = () =>
        (isEditMode &&
            selectedActivityInstanceTemplate &&
            selectedActivityInstanceTemplate.startTime) ||
        timeDefaultValue;
    const [time, setSelectedTime] = useState(timeDefaultValue);

    const activityIdDefault = "";
    const getActivityIdValue = () =>
        (isEditMode &&
            selectedActivityInstanceTemplate &&
            selectedActivityInstanceTemplate.activity.id) ||
        activityIdDefault;
    const [activityId, setSelectedActivityId] = useState(activityIdDefault);

    const maxCapacityDefault = 10;
    const getMaxCapacityValue = () =>
        (isEditMode &&
            selectedActivityInstanceTemplate &&
            selectedActivityInstanceTemplate.maxCapacity) ||
        maxCapacityDefault;
    const [maxCapacity, setMaxCapacity] = useState(maxCapacityDefault);

    const pricePerBookingDefault = 5.99;
    const getPricePerBookingValue = () =>
        (isEditMode &&
            selectedActivityInstanceTemplate &&
            selectedActivityInstanceTemplate.pricePerBooking) ||
        pricePerBookingDefault;
    const [pricePerBooking, setPricePerBooking] = useState(
        pricePerBookingDefault
    );

    useEffect(() => {
        setSelectedTime(getTimeValue());
        setSelectedActivityId(getActivityIdValue());
        setMaxCapacity(getMaxCapacityValue());
        setPricePerBooking(getPricePerBookingValue());
    }, [selectedActivityInstanceTemplate, isEditMode]);

    const setMaxCapacityAndPricePerBooking = () => {
        if (!isEditMode) {
            const selectedActivity = activities.find(
                (a) => a.id === activityId
            );
            if (selectedActivity) {
                if (selectedActivity.resource) {
                    setMaxCapacity(
                        selectedActivity.resource.defaultMaxCapacity
                    );
                }
                setPricePerBooking(selectedActivity.defaultPricePerBooking);
            }
        }
    };

    useEffect(setMaxCapacityAndPricePerBooking, [activityId]);

    const onAddSession = () => {
        addActivityInstance(
            activityId,
            selectedDay,
            time,
            maxCapacity,
            pricePerBooking
        );
        closeModal();
    };

    const onDelete = () => {
        deleteActivityInstance(
            selectedDay,
            selectedActivityInstanceTemplate.activity.id,
            selectedActivityInstanceTemplate.startTime,
            selectedActivityInstanceTemplate.endTime
        );
        closeModal();
    };

    return (
        <Dialog open={isModalOpen} onClose={closeModal}>
            <DialogTitle>{selectedDayName}</DialogTitle>
            <SelectTime
                timeLineTimes={isEditMode ? timeLineTimes : availableStartTimes}
                isDisabled={isEditMode}
                value={time}
                onChangeHandler={setSelectedTime}
            />
            <SelectActivity
                activities={activities}
                isDisabled={isEditMode}
                onChangeHandler={setSelectedActivityId}
                value={activityId}
            />

            <DialogContent>
                <DialogContentText>Max Capacity:</DialogContentText>
                <TextField
                    label="Max Capacity"
                    disabled={isEditMode}
                    onChange={(event) =>
                        setMaxCapacity(parseInt(event.target.value))
                    }
                    type="number"
                    value={maxCapacity}
                    InputLabelProps={{
                        shrink: true
                    }}
                    margin="normal"
                />
            </DialogContent>
            <DialogContent>
                <DialogContentText>Price per booking:</DialogContentText>
                <TextField
                    label="Price per booking:"
                    disabled={isEditMode}
                    onChange={(event) =>
                        setPricePerBooking(parseFloat(event.target.value))
                    }
                    type="float"
                    value={pricePerBooking}
                    InputLabelProps={{
                        shrink: true
                    }}
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                {isEditMode ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onDelete}
                    >
                        Delete
                        <DeleteIcon />
                    </Button>
                ) : (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onAddSession}
                    >
                        Add Session
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ActivityInstanceTemplateModal;
