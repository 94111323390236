import React, { createContext, useState } from "react";
import {
    ICreateWeekTemplateForResourceInput,
    IUpdateWeekTemplateForResourceInput,
    IWeekTemplateWhereUniqueInput
} from "../../../../generated/dataInterfaces";
import addActivityInstanceToWeekTemplate from "./addActivityInstanceToWeekTemplate";
import createDayScheduleTemplateInputs from "./createDayScheduleTemplateInputs";
import deleteActivityInstanceFromWeekTemplate from "./deleteActivityInstanceFromWeekTemplate";
import {
    IAddActivityInstance,
    IDeleteActivityInstance,
    IGetActivityInstanceTemplateOnWeekTemplate,
    ISetWeekTemplate,
    IWeekTemplateContext
} from "./interfaces";
import useWeekTemplate from "./useWeekTemplate";
import { toast } from "react-toastify";
import produce from "immer";

export const WeekTemplateContext = createContext({});

export const WeekTemplateContextProvider = ({ children }) => {
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [createdWeekTemplateId, setCreatedWeekTemplateId] = useState("");
    const {
        weekTemplate,
        setWeekTemplate,
        setWeekTemplateId,
        resourceId,
        setResourceId,
        createWeekTemplate,
        updateWeekTemplate,
        deleteWeekTemplate
    } = useWeekTemplate();

    const setWeekTemplateName: ISetWeekTemplate = (name) => {
        const updatedWeekTemplate = produce(
            weekTemplate,
            (weekTemplateDraft) => {
                weekTemplateDraft.name = name;
            }
        );

        setWeekTemplate(updatedWeekTemplate);
    };

    const addActivityInstance: IAddActivityInstance = (
        activityId,
        dayOfWeek,
        time,
        maxCapacity,
        pricePerBooking
    ) => {
        const newWeekTemplate = addActivityInstanceToWeekTemplate(
            weekTemplate,
            activityId,
            dayOfWeek,
            time,
            maxCapacity,
            pricePerBooking
        );

        setWeekTemplate(newWeekTemplate);
    };

    const getActivityInstanceTemplateOnWeekTemplate: IGetActivityInstanceTemplateOnWeekTemplate = (
        dayOfWeek,
        activityId,
        startTime,
        endTime
    ) => {
        const activityInstanceTemplate = weekTemplate.dayScheduleTemplates[
            dayOfWeek - 1
        ].activityInstanceTemplates.find(
            (a) =>
                a.activity.id === activityId &&
                a.startTime === startTime &&
                a.endTime === endTime
        );

        // const copyOfActivityInstanceTemplate = update(
        //     activityInstanceTemplate,
        //     {}
        // );

        const copyOfActivityInstanceTemplate = produce(
            activityInstanceTemplate,
            () => {}
        );

        return copyOfActivityInstanceTemplate;
    };

    const deleteActivityInstance: IDeleteActivityInstance = (
        dayOfWeek,
        activityId,
        startTime,
        endTime
    ) => {
        const newWeekTemplate = deleteActivityInstanceFromWeekTemplate(
            weekTemplate,
            dayOfWeek,
            activityId,
            startTime,
            endTime
        );

        if (newWeekTemplate) {
            setWeekTemplate(newWeekTemplate);
        }
    };

    const createWeekTemplateExtended = async () => {
        const createWeekTemplateForResourceInput: ICreateWeekTemplateForResourceInput = {
            name: weekTemplate.name,
            resourceId: weekTemplate.resource.id,
            dayScheduleTemplates: createDayScheduleTemplateInputs(weekTemplate)
        };

        const { data, errors } = await createWeekTemplate({
            variables: {
                createWeekTemplateForResourceInput
            }
        });

        if (errors && errors.length > 0) {
            toast(errors[0].message);
        }

        if (data && data["createWeekTemplateForResource"]) {
            setWeekTemplate(null);
            setCreatedWeekTemplateId(data["createWeekTemplateForResource"].id);
            toast(`Week template was created you are now on the edit page.`);
            setShouldRedirect(true);
            window.scrollTo(0, 0);
        } else {
            toast(`An error occurred, the template wasn't saved.`);
        }
    };

    const updatedWeekTemplateExtended = () => {
        const updateWeekTemplateForResourceInput: IUpdateWeekTemplateForResourceInput = {
            name: weekTemplate.name,
            id: weekTemplate.id,
            dayScheduleTemplates: createDayScheduleTemplateInputs(weekTemplate)
        };

        updateWeekTemplate({
            update: (proxy, mutationResult) => {
                if (
                    mutationResult.data &&
                    mutationResult.data["updateWeekTemplateForResource"]
                ) {
                    toast(`Week template saved.`);
                }
            },
            variables: { updateWeekTemplateForResourceInput }
        });
    };

    const deleteWeekTemplateExtended = () => {
        const weekTemplateWhereUniqueInput: IWeekTemplateWhereUniqueInput = {
            id: weekTemplate.id
        };

        deleteWeekTemplate({
            update: (proxy, mutationResult) => {
                if (
                    mutationResult.data &&
                    mutationResult.data["deleteWeekTemplate"]
                ) {
                    toast(`Week template deleted.`);
                    setShouldRedirect(true);
                    window.scrollTo(0, 0);
                } else {
                    toast(`An error occurred, the template wasn't deleted.`);
                }
            },
            variables: { weekTemplateWhereUniqueInput }
        });
    };

    const weekTemplateContext: IWeekTemplateContext = {
        weekTemplate,
        setWeekTemplateId,
        setWeekTemplateName,
        addActivityInstance,
        getActivityInstanceTemplateOnWeekTemplate,
        deleteActivityInstance,
        createWeekTemplate: createWeekTemplateExtended,
        updateWeekTemplate: updatedWeekTemplateExtended,
        deleteWeekTemplate: deleteWeekTemplateExtended,
        setWeekTemplate,
        resourceId,
        setResourceId,
        shouldRedirect,
        createdWeekTemplateId
    };

    return (
        <WeekTemplateContext.Provider value={weekTemplateContext}>
            {children}
        </WeekTemplateContext.Provider>
    );
};
