import { Grid, Typography } from "@material-ui/core";
import React from "react";
import ActivityDateStartTimeEndTime from "./ActivityDateStartTimeEndTime";
import AddActivityDateStartTimeEndTime from "./AddActivityDateStartTimeEndTime";
import { usePackageContext } from "./PackageContext";
import moment from "moment";

const GeneratedDates = () => {
    const { state, dispatch } = usePackageContext();
    const { activityGeneratedDates } = state;

    const onDateEndTimeChangeHandler = (
        date: moment.Moment,
        endTime: moment.Moment,
        index: number
    ) => {
        dispatch({
            type: "UPDATE_ONE_ACTIVITY_GENERATED_DATE",
            payload: {
                index,
                newActivityDateAndEndTime: {
                    date,
                    endTime
                }
            }
        });
    };

    const onDeleteHandler = (index: number) => {
        dispatch({
            type: "DELETE_ONE_ACTIVITY_GENERATED_DATE",
            payload: { index }
        });
    };

    const onAddHandler = (date: moment.Moment, endTime: moment.Moment) => {
        dispatch({
            type: "ADD_ACTIVITY_DATE",
            payload: {
                date,
                endTime
            }
        });
    };

    return (
        <>
            <div
                style={{
                    width: "100%",
                    margin: "50px 0 20px",
                    padding: "20px",
                    border: "1px solid #ddd",
                    backgroundColor: "#eee"
                }}
            >
                {activityGeneratedDates && activityGeneratedDates.length > 0 ? (
                    <>
                        {activityGeneratedDates.map(
                            ({ date, endTime }, index) => (
                                <div
                                    key={`${date.toString()}-${endTime.toString()}`}
                                    style={{ marginTop: "20px" }}
                                >
                                    <Grid container xs={12}>
                                        <ActivityDateStartTimeEndTime
                                            date={date}
                                            endTime={endTime}
                                            index={index}
                                            mustDisableDateInput={true}
                                            onDateEndTimeChange={
                                                onDateEndTimeChangeHandler
                                            }
                                            onDelete={onDeleteHandler}
                                        />
                                    </Grid>
                                </div>
                            )
                        )}
                    </>
                ) : (
                    <Typography variant="h4">
                        Instance date/times will be shown here.
                    </Typography>
                )}
            </div>

            <AddActivityDateStartTimeEndTime onAdd={onAddHandler} />
        </>
    );
};
export default GeneratedDates;
